import React from 'react';
import {
  TradeDirection,
} from '../../graphQL/graphQLTypes';
import {
  SelectorActiveOptionContainer,
  SelectorOptionContainer,
} from './Utils';

interface IChoiceProps {
  text: string;
  value: TradeDirection;
  selected: TradeDirection;
  isDisabled: boolean;
  onClick: (type: TradeDirection) => void;
}

export default class extends React.Component<IChoiceProps, {}> {
  private onClick = () => {
    const {isDisabled, onClick, value} = this.props;
    if (!isDisabled) {
      onClick(value);
    }
  }
  render() {
    const {text, value, selected, isDisabled} = this.props;
    const isActive = (value === selected);
    const Container = isActive ?
                        SelectorActiveOptionContainer :
                        SelectorOptionContainer;
    const style = {
      opacity: isDisabled ? 0.3 : 1,
      cursor: (!isActive && !isDisabled) ? 'pointer' : 'default',
    };
    return (
      <Container onClick={this.onClick} style={style}>
        {text}
      </Container>
    );
  }
}
