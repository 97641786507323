import React from 'react';
import styled from 'styled-components';
import {
  VizType,
} from '../Utils';
import Choice from './VizTypeChoice';

const Ul = styled.ul`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
`;

export interface IItem {
  title: string;
  value: VizType;
  imgSrc: string;
}

export enum VizCategory {
  Trade,
  Complexity,
}

export interface IProps {
  onChange: (type: VizType) => void;
  items: IItem[];
  currentValue: VizType;
}

export default (props: IProps) => {
  const {items, currentValue, onChange} = props;

  const choicesElms = items.map(({title, value, imgSrc}) => (
    <Choice
      value={value} imgSrc={imgSrc} onChange={onChange}
      title={title}
      selected={currentValue}
      key={value}
    />
  ));

  const choices = choicesElms.length === 4 ? (
    <>
      <FlexWrapper>
        {choicesElms[0]}
        {choicesElms[1]}
      </FlexWrapper>
      <FlexWrapper>
        {choicesElms[2]}
        {choicesElms[3]}
      </FlexWrapper>
    </>
    ) : choicesElms;

  return (
    <Ul>
      {choices}
    </Ul>
  );
};
