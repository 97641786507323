import {
  connect,
  MapStateToProps,
} from 'react-redux';
import memoize from '../../memoize';
import {
  IRootState,
} from '../../rootState';
import {
  getDataSelector,
  IData,
  IHash,
} from '../../sharedData/newCountryMetadata';
import {
  CountryMetadatumLevel,
  getCountryDropdownLabel,
  getCountryDropdownSearchString,
  IDropdownOption,
  ILoadable,
  LoadableStatus,
} from '../../Utils';
import Dropdown, {
  IOwnProps,
  IStateProps,
} from './Dropdown';

const unmemoizedStatusSelector =
  (dataSelector: (rootState: IRootState, hash: IHash) => ILoadable<IData>) =>
    (rootState: IRootState): ILoadable<IDropdownOption[]> => {

  const dataStatus = dataSelector(rootState, {});
  if (dataStatus.status === LoadableStatus.Initial) {
    return {status: LoadableStatus.Initial};
  } else if (dataStatus.status === LoadableStatus.Loading) {

    return {status: LoadableStatus.Loading};
  } else if (dataStatus.status === LoadableStatus.NotPresent) {

    return {status: LoadableStatus.NotPresent};
  } else {
    const {data: map} = dataStatus;
    const options: IDropdownOption[] = [...map.values()].filter(
      // Filter out regions:
      ({level, former_country}) => (level === CountryMetadatumLevel.country && !former_country),
    ).sort(
      (a, b) => (a.name_short_en < b.name_short_en) ? -1 : 1,
    ). map(
      ({id, name_short_en, level, code, name_en}) => {
        const option: IDropdownOption = {
          value: id,
          label: getCountryDropdownLabel(name_short_en, {level, code}),
          searchString: getCountryDropdownSearchString(name_short_en, name_en, {level, code}),
        };
        return option;
      },
    );
    return {
      status: LoadableStatus.Present,
      data: options,
    };
  }
};

function mapStateToProps(): MapStateToProps<IStateProps, IOwnProps<IDropdownOption>, IRootState> {
  const selector = memoize(unmemoizedStatusSelector(getDataSelector()));
  return (rootState: IRootState) => ({dataStatus: selector(rootState)});
}

export default connect(mapStateToProps)(Dropdown);
