import sortBy from 'lodash-es/sortBy';
import {
  ProductClass,
} from '../../graphQL/graphQLTypes';
import {
  hashJSONObject,
  ILoadable,
  LoadableStatus,
  ProductMetadatumLevel as Level,
} from '../../Utils';
import getGraphDataCache, {
  IBaseState,
  IUpdateMergedDataActionBase,
  IUpdateMergedDataPayloadBase,
  RoutingInputCheckResult,
} from '../newGraphDataCache';
import {
  ErrorCode,
  HideExports,
  IComputationOutput,
  NodeSizing,
  YAxisMeasure,
} from './Utils';

export interface IHash {
  // from IUIState:
  detailLevel: Level;
  hideExports: HideExports;
  yAxisMeasure: YAxisMeasure;
  deselectedCategories: number[];
  svgWidth: number | undefined;
  svgHeight: number | undefined;
  // From CompuationalInput:
  productClass: ProductClass;
  year: number;
  country: number | undefined;
}

const hashFunction = (input: IHash) => {
  // Sort the `selectedCategories` array to ensure identical hash regardless of
  // the order of its elements:
  const withSortedCategories = {
    ...input,
    deselectedCategories: sortBy(input.deselectedCategories),
  };
  return hashJSONObject(withSortedCategories);
};
export const getHashInputFromRoutingAndUIState =
  (inputFromURLRouting: IInputFromURLRouting, uiState: IUIState): IHash => {

  const {
    productClass, year, country,
  } = inputFromURLRouting;
  const {
    detailLevel, hideExports, yAxisMeasure,
    deselectedCategories,
    svgHeight, svgWidth,
  } = uiState;
  const hashInput: IHash = {
    detailLevel, hideExports, yAxisMeasure,
    deselectedCategories, svgWidth, svgHeight,
    productClass, year, country,
  };
  return hashInput;
};

export interface IValidInputFromURLRoutingCheckInput {
  country: number | undefined;
}

export interface IHasError {
  hasError: true;
  errorCode: ErrorCode;
}

export interface IHasNoError {
  hasError: false;
  value: ILoadable<IComputationOutput>;
}

export type MergedData = IHasError | IHasNoError;
const doesMergedDataIndicateSuccess = (mergedData: MergedData) => {
  if (mergedData.hasError === true) {
    return false;
  } else if (mergedData.value.status !== LoadableStatus.Present) {
    return false;
  } else {
    return true;
  }
};

export interface IUIState {
  detailLevel: Level;
  hideExports: HideExports;
  yAxisMeasure: YAxisMeasure;

  nodeSizing: NodeSizing;

  // Note: we use `deselectedCategories` instead of `selectedCategories` because we don't know the full set of
  // available categories. This is because the store doesn't know about the `productClass`
  deselectedCategories: number[];

  // DOM layout informations for chart container element:
  svgWidth: number | undefined;
  svgHeight: number | undefined;
  svgTop: number | undefined;
  svgLeft: number | undefined;
}

export interface IInputFromURLRouting {
  productClass: ProductClass;
  year: number;
  country: number | undefined;
}

export type IState = IBaseState<IUIState, MergedData, IInputFromURLRouting>;

export type ISuccessfulMergePayload = IUpdateMergedDataPayloadBase<IHash, MergedData>;

export type ISuccessfulMergeAction =
  IUpdateMergedDataActionBase<typeof UPDATE_MERGED_DATA, ISuccessfulMergePayload>;

// Note: only use this to create the enhancer:
export const START_SUBSCRIBING = 'FEASIBILITY_GRAPH_START_SUBSCRIBIBNG';
export const STOP_SUBSCRIBING = 'FEASIBILITY_GRAPH_STOP_SUBSCRIBIBNG';

export const UPDATE_MERGED_DATA = 'FEASIBILITY_GRAPH_UPDATE_MERGED_DATA';
const UPDATE_UI_STATE = 'FEASIBILITY_GRAPH_UPDATE_UI_STATE';
const UPDATE_INPUT_FROM_URL_ROUTING = 'FEASIBILITY_GRAPH_UPDATE_INPUT_FROM_URL_ROUTING';
const RESET = 'FEASIBILITY_GRAPH_RESET';

export const checkForInvalidRoutingInput =
  ({country}: IValidInputFromURLRoutingCheckInput): RoutingInputCheckResult<IHasError, undefined>  => {
    if (country === undefined) {
      return {
        isValid: false,
        value: {
          hasError: true,
          errorCode: ErrorCode.PickCountry,
        },
      };
    } else {
      return {isValid: true, extraInfo: undefined};
    }
  };

const computedDataForValidButUncomputedHashKey: IHasNoError = {
  hasError: false,
  value: {status: LoadableStatus.Initial},
};

const initialInputFromURLRouting: IInputFromURLRouting = {
  productClass: ProductClass.HS,
  year: 0,
  country: undefined,
};

const initialUIState: IUIState = {
  detailLevel: Level.fourDigit,
  hideExports: HideExports.On,
  yAxisMeasure: YAxisMeasure.Complexity,
  deselectedCategories: [],
  svgWidth: undefined,
  svgHeight: undefined,
  svgTop: undefined,
  svgLeft: undefined,
  nodeSizing: NodeSizing.WorldTrade,
};

// Reset category selection if product class changes:
const updateUIStateBasedOnURLRoutingUpdate = (
    {productClass: nextProductClass}: IInputFromURLRouting,
    {productClass: prevProductClass}: IInputFromURLRouting,
    prevUIState: IUIState): Partial<IUIState> => {

  const {
    deselectedCategories: prevDeselectedCategories,
  } = prevUIState;

  let newDeselectedCategories: number[];
  if (nextProductClass === prevProductClass) {
    newDeselectedCategories = prevDeselectedCategories;
  } else {
    newDeselectedCategories = [];
  }

  return {
    deselectedCategories: newDeselectedCategories,
  };
};

const getReducer = <RootState>(
    getCacheFromRootState: (rootState: RootState) => IState) => {

  return getGraphDataCache<
    RootState,
    MergedData,
    IUIState,
    IInputFromURLRouting,
    IHash,
    typeof START_SUBSCRIBING,
    typeof STOP_SUBSCRIBING,
    typeof UPDATE_MERGED_DATA,
    typeof UPDATE_UI_STATE,
    typeof UPDATE_INPUT_FROM_URL_ROUTING,
    typeof RESET,

    undefined,
    IValidInputFromURLRoutingCheckInput
  >({
    hashFunction,
    getCacheFromRootState,
    getInitialUIState: () => initialUIState,
    updateUIStateBasedOnURLRoutingUpdate,
    initialInputFromURLRouting,
    checkForInvalidRoutingInput,
    getHashInputFromRoutingAndUIState,
    doesMergedDataIndicateSuccess,

    startSubscribingActionName: START_SUBSCRIBING,
    stopSubscribingActionName: STOP_SUBSCRIBING,
    updateMergedDataActionName: UPDATE_MERGED_DATA,
    updateInputFromURLRoutingName: UPDATE_INPUT_FROM_URL_ROUTING,
    updateUIStateName: UPDATE_UI_STATE,
    resetActionName: RESET,

    computedDataForValidButUncomputedHashKey,
    getRoutingCheckInputFromHash: ({country}) => ({country}),
  });
};

export default getReducer;
