import {
  DataIssueType,
} from '../sharedComponents/dataNotes/Utils';
import {
  Mode,
} from '../sharedComponents/exportsPanel/Utils';
import {
  VizType,
} from '../viz/Utils';
export const ECI_OVERLAY = 'ECI_OVERLAY';
export const GRAPH_EXPLANATION_OVERLAY = 'GRAPH_EXPLANATION_OVERLAY';
export const SHARING_EXPORTS_PANEL = 'SHARING_EXPORTS_PANEL';
export const SHOW_DATA_NOTES = 'SHOW_DATA_NOTES';

export interface IMapExplanation {
  type: typeof GRAPH_EXPLANATION_OVERLAY;
  info: VizType;
}
export interface IECIOverlayInfo {
  type: typeof ECI_OVERLAY;
}

export interface ISharingExportsPanel {
  type: typeof SHARING_EXPORTS_PANEL;
  vizType: VizType;
  initialMode: Mode;
}

export interface IShowDataNotes {
  type: typeof SHOW_DATA_NOTES;
  dataIssues: DataIssueType[];
}

// If there are more overlay types, add them to this union type:
export type IOverlayInfo =
  IECIOverlayInfo | IMapExplanation |
  ISharingExportsPanel |
  IShowDataNotes;

export type IOverlayState = {
  isOverlayShown: true,
  overlayInfo: IOverlayInfo,
  // Whether the overlay should close when the user clicks outside the overlay:
  shouldCloseWhenClickedOutside: boolean,
} | {
  isOverlayShown: false,
};
