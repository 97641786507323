import React, { lazy, useState } from 'react';
import Feasibility from '../feasibility';
import GeoMap from '../geoMap';
import MarketShare from '../marketShare';
import ProductSpace from '../network';
import Rings from '../rings';
import { RouteID } from '../routing/routes';
import {
  localStorageExploreTutorialTimesShownCountVariable,
  maxTimesToShowModal,
} from '../sharedComponents/newGraphShare';
import Stack from '../stack';
import { PanelControls } from './Utils';
import VizGrid from './VizGrid';
import VizSettings from './VizSettings';

const Tree = lazy(() => import(/* webpackChunkName: "tree" */ '../tree'));

interface Props {
  routeID: RouteID.TreeMap | RouteID.GeoMap | RouteID.StackGraph | RouteID.MarketShare |
            RouteID.ProductSpace | RouteID.Feasibility | RouteID.RingsGraph;
}

const Viz = ({routeID}: Props) => {

  const tutorialShownCountStorageVal = localStorage.getItem(localStorageExploreTutorialTimesShownCountVariable);
  const tutorialShownCount = tutorialShownCountStorageVal !== null ? parseInt(tutorialShownCountStorageVal, 10) : 0;
  const showTutorialModal = !tutorialShownCount || tutorialShownCount < maxTimesToShowModal;

  const [activePanel, setActivePanel] = useState<PanelControls>(PanelControls.Build);
  const [isTutorialModalOpen, setIsTutorialModalOpen] = useState<boolean>(showTutorialModal);

  let content: React.ReactElement<any> | null;
  if (routeID === RouteID.TreeMap) {
    content = (
      <Tree
        activePanel={activePanel}
        setActivePanel={setActivePanel}
        isTutorialModalOpen={isTutorialModalOpen}
        setIsTutorialModalOpen={setIsTutorialModalOpen}
      />
    );
  } else if (routeID === RouteID.ProductSpace) {
    content = (
      <ProductSpace
        activePanel={activePanel}
        setActivePanel={setActivePanel}
        isTutorialModalOpen={isTutorialModalOpen}
        setIsTutorialModalOpen={setIsTutorialModalOpen}
      />
    );
  } else if (routeID === RouteID.GeoMap) {
    content = (
      <GeoMap
        activePanel={activePanel}
        setActivePanel={setActivePanel}
        isTutorialModalOpen={isTutorialModalOpen}
        setIsTutorialModalOpen={setIsTutorialModalOpen}
      />
    );
  } else if (routeID === RouteID.StackGraph) {
    content = (
      <Stack
        activePanel={activePanel}
        setActivePanel={setActivePanel}
        isTutorialModalOpen={isTutorialModalOpen}
        setIsTutorialModalOpen={setIsTutorialModalOpen}
      />
    );
  } else if (routeID === RouteID.MarketShare) {
    content = (
      <MarketShare
        activePanel={activePanel}
        setActivePanel={setActivePanel}
        isTutorialModalOpen={isTutorialModalOpen}
        setIsTutorialModalOpen={setIsTutorialModalOpen}
      />
    );
  } else if (routeID === RouteID.Feasibility) {
    content = (
      <Feasibility
        activePanel={activePanel}
        setActivePanel={setActivePanel}
        isTutorialModalOpen={isTutorialModalOpen}
        setIsTutorialModalOpen={setIsTutorialModalOpen}
      />
    );
  } else if (routeID === RouteID.RingsGraph) {
    content = (
      <Rings
        activePanel={activePanel}
        setActivePanel={setActivePanel}
        isTutorialModalOpen={isTutorialModalOpen}
        setIsTutorialModalOpen={setIsTutorialModalOpen}
      />
    );
  } else {
    content = null;
  }
  return (
    <VizGrid>
      <VizSettings
        activePanel={activePanel}
        setActivePanel={setActivePanel}
      />
      {content}
    </VizGrid>
  );
};

export default Viz;
