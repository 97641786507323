import once from 'lodash-es/once';
import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import ReactDOM from 'react-dom';
import styled, {
  css,
} from 'styled-components';
import {
  failIfValidOrNonExhaustive,
  offscreenRenderingDivID,
  xIconHTMLEntity,
} from '../../Utils';
import {
  Background as BackgroundBase,
  CloseButton,
  ModeChoicesContainer,
  OuterContentContainer as OuterContentContainerBase,
} from '../newExportsPanel/sharedStyles';
import {
  generalStyling,
  H2,
  H3,
  Para,
} from '../NewTextStyling';
import Tab, {
  TabType,
} from './Tab';
import {
  DataIssueType,
} from './types';
import useClickOutsideToCloseDialogBehavior from './useClickOutsideToCloseDialogBehavior';

//#region Styling
const zIndices = {
  background: 10,
  content: 20,
};
const Background = styled(BackgroundBase)`
  z-index: ${zIndices.background};
`;
const TabsContainer = styled(ModeChoicesContainer)`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;
const contentPadding = 1; // in rem
const contentWidth = 40; // in vw
const horizontalPadding = 2.5; // in `vw`

const OuterContentContainer = styled(OuterContentContainerBase)`
  z-index: ${zIndices.content};
`;

const InnerContentContainer = styled.div`
  display: grid;
  grid-template-rows: 6rem auto;
  width: ${contentWidth + 2 * horizontalPadding}vw;
  padding: 0 ${horizontalPadding}vw;
`;
const MainContentContainer = styled.div`
  padding: ${contentPadding}rem 0;
`;

const dataPageArrowStyling = css`
  a[title="data-page"] {
    &::after {
      display: inline-block;
      content: "→";
      margin-left: 0.25rem;
    }
  }
`;
const Content = styled.div`
  ${generalStyling}
  ${dataPageArrowStyling}

  h2:first-child {
    margin-top: 0;
  }
`;
const generalNotesContent = (
  <Content>
    {__lexicon('generalNotes')}
  </Content>
);
const DataIssuesContainer = styled(Content)`
  overflow-y: auto;
`;

const measureGeneralNotesHeight = once(() => new Promise<number>((resolve, reject) => {
  let offscreenDiv: HTMLElement | undefined;

  const removeOffscreenDiv = () => {
    if (offscreenDiv !== undefined) {
      offscreenDiv.remove();
    }
  };
  try {
    offscreenDiv = document.createElement('div');
    offscreenDiv.style.width = `${contentWidth}vw`;

    const offscreenDivContainer = document.getElementById(offscreenRenderingDivID)!;
    offscreenDivContainer.appendChild(offscreenDiv);

    ReactDOM.render(generalNotesContent, offscreenDiv, () => {
      if (offscreenDiv !== undefined) {
        try {
          const {height} = offscreenDiv.getBoundingClientRect();
          resolve(height);
        } catch (e) {
          reject(e);
        } finally {
          ReactDOM.unmountComponentAtNode(offscreenDiv);
          removeOffscreenDiv();
        }
      }
    });
  } catch (e) {
    removeOffscreenDiv();
    reject(e);
  }
}));
//#endregion

interface IProps {
  dataIssues: DataIssueType[];
  closeOverlay: () => void;
}

export default (props: IProps) => {
  const {dataIssues, closeOverlay} = props;

  const contentRootElRef = useRef<HTMLDivElement | null>(null);
  useClickOutsideToCloseDialogBehavior({
    containerElRef: contentRootElRef,
    closeDialog: closeOverlay,
  });

  const initialTabType = (props.dataIssues.length === 0) ? TabType.GeneralNotes : TabType.DataIssues;
  const [tabType, setTabType] = useState<TabType>(initialTabType);
  const switchTab = (type: TabType) => {
    const newTabType = (type === TabType.DataIssues && props.dataIssues.length > 0) ?
                        TabType.DataIssues : TabType.GeneralNotes;
    setTabType(newTabType);
  };

  const [
    generalNotesContentHeight, setGeneralNotesContentHeight,
  ] = useState<number | undefined>(undefined);

  // TODO: change to using Suspense once that's available.
  // https://github.com/facebook/react/issues/14326
  useEffect(() => {
    (async () => {
      const measuredHeight = await measureGeneralNotesHeight();
      // Use the measured height but if measured height is too large (more than
      // 80% viewport height) then clamp to 80% viewport height:
      const proportionedHeight = window.innerHeight * 0.8;
      const heightToSet = (measuredHeight > proportionedHeight) ?
                            proportionedHeight : measuredHeight;
      setGeneralNotesContentHeight(heightToSet);
    })();
  }, []);

  let generalNotesDisplayedHeight: string, rootOpacity: number;
  if (generalNotesContentHeight === undefined) {
    generalNotesDisplayedHeight = '0';
    rootOpacity = 0;
  } else {
    generalNotesDisplayedHeight = `calc(${generalNotesContentHeight}px + ${2 * contentPadding}rem)`;
    rootOpacity = 1;
  }

  let content: React.ReactNode;
  if (tabType === TabType.GeneralNotes) {
    // Add height:
    content = React.cloneElement(generalNotesContent, {
      style: {height: generalNotesDisplayedHeight},
    });
  } else if (tabType === TabType.DataIssues) {
    const countryNote = dataIssues.includes(DataIssueType.UnreliableCountry) ? (
      <>
        <H2>{__lexicon('dataIssues.country.title')}</H2>
        <Para>{__lexicon('dataIssues.country.text')}</Para>
      </>
    ) : null;

    const servicesNote = dataIssues.includes(DataIssueType.ServicesInGeneral) ? (
      <>
        <H2>{__lexicon('dataIssues.service.title')}</H2>
        <Para>{__lexicon('dataIssues.service.introText')}</Para>
        <H3>{__lexicon('dataIssues.service.tradeFlowTitle')}</H3>
        <Para>{__lexicon('dataIssues.service.tradeFlowText')}</Para>
        <H3>{__lexicon('dataIssues.service.yearRangeTitle')}</H3>
        <Para>{__lexicon('dataIssues.service.yearRangeText')}</Para>
        <H3>{__lexicon('dataIssues.service.countryCoverageTitle')}</H3>
        <Para>{__lexicon('dataIssues.service.countryCoverageText')}</Para>
      </>
    ) : null;
    content = (
      <DataIssuesContainer style={{height: generalNotesDisplayedHeight}}>
        {countryNote}
        {servicesNote}
      </DataIssuesContainer>
    );
  } else {
    failIfValidOrNonExhaustive(tabType, 'Invalid tab type');
    content = null;
  }

  const dataAlertsTab: React.ReactNode = (dataIssues.length === 0) ? null : (
    <Tab tabType={TabType.DataIssues} selectTab={switchTab} selectedTabType={tabType}>
      {__lexiconText('tabs.dataIssues')}
    </Tab>
  );

  return (
    <>
      <Background/>
      <OuterContentContainer style={{opacity: rootOpacity}} ref={contentRootElRef}>
        <InnerContentContainer>
          <TabsContainer>
            <Tab tabType={TabType.GeneralNotes} selectTab={switchTab} selectedTabType={tabType}>
              {__lexiconText('tabs.generalNotes')}
            </Tab>
            {dataAlertsTab}
          </TabsContainer>
          <MainContentContainer>
            {content}
          </MainContentContainer>
          <CloseButton
            onClick={closeOverlay}
            dangerouslySetInnerHTML={{__html: xIconHTMLEntity}}
          />
        </InnerContentContainer>
      </OuterContentContainer>
    </>
  );

};
