import {
  ProductClass,
} from '../graphQL/graphQLTypes';
import {
  Target,
} from '../Utils';
import {
  ALL_VIZ_TYPE_FORCED_NAVIGATION,
  ForcedNavigationAction,
  QueryStore,
} from '../viz/queryStoreUtils';

export enum ActionType {
  SELECT_YEAR = 'SELECT_YEAR',
  SELECT_COUNTRY = 'SELECT_COUNTRY',
  SELECT_PRODUCT_CLASS = 'SELECT_PRODUCT_CLASS',
  RESET_COUNTRY_AND_TARGET = 'RESET_COUNTRY_AND_TARGET',
}
export interface SelectYear {
  type: ActionType.SELECT_YEAR;
  payload: {year: number};
}
export interface SelectCountry {
  type: ActionType.SELECT_COUNTRY;
  payload: {country: number | undefined};
}
export interface SelectProductClass {
  type: ActionType.SELECT_PRODUCT_CLASS;
  payload: {productClass: ProductClass};
}
export interface ResetCountryAndTarget {
  type: ActionType.RESET_COUNTRY_AND_TARGET;
  payload: {country: undefined, target: Target.Product};
}

export type InnerAction =
  SelectYear |
  SelectCountry |
  SelectProductClass |
  ResetCountryAndTarget |
  ForcedNavigationAction;

export default function(state: QueryStore, action: InnerAction): QueryStore {

  let newState: QueryStore;
  switch (action.type) {
    case ActionType.SELECT_YEAR:
      const {payload: {year}} = action;
      newState = {
        ...state,
        year,
      };
      break;
    case ActionType.SELECT_COUNTRY:
      const {payload: {country}} = action;
      newState = {
        ...state,
        country,
      };
      break;
    case ActionType.SELECT_PRODUCT_CLASS:
      const {payload: {productClass}} = action;
      newState = {
        ...state,
        productClass,
      };
      break;
    case ActionType.RESET_COUNTRY_AND_TARGET:
      newState = {
        ...state,
        target: action.payload.target,
        country: undefined,
        partner: undefined,
      };
      break;
    case ALL_VIZ_TYPE_FORCED_NAVIGATION: {
      const {payload: {params}} = action;
      newState = {
        ...state,
        ...params,
      };
      break;
    }
    default:
      newState = state;
  }
  return newState;
}
