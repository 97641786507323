import React from 'react';
import {
  ApolloProvider as BaseApolloProvider,
} from 'react-apollo';
import {
  client,
} from '../getConfiguredApolloClient';

const ApolloProvider: React.FunctionComponent = props => (
  <BaseApolloProvider client={client}>
    {props.children}
  </BaseApolloProvider>
);

export default ApolloProvider;
