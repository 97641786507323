import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory';
import {
  ApolloClient,
} from 'apollo-client';
import {
  introspectionTypes as treeIntrospectionTypes,
} from './graphQL/tree/graphQLTypes';
import WorkerLink from './graphQL/workerLink';

const graphQLWorkeLoaderOutput = require('worker-loader?name=graphql.[hash].js!./graphQL/graphQLWorker');
const graphQLWorker = new graphQLWorkeLoaderOutput();
const workerLink = new WorkerLink(graphQLWorker);

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: {
    __schema: {
      types: [
        ...treeIntrospectionTypes,
      ],
    },
  },
});

const cache = new InMemoryCache({
  fragmentMatcher,
});

export const client = new ApolloClient({
  link: workerLink,
  cache,
});
