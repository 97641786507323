import {
  DocumentNode,
} from 'graphql';
import React, {useEffect, useRef} from 'react';
import {
  Query,
  QueryResult,
} from 'react-apollo';
import { CSVLink } from 'react-csv';

interface IProps<FetchedData, Variables, OtherInputs> {
  variables: Variables;
  query: DocumentNode;
  otherInputs: OtherInputs;
  fetchedDataToCsvData: (input: {fetchedData: FetchedData, variables: Variables, otherInputs: OtherInputs}) => object[];
  graphTitle: string;
  closeOverlay: () => void;
}

function TriggerDataDownload<FetchedData, Variables, OtherInputs>(
    props: IProps<FetchedData, Variables, OtherInputs>,
  ) {
  const {closeOverlay, query, graphTitle, fetchedDataToCsvData, otherInputs} = props;

  const csvLinkRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (csvLinkRef && csvLinkRef.current) {
      const node = csvLinkRef.current;
      setTimeout(() => {
        node.click();
        closeOverlay();
      }, 0);
    }
  }, [csvLinkRef]);

  const renderProp = ({loading, error, data, variables}: QueryResult<FetchedData, Variables>) => {
    let output: React.ReactElement<any> | null;
    if (loading) {
      output = null;
    } else if (error !== undefined) {
      console.error(error);
      output = null;
    } else if (data !== undefined) {
      const graphData = fetchedDataToCsvData({fetchedData: data, variables, otherInputs});
      output = (
        <CSVLink
          data={graphData}
          filename={graphTitle + '.csv'}
          style={{display: 'none'}}
        >
          <div
            ref={csvLinkRef}
          />
        </CSVLink>
      );
    } else {
      output = null;
    }
    return output;
  };

  return (
    <Query
      query={query}
      variables={props.variables}
      children={renderProp}
    />
  );
}

export default TriggerDataDownload;
