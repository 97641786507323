import React, {useEffect, useRef, useState} from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { darkGray } from '../../cssVariables';
import { googleAnalyticsEvent } from '../../useGoogleAnalytics';
import {overlayPortalContainerId} from '../../Utils';
import {borderLeftColor} from '../../viz/controls';
import { joyrideClassNames, TutorialTool } from '../../viz/joyrideUtils';
import { columnSpacing, ShareContainer } from '../../viz/VizGrid';
import Glossary from '../newExportsPanel/Glossary';
import Option from './Option';

export const maxTimesToShowModal = 5;
export const localStorageExploreTutorialTimesShownCountVariable = 'localStorageExploreTutorialTimesShownCountVariable';

//#region Styling
const Root = styled(ShareContainer)`
  margin-left: ${columnSpacing}rem;
`;
const DownloadRoot = styled.div`
  height: 100%;
  padding: 0.3rem;
  display: flex;
  justify-content: space-around;
  background-color: #fff;
  border-top: 1px solid ${borderLeftColor};
  border-left: 1px solid ${borderLeftColor};
`;
const HelpRoot = styled.div`
  height: 100%;
  padding: 0.5rem;
  display: flex;
  justify-content: space-around;
  background-color: ${darkGray};
`;

const transitionTime = 300; // in milliseconds;

const ModalRoot = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  transition: all ${transitionTime}ms ease;
`;

const ModalContainerBase = styled.div`
  background-color: #fff;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  transition: all ${transitionTime}ms ease;
  border-radius: 1rem;
`;

const TutorialModalContainer = styled(ModalContainerBase)`
  position: absolute;
  width: 500px;
  overflow: hidden;
`;

const GlossaryModalContainer = styled(ModalContainerBase)`
  position: relative;
  width: 45vw;
  padding: 0 2.5vw;
`;

const ModalLogo = styled.img`
  max-width: 200px;
`;

const ModalTitle = styled.h1`
  font-size: 1.3rem;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 1.8rem 0;
`;

const ModalStartButton = styled.button`
  text-transform: uppercase;
  border: none;
  padding: 1.3rem 1.5rem;
  letter-spacing: 1px;
  color: #fff;
  background-color: #ea735c;
  margin-bottom: 0.5rem;

  &:hover {
    background-color: #ca5944;
    cursor: pointer;
  }
`;

const ModalDismissButton = styled.button`
  border: none;
  padding: 0.7rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 2rem;

  &:hover {
    background-color: #e7e7e7;
    cursor: pointer;
  }
`;

const ModalNeverAgainLabel = styled.label`
  font-size: 0.9rem;
  color: #666;
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`;

const ModalFadeOutContent = styled.div`
  color: white;
  padding: 0.5rem;
`;

const ModalNeverAgainCheckBox = styled.input`
  margin-right: 5px;
`;

//#endregion
export interface DataNotesProps {
  isDataNotesWarningActive: boolean;
  launchDataNotes: () => void;
}

export interface TutorialModalProps {
  isTutorialModalOpen: boolean;
  setIsTutorialModalOpen: (value: boolean) => void;
}

interface IProps extends TutorialModalProps {
  launchShare: null | (() => void);
  launchExports: null | (() => void);
  showDataDownload: null | (() => void);
  dataNotesProps: DataNotesProps;
  closeDetailOverlay: () => void;
}

export const SharePlaceholder = () => (
  <Root>
    <DownloadRoot />
    <HelpRoot />
  </Root>
);

export default (props: IProps) => {
  const {
    launchShare, launchExports, showDataDownload,
    dataNotesProps, isTutorialModalOpen,
    setIsTutorialModalOpen, closeDetailOverlay,
  } = props;

  const [runTutorial, setRunTutorial] = useState<boolean>(false);
  const [modalContainerStyles, setModalContainerStyles] = useState<React.CSSProperties | undefined>(undefined);
  const [overlayOpacity, setOverlayOpacity] = useState<number>(1);
  const [tutorialIconClassNames, setTutorialIconClassNames] = useState<string>('');
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [isGlossaryModalOpen, setIsGlossaryModalOpen] = useState<boolean>(false);

  const overlayPortalContainerNodeRef = useRef<HTMLElement | null>(null);
  const modalContainerNodeRef = useRef<HTMLDivElement | null>(null);
  const helpIconNodeRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const node = document.querySelector<HTMLElement>(`#${overlayPortalContainerId}`);
    if (node !== null) {
      overlayPortalContainerNodeRef.current = node;
    }
  }, []);

  let dataNotesSVGString: string, dataNotesColor: string | undefined;
  if (dataNotesProps.isDataNotesWarningActive) {
    dataNotesSVGString = require('./datanotes_on.svg');
    dataNotesColor = 'rgb(255, 133, 48)';
  } else {
    dataNotesSVGString = require('./datanotes_off.svg');
    dataNotesColor = undefined;
  }

  const disableTutorial = () => {
    localStorage.setItem(localStorageExploreTutorialTimesShownCountVariable, (maxTimesToShowModal + 1).toString());
  };

  const incTutorialViews = () => {
    if (isChecked) {
      disableTutorial();
    } else {
      const tutorialShownCountStorageVal = localStorage.getItem(localStorageExploreTutorialTimesShownCountVariable);
      const tutorialShownCount = tutorialShownCountStorageVal !== null ? parseInt(tutorialShownCountStorageVal, 10) : 0;
      localStorage.setItem(localStorageExploreTutorialTimesShownCountVariable, (tutorialShownCount + 1).toString());
    }
  };

  const closeTutorialModal = () => {
    // for animation, first set the top, left, and height values of the modal
    if (modalContainerNodeRef !== null &&
        modalContainerNodeRef.current !== null &&
        modalContainerStyles === undefined) {
      const {top, left} = modalContainerNodeRef.current.getBoundingClientRect();
      const height = modalContainerNodeRef.current.offsetHeight;
      setModalContainerStyles({top, left, minHeight: height});
    }
    // then set the values for the end of the animation
    // set a timeout to 0 so this runs as soon as the function ends
    setTimeout(() => {
      if (helpIconNodeRef !== null && helpIconNodeRef.current !== null) {
        const {top, left} = helpIconNodeRef.current.getBoundingClientRect();
        const height = helpIconNodeRef.current.offsetHeight;
        setModalContainerStyles({
          width: height, minHeight: height,
          top, left,
          backgroundColor: '#717a82',
        });
      }
    }, 0);
    // set overlay to 0 so that it fades out as the box shrinks
    setOverlayOpacity(0);
    // set a timeout to reset all values and officially close the modal once the animation ends
    setTimeout(() => {
      setIsTutorialModalOpen(false);
      setModalContainerStyles(undefined);
      setOverlayOpacity(1);
      setTutorialIconClassNames('bounce');
    }, transitionTime);
    incTutorialViews();
    googleAnalyticsEvent('Explore Tutorial', `click --- "Dismiss Tutorial"`);
  };
  const startTutorial = () => {
    setIsTutorialModalOpen(false);
    setRunTutorial(true);
    disableTutorial();
    googleAnalyticsEvent('Explore Tutorial', `click --- "Start Tutorial"`);
  };

  const toggleNeverAgainCheckbox = () => {
    const newCheckedValue = !isChecked;
    googleAnalyticsEvent('Explore Tutorial', `toggle --- "Dont show again checkbox = ${newCheckedValue}"`);
    setIsChecked(newCheckedValue);
  };

  let tutorialModal: React.ReactElement<any> | null;
  if (isTutorialModalOpen === true && overlayPortalContainerNodeRef.current !== null) {
    const content = overlayOpacity === 0 ? (
      <ModalFadeOutContent></ModalFadeOutContent>
    ) : (
      <>
        <ModalLogo src={require('../../img/atlasOnboardingLogo.png')}/>
        <ModalTitle>
          {__lexiconText('tutorialModal.title')}
        </ModalTitle>
        <ModalStartButton onClick={startTutorial}>{__lexiconText('tutorialModal.startBtn')}</ModalStartButton>
        <ModalDismissButton onClick={closeTutorialModal}>
          {__lexiconText('tutorialModal.dismissBtn')}
        </ModalDismissButton>
        <ModalNeverAgainLabel htmlFor={'dont-show-tutorial-on-start-again'}>
          <ModalNeverAgainCheckBox
            type='checkbox'
            id={'dont-show-tutorial-on-start-again'}
            checked={isChecked}
            onChange={toggleNeverAgainCheckbox}
          />
          {__lexiconText('tutorialModal.neverShowAgainBtn')}
        </ModalNeverAgainLabel>
      </>
    );
    tutorialModal = ReactDOM.createPortal((
      <ModalRoot>
        <ModalOverlay onClick={closeTutorialModal} style={{opacity: overlayOpacity}} />
        <TutorialModalContainer style={modalContainerStyles} ref={modalContainerNodeRef}>
          {content}
        </TutorialModalContainer>
      </ModalRoot>
    ), overlayPortalContainerNodeRef.current);
  } else {
    tutorialModal = null;
  }

  let glossaryModal: React.ReactElement<any> | null;
  if (isGlossaryModalOpen === true && overlayPortalContainerNodeRef.current !== null) {
    const onClose = () => setIsGlossaryModalOpen(false);
    glossaryModal = ReactDOM.createPortal((
      <ModalRoot>
        <ModalOverlay onClick={onClose} />
        <GlossaryModalContainer>
          <Glossary onClose={onClose} />
        </GlossaryModalContainer>
      </ModalRoot>
    ), overlayPortalContainerNodeRef.current);
  } else {
    glossaryModal = null;
  }

  const onRunTutorialClick = () => {
    closeDetailOverlay();
    setRunTutorial(true);
  };

  return (
    <Root
      className={joyrideClassNames.sixth}
    >
      <DownloadRoot>
        <Option
          svgString={require('./download.svg')}
          label={'Data Download'}
          onClick={showDataDownload}
          size={'1rem'}
          color={darkGray}
        />
        <Option
          svgString={require('../../countryRankings/img/imageDownload.svg')}
          label={'Image Download'}
          onClick={launchExports}
          size={'1.4rem'}
          color={darkGray}
        />
        <Option
          svgString={require('./share.svg')}
          label={__lexiconText('share')}
          onClick={launchShare}
          size={undefined}
          color={darkGray}
        />
      </DownloadRoot>
      <HelpRoot
        ref={helpIconNodeRef}
        style={{zIndex: overlayOpacity === 0 ? 1000 : undefined}}
      >
        <Option
          svgString={'?'}
          label={'Guide'}
          onClick={onRunTutorialClick}
          size={undefined}
          color={undefined}
          rootClassName={tutorialIconClassNames}
        />
        <Option
          svgString={dataNotesSVGString}
          label={__lexiconText('dataNotes')}
          onClick={dataNotesProps.launchDataNotes}
          size={'1.2rem'}
          color={dataNotesColor}
        />
        <Option
          svgString={require('./glossary.svg')}
          label={__lexiconText('glossary')}
          onClick={() => setIsGlossaryModalOpen(true)}
          size={'1.8rem'}
          color={undefined}
        />
      </HelpRoot>
      <TutorialTool
        run={runTutorial}
        onClose={() => setRunTutorial(false)}
        key={runTutorial.toString()}
      />
      {tutorialModal}
      {glossaryModal}
    </Root>
  );

};
