import {
  TradeDirection,
} from '../graphQL/graphQLTypes';
import {
  IURLParams,
  Title,
} from '../sharedComponents/graphSearchBar/Utils';
import {
  IMetadatum as ICountryMetadatum,
} from '../sharedData/newCountryMetadata';
import {
  IMetadatum as IProductMetadatum,
} from '../sharedData/newProductMetadata';

const getTitle = (
    countryMetadata: Map<number, ICountryMetadatum>,
    productMetadata: Map<number, IProductMetadatum>,
    {country, year, product, tradeDirection}: IURLParams): Title => {

  const tradeWord = (tradeDirection === TradeDirection.export) ? 'export' : 'import';
  const preposition = (tradeDirection === TradeDirection.export) ? 'to' : 'from';

  let result: Title;
  if (country !== undefined && product === undefined) {

    const {name_short_en: countryName} = countryMetadata.get(country)!;
    const title = `Where did ${countryName} ${tradeWord} ${preposition} in ${year}?`;
    result = {ableToDetermineTitle: true, title};
  } else if (country === undefined && product !== undefined) {

    const {name_short_en: productName} = productMetadata.get(product!)!;
    const tradeWordPastTense = (tradeDirection === TradeDirection.export) ? 'exported' : 'imported';
    const title = `Who ${tradeWordPastTense} ${productName} in ${year}?`;
    result = {ableToDetermineTitle: true, title};
  } else if (country !== undefined && product !== undefined) {

    const {name_short_en: countryName} = countryMetadata.get(country!)!;
    const {name_short_en: productName} = productMetadata.get(product!)!;
    const title = `Where did ${countryName} ${tradeWord} ${productName} ${preposition} in ${year}?`;
    result = {ableToDetermineTitle: true, title};
  } else {
    result = {ableToDetermineTitle: false};
  }

  return result;
};

export default getTitle;
