import React from 'react';
import {
  DispatchProp,
} from 'react-redux';
import Select from 'react-select';
import {
  Action,
} from 'redux';
import {
  Subscription,
} from 'rxjs';
import styled from 'styled-components';
import Tooltip, {
  defaultTooltipDelay,
  getTooltipVisibilityStream,
} from '../../sharedComponents/UITooltip';
import {
  highlightDropdownFilterFunction,
  ILoadable,
  LoadableStatus,
} from '../../Utils';
import {
  IDropdownOption,
} from '../../Utils';

// This component clip long label names with ellipsis:
// The number to be subtractd is picked such that the ellipsis at the end of
// long labels just barely touch the clear button:
// Also need to double up the class name to overcome specificity:
const SelectWithEllipsis = styled(Select)`
  .Select-value.Select-value {
    max-width: calc(100% - 24px);
  }

  .Select-control {
    border-radius: 0;
    border: none;
  }
`;

// Color is picked so that it matches the color of placeholder text when no
// value is selected from dropdown:
const FocusedPromptContainer = styled.div`
  color: rgb(170, 170, 170);
`;

const SelectorContainer = styled.div`
  position: relative;
`;
export interface IStateProps {
  dataStatus: ILoadable<IDropdownOption[]>;
}
type ITooltipProps  = {
  enabled: true,
  text: string,
} | {
  enabled: false,
};
export interface IOwnProps <T extends IDropdownOption> {
  clearable: boolean;
  value: number | undefined;
  onChange: (id: number | undefined) => void;
  loadingText: string;
  placeholder: string;
  textPromptOnFocus: string;
  fetchDataAction: Action;
  tooltip: ITooltipProps;
  optionRenderer?: (option: T) => JSX.Element;
  valueRenderer?: (option: T) => JSX.Element;
}

type IProps<T extends IDropdownOption> = IOwnProps<T> & IStateProps & DispatchProp;

interface IState {
  showTooltip: boolean;
  isFocused: boolean;
}

export default class ProductDropdown<T extends IDropdownOption>
      extends React.Component<IProps<T>, IState> {

  constructor(props: IProps<T>) {
    super(props);
    this.state = {
      showTooltip: false,
      isFocused: false,
    };
  }

  private el: HTMLElement | null = null;
  private rememberEl = (el: HTMLElement | null) => this.el = el;

  private showOrHideSubscription: Subscription | undefined;

  componentDidMount() {
    const {el} = this;
    if (el !== null) {
      const showOrHide$ = getTooltipVisibilityStream(el, defaultTooltipDelay);
      this.showOrHideSubscription = showOrHide$.subscribe(showTooltip =>
        this.setState((prevState: IState) => ({...prevState, showTooltip})),
      );

      const {dispatch, fetchDataAction} = this.props;
      dispatch(fetchDataAction);
    }
  }

  componentWillUnmount() {
    const {showOrHideSubscription} = this;
    if (showOrHideSubscription !== undefined) {
      showOrHideSubscription.unsubscribe();
    }
  }

  private onChange = (input: IDropdownOption | null) => {
    const valueToSend = (input === null) ?
                          undefined : (input.value as number);
    this.props.onChange(valueToSend);
  }

  private onFocus = () => this.setState(
    (prevState: IState): IState => ({...prevState, isFocused: true}),
  )

  private onBlur = () => this.setState(
    (prevState: IState): IState => ({...prevState, isFocused: false}),
  )

  render() {
    const {
      value, dataStatus, loadingText, clearable, placeholder, tooltip,
      optionRenderer, textPromptOnFocus,
    } = this.props;
    const {showTooltip, isFocused} = this.state;

    // Show the "loading" text until product metadata finishes fetching,
    // at which point shows the actual product name:
    let options: IDropdownOption[];
    if (dataStatus.status === LoadableStatus.Present) {
      ({data: options} = dataStatus);
    } else {
      if (value === undefined) {
        options = [];
      } else {
        // Assign the "loading" text to the value of `selectedCountry`
        // makes the "loaidng" text shows up in the box:
        options = [{
          value,
          label: loadingText,
          searchString: '',
        }];
      }
    }

    let tooltipElem: JSX.Element | null;
    if (tooltip.enabled === true && showTooltip === true) {
      tooltipElem = (
        <Tooltip>{tooltip.text}</Tooltip>
      );
    } else {
      tooltipElem = null;
    }

    const promptRenderer = () => (
      <FocusedPromptContainer>{textPromptOnFocus}</FocusedPromptContainer>
    );

    // Show prompt message instead of selected value while focused:
    const valueRenderer = isFocused ? promptRenderer : this.props.valueRenderer;

    return (
      <SelectorContainer ref={this.rememberEl}>
        <SelectWithEllipsis
          options={options} value={value} clearable={clearable}
          placeholder={placeholder} optionRenderer={optionRenderer}
          filterOption={highlightDropdownFilterFunction}
          valueRenderer={valueRenderer}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          autoBlur={true}
          onChange={this.onChange}/>
        {tooltipElem}
      </SelectorContainer>
    );
  }
}
