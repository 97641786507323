import React from 'react';
import * as ReactDOM from 'react-dom';
import Root from './Root';

const rootEl = document.getElementById('app-container');

const render = (Component: React.ComponentType) => {
  ReactDOM.render(
    <Component/>
  , rootEl);
};

const RAVEN_STATUS = window.RAVEN_STATUS;
if (RAVEN_STATUS.isEnabled === true) {
  RAVEN_STATUS.Raven.context(() => render(Root));
} else {
  render(Root);
}

declare global {
  interface IdleCallbackDeadline {
    timeRemaining: () => number;
    didTimeout: boolean;
  }
  interface IdleCallbackOptions {
    timeout: number;
  }
  type RequestIdleCallback = (deadline: IdleCallbackDeadline) => void;
  function requestIdleCallback(callback: RequestIdleCallback, options?: IdleCallbackOptions): number;
  function cancelIdleCallback(callbackId: number): void;

  interface ResizeObserver {
    observe(target: Element): void;
    unobserve(target: Element): void;
    disconnect(): void;
  }

  interface ResizeObserverEntry {
    readonly target: Element;
    readonly contentRect: DOMRectReadOnly;
  }

  type ResizeObserverCallback = (entries: ResizeObserverEntry[], resizeObserver: ResizeObserver) => void;

  const ResizeObserver: {
    prototype: ResizeObserver
    new(callback: ResizeObserverCallback): ResizeObserver,
  };
}
