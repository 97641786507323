import {
  ActionsObservable,
  Epic,
  StateObservable,
} from 'redux-observable';
import {
  of,
} from 'rxjs';
import {
  ajax,
} from 'rxjs/ajax';
import {
  catchError,
  concat,
  filter,
  map,
  mergeMap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  doNothingAction,
  IDoNothingAction,
} from '../sharedComponents/getDataCache';
import {
  FetchStatus,
  IFetchableDataState,
} from '../Utils';

export default function<
  IData,
  RootState,
  // String constants for various action types:
  FetchIfNeeded extends string,
  FetchBegin extends string,
  FetchSuccess extends string,
  FetchFail extends string,
  IAPIResponse = IData
>(options: {
  fetchIfNeededAction: FetchIfNeeded,
  fetchBeginAction: FetchBegin,
  fetchSuccessAction: FetchSuccess,
  fetchFailAction: FetchFail,
  url: string,
  getLocalStateFromRootState: (rootState: RootState) => IFetchableDataState<IData>,
  getDataFromAPIResponse?: (response: IAPIResponse) => IData,
}) {
  const {
    fetchIfNeededAction, fetchBeginAction,
    fetchSuccessAction, fetchFailAction,
    getLocalStateFromRootState,
    url, getDataFromAPIResponse,
  } = options;

  type IState = IFetchableDataState<IData>;

  interface IFetchIfNeededAction {
    type: FetchIfNeeded;
  }
  interface IFetchSuccessAction {
    type: FetchSuccess;
    payload: {
      data: IData,
    };
  }
  interface IFetchBeginAction {
    type: FetchBegin;
  }
  interface IFetchFailAction {
    type: FetchFail;
    payload: {
      errorMessage: string,
    };
  }

  type IAction = IFetchIfNeededAction | IFetchBeginAction |
                  IFetchFailAction | IFetchSuccessAction | IDoNothingAction;

  function reducer(
    state: IState = {status: FetchStatus.Initial, data: undefined, errorMessage: undefined},
    action: IAction): IState {

      let newState: IState;
      switch (action.type) {
        case fetchBeginAction:
          newState = {
            status: FetchStatus.InProgress,
            data: undefined,
            errorMessage: undefined,
          };
          break;
        case fetchSuccessAction:
          const {payload: {data}} = action as IFetchSuccessAction;
          newState = {
            status: FetchStatus.Success,
            data,
            errorMessage: undefined,
          };
          break;
        case fetchFailAction:
          const {payload: {errorMessage}} = action as IFetchFailAction;
          newState = {
            status: FetchStatus.Fail,
            data: undefined,
            errorMessage,
          };
          break;
        default:
          newState = state;
      }
      return newState;
    }

  // Action creator:
  const fetchIfNeeded = (): IFetchIfNeededAction => ({type: fetchIfNeededAction});

  const extractDataFromResponse = getDataFromAPIResponse ?
                                  getDataFromAPIResponse :
                                  (input: IAPIResponse) => input as any as IData;

  const isFetchIfNeededAction =
    (action: IAction): action is IFetchIfNeededAction => action.type === fetchIfNeededAction;

  const fetchDataEpic: Epic<IAction, IAction, RootState> =
    (action$: ActionsObservable<IAction>, state$: StateObservable<RootState>) =>
      action$.pipe(
        filter<IAction, IFetchIfNeededAction>(isFetchIfNeededAction),
        withLatestFrom(state$),
        mergeMap<[IFetchIfNeededAction, RootState], IAction>(
          ([, state]) => {
            const {status, data} = getLocalStateFromRootState(state);
            // Don't re-fetch if data is already successfully fetched or if a fetch
            // is already in progress:
            if (data && (status !== FetchStatus.Fail && status !== FetchStatus.Initial)) {
              const doNothing$ = of(doNothingAction);
              return doNothing$;
            } else {
              // Otherwise initiate a fetch sequence:
              const fetchAction = {type: fetchBeginAction};
              const fetchBegin$ = of<IFetchBeginAction>(fetchAction);
              const fetch$ = ajax.getJSON<IAPIResponse>(url).pipe(
                map<IAPIResponse, IData>(response => extractDataFromResponse(response)),
                map<IData, IFetchSuccessAction>(
                  fetchedData => ({type: fetchSuccessAction, payload: {data: fetchedData}}),
                ),
                catchError<IFetchSuccessAction, IFetchFailAction>(
                  errorMessage => {
                    const RAVEN_STATUS = window.RAVEN_STATUS;
                    if (RAVEN_STATUS.isEnabled) {
                      RAVEN_STATUS.Raven.captureMessage(errorMessage, {
                        extra: fetchAction,
                        level: 'warning',
                      });
                    }
                    return of<IFetchFailAction>({type: fetchFailAction, payload: {errorMessage}});
                }),
              );
              return fetchBegin$.pipe(concat(fetch$));
            }
          },
        ),
      );

  return {
    reducer,
    fetchIfNeeded,
    fetchDataEpic,
  };
}
