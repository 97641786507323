import {
  TradeDirection,
} from '../graphQL/graphQLTypes';
import {
  IURLParams,
  Title,
} from '../sharedComponents/graphSearchBar/Utils';
import {
  IMetadatum as ICountryMetadatum,
} from '../sharedData/newCountryMetadata';
import {
  IMetadatum as IProductMetadatum,
} from '../sharedData/newProductMetadata';
import {
  DataSource as Source,
} from '../Utils';

import {
  GraphSubject as Subject,
} from '../viz/Utils';
import {
  checkForInvalidRoutingInput,
} from '../workerStore/stack/getReducer';

const getTitle = (
    countryMetadata: Map<number, ICountryMetadatum>,
    productMetadata: Map<number, IProductMetadatum>,
    input: IURLParams): Title => {

  const checkResult = checkForInvalidRoutingInput(input);
  let title: Title;
  if (checkResult.isValid === true) {
    const {country, partner, product, startYear, year: endYear, tradeDirection} = input;
    const {extraInfo} = checkResult;

    let phraseBeforeYear: string;
    const tradeWord = (tradeDirection === TradeDirection.export) ? 'export' : 'import';
    const preposition = (tradeDirection === TradeDirection.export) ? 'to' : 'from';
    if (extraInfo.source === Source.CPY && extraInfo.subject === Subject.Product) {

      const {name_short_en: countryName} = countryMetadata.get(country!)!;
      phraseBeforeYear = `What did ${countryName} ${tradeWord}`;
    } else if (extraInfo.source === Source.CPY && extraInfo.subject === Subject.Country) {

      const {name_short_en: productName} = productMetadata.get(product!)!;
      const tradeWordPastTense = (tradeDirection === TradeDirection.export) ? 'exported' : 'imported';
      phraseBeforeYear = `Who ${tradeWordPastTense} ${productName}`;
    } else if (extraInfo.source === Source.CCY && extraInfo.subject === Subject.Country) {
      const {name_short_en: countryName} = countryMetadata.get(country!)!;
      phraseBeforeYear = `Where did ${countryName} ${tradeWord} ${preposition}`;
    } else if (extraInfo.source === Source.CCPY && extraInfo.subject === Subject.Country) {
      const {name_short_en: countryName} = countryMetadata.get(country!)!;
      const {name_short_en: productName} = productMetadata.get(product!)!;
      phraseBeforeYear = `Where did ${countryName} ${tradeWord} ${productName} ${preposition}`;
    } else {
      const {name_short_en: countryName} = countryMetadata.get(country!)!;
      const {name_short_en: partnerName} = countryMetadata.get(partner!)!;
      phraseBeforeYear = `What did ${countryName} ${tradeWord} ${preposition} ${partnerName}`;
    }
    const yearPhrase = `between ${startYear} and ${endYear}`;
    title = {
      ableToDetermineTitle: true,
      title: `${phraseBeforeYear} ${yearPhrase}?`,
    };
  } else {
    title = {ableToDetermineTitle: false};
  }
  return title;
};

export default getTitle;
