import React from 'react';
import styled from 'styled-components';
import {
  IRootState,
} from '../../rootState';
import {
  VizType,
} from '../../viz/Utils';
import {
  IExportsCreator,
} from './ExportOption';
import Exports from './Exports';
import ModeChoice from './ModeChoice';
import Share from './Share';
import {
  GetDataMergeStatusResult,
  Mode,
  ModeSelectorContainer as ModeSelectorContainerBase,
  Root as RootBase,
} from './Utils';

interface IModeChoicePrecursor {
  assignedMode: Mode;
  label: string;
}

const modeChoices: IModeChoicePrecursor[] = [
  {
    assignedMode: Mode.Share,
    label: __lexiconText('mode.share'),
  },
  {
    assignedMode: Mode.Export,
    label: __lexiconText('mode.export'),
  },
];

const horizontalPadding = 15; // in `vw`
const verticalPadding = 20; // in `vh`

const Root = styled(RootBase)`
  width: calc(30vw + ${horizontalPadding}vw);
  height: calc(30vh + ${verticalPadding}vh);
  padding: 1rem 5% 0;
`;

const TypeSelector = styled(ModeSelectorContainerBase)`
  display: grid;
  grid-template-columns: repeat(2, minmax(100px, 1fr));
`;

type IInput<MergedData, UIState, InputFromURLRouting, ExtraInfoFromRootState> = {
  getMergedDataStatus: (rootState: IRootState) => GetDataMergeStatusResult<MergedData>;
  getUIState: (rootState: IRootState) => UIState;
  getInputFromURLRouting: (rootState: IRootState) => InputFromURLRouting;
  getExtraInfoFromRootState: (rootState: IRootState) => ExtraInfoFromRootState;
  graphType: VizType;

  performPNGExport: IExportsCreator<MergedData, UIState, InputFromURLRouting, ExtraInfoFromRootState>;
  performPDFExport: IExportsCreator<MergedData, UIState, InputFromURLRouting, ExtraInfoFromRootState>;
} & (
  {
    isSVGEnabled: false,
  } | {
    isSVGEnabled: true,
    performSVGExport: IExportsCreator<MergedData, UIState, InputFromURLRouting, ExtraInfoFromRootState>,
  }
);
interface IProps {
  initialMode: Mode;
}

interface IState {
  mode: Mode;
}

function getPanel<MergedData, UIState, InputFromURLRouting, ExtraInfoFromRootState>(
    input: IInput<MergedData, UIState, InputFromURLRouting, ExtraInfoFromRootState>,
  ): React.ComponentClass<IProps> {

  const {
    getMergedDataStatus,
    getUIState,
    performPNGExport,
    performPDFExport,
    getInputFromURLRouting,
    getExtraInfoFromRootState,
    graphType,
  } = input;

  return class extends React.Component<IProps, IState> {
    constructor(props: IProps) {
      super(props);
      this.state = {
        mode: props.initialMode,
      };
    }

    private setMode = (mode: Mode) => this.setState(
      (prevState: IState): IState => ({...prevState, mode}),
    )

    render() {
      const {
        mode,
      } = this.state;
      const modes = modeChoices.map(({assignedMode, label}) => (
        <ModeChoice
          assignedMode={assignedMode}
          label={label}
          activeMode={mode}
          setMode={this.setMode}
          key={assignedMode}/>
      ));
      let content: JSX.Element;
      switch (mode) {
        case Mode.Share: {
          content = (
            <Share/>
          );
          break;
        }
        case Mode.Export: {
          if (input.isSVGEnabled === true) {
            content = (
              <Exports isSVGEnabled={true}
                performSVGExport={input.performSVGExport}
                graphType={graphType}
                getMergedDataStatus={getMergedDataStatus}
                getInputFromURLRouting={getInputFromURLRouting}
                getUIState={getUIState}
                performPNGExport={performPNGExport}
                performPDFExport={performPDFExport}
                getExtraInfoFromRootState={getExtraInfoFromRootState}
              />
            );

          } else {
            content = (
              <Exports isSVGEnabled={false}
                graphType={graphType}
                getMergedDataStatus={getMergedDataStatus}
                getInputFromURLRouting={getInputFromURLRouting}
                getUIState={getUIState}
                performPNGExport={performPNGExport}
                performPDFExport={performPDFExport}
                getExtraInfoFromRootState={getExtraInfoFromRootState}
              />
            );

          }
          break;
        }
        default: {
          throw new Error('Invalid mode');
        }
      }
      return (
        <Root>
          <TypeSelector>{modes}</TypeSelector>
          {content}
        </Root>
      );
    }
  };
}
export default getPanel;
