import {
  HierarchyNode,
  stratify,
} from 'd3-hierarchy';
import omit from 'lodash-es/omit';
import {
  ProductClass,
} from '../../graphQL/graphQLTypes';
import {
  failIfValidOrNonExhaustive,
  getDisplayedProductCode,
  getProductSearchString,
  hsColorsMap,
  ProductMetadatumLevel as Level,
  sitcColorMap,
} from '../../Utils';
import {
  IMetadatum,
} from '../../workerStore/fetchedData/productMetadata';
import {
  getProductSection,
  IHierarchicalItem,
  IItem,
} from './Utils';

// No product has this ID so we can use it to create a "fake" root node for the
// purpose of using d3-stratify:
const sentinelProductId = 999999;

const getMetadatumToItemConverter =
    (productClass: ProductClass, metadataMap: Map<number, IMetadatum>) => {
  let colorMap: typeof hsColorsMap;
  if (productClass === ProductClass.HS) {
    colorMap = hsColorsMap;
  } else if (productClass === ProductClass.SITC) {
    colorMap = sitcColorMap;
  } else {
    failIfValidOrNonExhaustive(productClass, 'Invalid product class');
    // These lines will never be executed:
    colorMap = hsColorsMap;
  }

  return ({level, name_short_en, code, id}: IMetadatum): IItem<Level> => {
    const section = getProductSection(id, metadataMap);
    const color = colorMap.get(section);
    if (color === undefined) {
      throw new Error('Cannot find color for section' + section);
    }
    const productCode = getDisplayedProductCode(code, productClass, level);
    return {
      level,
      primaryLabel: name_short_en,
      secondaryLabel: productCode,
      searchString: getProductSearchString(name_short_en, productClass, code),
      value: id,
      color,
    };
  };
};

export const getHierarchicalItems = (
    input: IMetadatum[],
    productClass: ProductClass,
    metadataMap: Map<number, IMetadatum>,
  ): Array<IHierarchicalItem<Level>> => {

  const stratifyInput = [
    ...input,
    {id: sentinelProductId, parent_id: undefined} as any as IMetadatum,
  ];
  // Construct hierarchical structure from flat list:
  const tree = stratify<IMetadatum>().parentId(
    ({parent_id}: IMetadatum) =>
      (parent_id === null) ?
        sentinelProductId.toString() :
        (parent_id === undefined ? undefined : parent_id.toString()),
  )(stratifyInput);

  const metadatumToItemConverter = getMetadatumToItemConverter(productClass, metadataMap);
  const transformStratifyDatum = (node: Omit<HierarchyNode<IMetadatum>, 'children'>): IItem<Level> => {
    const {data} = node;
    return metadatumToItemConverter(data);
  };

  const transformStratifyNode = (
      inputNode: HierarchyNode<IMetadatum>,
    ): IHierarchicalItem<Level> => {

    const children = inputNode.children;
    const rest = omit(inputNode, 'children') as Omit<typeof inputNode, 'children'>;
    const transformOutput = transformStratifyDatum(rest);
    let output: IHierarchicalItem<Level>;
    if (children === undefined) {
      output = Object.assign({}, transformOutput, {
        children: undefined,
      });
    } else {
      const newChildren = children.map(child => transformStratifyNode(child));
      output = Object.assign({}, transformOutput, {
        children: newChildren,
      });
    }
    return output;
  };

  const result = tree.children!.map(child => transformStratifyNode(child));
  return result;
};

export const convertMetadataToList = (input: Map<number, IMetadatum>): IMetadatum[] => {
  const result = [...input.values()].sort(
    (a, b) => (a.code < b.code) ? -1 : 1,
  );
  return result;
};

export const convertToItems = (
    input: IMetadatum[],
    productClass: ProductClass,
    metadataMap: Map<number, IMetadatum>,
  ) => {
  const converter = getMetadatumToItemConverter(productClass, metadataMap);
  return input.map(converter);
};
