import {
  ApolloLink,
  Operation,
} from 'apollo-link';
import {
  Observable,
  Observer,
} from 'zen-observable-ts';

import PromiseWorker from 'promise-worker';

// TODO: try to eliminate `any` types in this file:

export interface OperationWithContext extends Operation {
  context: any;
}

class WorkerLink extends ApolloLink {
  private promiseWorker!: PromiseWorker<OperationWithContext, any>;

  constructor(worker: Worker) {
    super();
    this.promiseWorker = new PromiseWorker(worker);
  }

  request(operation: Operation) {
    return new Observable((observer: Observer<any>) => {
      // Insert context into `operation` because we can't transmi `.getContext`
      // method across worker-main thread boundary:
      let isSubscriptionActive = true;
      const operationWithContext = {
        ...operation,
        context: operation.getContext(),
      };
      this.promiseWorker.postMessage(operationWithContext).then(
        response => {
          if (isSubscriptionActive === true) {
            observer.next!(response);
          }
        },
      ).catch(
        e => observer.error!(e),
      );
      return {
        unsubscribe() {
          isSubscriptionActive = false;
        },
        closed: false,
      };
    });
  }
}

export default WorkerLink;
