import {
  DataIssueType,
} from '../sharedComponents/dataNotes/Utils';
import {
  Mode,
} from '../sharedComponents/exportsPanel/Utils';
import {
  VizType,
} from '../viz/Utils';
import {
  GRAPH_EXPLANATION_OVERLAY,
  IOverlayInfo,
  SHARING_EXPORTS_PANEL,
  SHOW_DATA_NOTES,
} from './Utils';

export const SHOW_OVERLAY = 'SHOW_OVERLAY';
export const HIDE_OVERLAY = 'HIDE_OVERLAY';

interface IShowOverlay {
  type: typeof SHOW_OVERLAY;
  payload: IOverlayInfo & {shouldCloseWhenClickedOutside: boolean};
}

interface IHideOverlay {
  type: typeof HIDE_OVERLAY;
}

export type IAction = IShowOverlay | IHideOverlay;

export const showOverlay = (info: IOverlayInfo, shouldCloseWhenClickedOutside: boolean): IShowOverlay => ({
  type: SHOW_OVERLAY,
  payload: {...info, shouldCloseWhenClickedOutside},
});

export const hideOverlay = (): IHideOverlay => ({type: HIDE_OVERLAY});

export const showGraphExplanationOverlay = (vizType: VizType): IShowOverlay => ({
  type: SHOW_OVERLAY,
  payload: {
    type: GRAPH_EXPLANATION_OVERLAY,
    info: vizType,
    shouldCloseWhenClickedOutside: true,
  },
});

export const showShare = (vizType: VizType): IShowOverlay => ({
  type: SHOW_OVERLAY,
  payload: {
    type: SHARING_EXPORTS_PANEL,
    initialMode: Mode.Share,
    vizType,
    shouldCloseWhenClickedOutside: true,
  },
});

export const showExports = (vizType: VizType): IShowOverlay => ({
  type: SHOW_OVERLAY,
  payload: {
    type: SHARING_EXPORTS_PANEL,
    initialMode: Mode.Export,
    vizType,
    shouldCloseWhenClickedOutside: true,
  },
});

export const showDataNotes = (dataIssues: DataIssueType[]): IShowOverlay => ({
  type: SHOW_OVERLAY,
  payload: {
    type: SHOW_DATA_NOTES,
    dataIssues,
    shouldCloseWhenClickedOutside: true,
  },
});
