import { always } from '../Utils';
import {
  defaultCountryTransformer,
  defaultNodeSizing,
  defaultNumberWithUndefinedTransformer,
  defaultProductClassTransformer,
  defaultTargetTransformer,
  defaultTradeDirection,
  defaultTradeFlowTransformer,
  defaultYearTransformer,
  URLTransformers,
} from '../viz/queryStoreUtils';

const transformers: URLTransformers = {
  country: defaultCountryTransformer,
  year: defaultYearTransformer,
  productClass: defaultProductClassTransformer,

  // Below are URL params that we don't care about:
  nodeSizing: {
    parse: always(defaultNodeSizing),
    defaultValue: defaultNodeSizing,
  },
  tradeDirection: {
    parse: always(defaultTradeDirection),
    defaultValue: defaultTradeDirection,
  },
  tradeFlow: defaultTradeFlowTransformer,
  product: defaultNumberWithUndefinedTransformer,
  startYear: defaultNumberWithUndefinedTransformer,
  target: defaultTargetTransformer,
  partner: defaultNumberWithUndefinedTransformer,
};

export default transformers;
