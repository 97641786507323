import React from 'react';
import {
  TutorialModalProps,
} from '../sharedComponents/newGraphShare';
import { SetActivePanelProps } from '../viz/Utils';
import Dynamic from './Dynamic';
import Static from './Static';

type Props = SetActivePanelProps & TutorialModalProps;

const MarketShareRoot = (props: Props) => {
  const {activePanel, setActivePanel, isTutorialModalOpen, setIsTutorialModalOpen} = props;
  return (
    <>
      <Dynamic
        isTutorialModalOpen={isTutorialModalOpen}
        setIsTutorialModalOpen={setIsTutorialModalOpen}
      />
      <Static
        activePanel={activePanel}
        setActivePanel={setActivePanel}
      />
    </>
  );
};

export default MarketShareRoot;
