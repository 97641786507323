import {
  IntrospectionResultData,
} from 'apollo-cache-inmemory';

export enum ProductLevel {
  section = 'section',
  twoDigit = 'twoDigit',
  fourDigit = 'fourDigit',
  sixDigit = 'sixDigit',
}

export enum LocationLevel {
  country = 'country',
  region = 'region',
}

export enum TradeDirection {
  export = 'export',
  import = 'import',
}

export enum TradeFlow {
  Gross = 'Gross',
  Net = 'Net',
}

export enum ProductType {
  Goods = 'Goods',
  Service = 'Service',
}

export enum ProductClass {
  HS = 'HS',
  SITC = 'SITC',
}

export enum Comparison {
  More = 'More',
  Less = 'Less',
  Same = 'Same',
  ModeratelyMore = 'ModeratelyMore',
  ModeratelyLess = 'ModeratelyLess',
}

export enum GrowthProjectionClassification {
  Rapid = 'rapid',
  Moderate = 'moderate',
  Slow = 'slow',
}

export enum DecileClassification {
  Top = 'Top',
  Second = 'Second',
  Third = 'Third',
  Fourth = 'Fourth',
  Fifth = 'Fifth',
  Sixth = 'Sixth',
  Seventh = 'Seventh',
  Eighth = 'Eighth',
  Ninth = 'Ninth',
  Last = 'Last',
  Empty = 'Empty',
}

export enum COIClassifiation {
  low = 'low',
  medium = 'medium',
  high = 'high',
}

export enum StructuralTransformationDirection {
  risen = 'risen',
  fallen = 'fallen',
  stagnated = 'stagnated',
}

export enum MarketShareMainSectorDirection {
  rising = 'rising',
  falling = 'falling',
  stagnant = 'stagnant',
}

export enum StructuralTransformationStep {
  NotStarted = 'NotStarted',
  TextilesOnly = 'TextilesOnly',
  ElectronicsOnly = 'ElectronicsOnly',
  MachineryOnly = 'MachineryOnly',
  Completed = 'Completed',
}

export enum NewProductsComments {
  TooFew = 'TooFew',
  Sufficient = 'Sufficient',
}

export enum NewProductsIncomeGrowthComments {
  LargeEnough = 'LargeEnough',
  TooSmall = 'TooSmall',
}

export enum NewProductsComplexityStatusGrowthPrediction {
  More = 'More',
  Same = 'Same',
  Less = 'Less',
}

export enum GDPPCConstantCAGRRegionalDifference {
  Above = 'Above',
  InLine = 'InLine',
  Below = 'Below',
}

export enum IncomeClassification {
  High = 'High',
  UpperMiddle = 'UpperMiddle',
  LowerMiddle = 'LowerMiddle',
  Low = 'Low',
}

export interface AllProductsInput {
  productClass: ProductClass;
  level: ProductLevel | null;
}

export interface IntForYear {
  quantity: number;
  year: number;
}

export interface BigIntForYear {
  quantity: number | null;
  year: number | null;
}

export interface FloatForYear {
  quantity: number | null;
  year: number;
}

export enum GrowthProjectionPercentileClassification {
  TopDecile = 'TopDecile',
  TopQuartile = 'TopQuartile',
  TopHalf = 'TopHalf',
  BottomHalf = 'BottomHalf',
}

export enum PolicyRecommendation {
  ParsimoniousIndustrial = 'ParsimoniousIndustrial',
  StrategicBets = 'StrategicBets',
  LightTouch = 'LightTouch',
  TechFrontier = 'TechFrontier',
}

export enum ComplexityLevel {
  low = 'low',
  moderate = 'moderate',
  high = 'high',
}

export enum ExportValueGrowthClassification {
  Troubling = 'Troubling',
  Mixed = 'Mixed',
  Static = 'Static',
  Promising = 'Promising',
}

export enum DiversificationGrade {
  APlus = 'APlus',
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  DMinus = 'DMinus',
}

export interface Product {
  code: string;
  description: string;
  id: string;
  longName: string;
  shortName: string;
  level: ProductLevel;
  parent: Product | null;
  topLevelParent: Product;
  type: ProductType;
  pci: number | null;
  pciYearRange: FloatForYear[];
  globalExportValue: number | null;
  globalExportValueChange: number | null;
  complexityLevel: ComplexityLevel | null;
  neverShow: boolean;
}

export interface AllLocationsInput {
  level: LocationLevel | null;
}

export interface Location {
  code: string;
  id: string;
  longName: string;
  shortName: string;
  nameAbbr: string | null;
  thePrefix: boolean;
  level: LocationLevel;
  parent: Location | null;
  topLevelParent: Product;
  eci: number | null;
  eciYearRange: FloatForYear[];
  eciRankYearRange: IntForYear[];
  coiYearRange: FloatForYear[];
  coiRankYearRange: IntForYear[];
  coi: number | null;
  coiClassification: COIClassifiation | null;
  isDataTrustworthy: boolean;
  isInComplexityRankings: boolean;
  hasReportedServicesInAnyYear: boolean;
  hasReportedServicesLastYear: boolean;
  exportValue: number | null;
  importValue: number | null;
  exportValueRank: number | null;
  latestNetTradeInGoodsAndServices: BigIntForYear  | null;
  latestECIRank: number | null;
  latestCOIRank: number | null;
  latestGDPPerCapita: IntForYear | null;
  latestGDPPerCapitaPPPRank: IntForYear | null;
  latestGDPRank: IntForYear | null;
  latestGDPPerCapitaRank: IntForYear | null;
  gdpPerCapitaPPP: IntForYear[];
  gdpPerCapita: IntForYear[];
  eciRank: IntForYear[];
  latestPopulation: IntForYear | null;
  growthProjection: number | null;
  growthProjectionRank: number | null;
  eciRankChange: number;
  diversityRank: number | null;
  diversityRankChange: number;
  diversityChange: number;
  gdpPcConstantCAGRRegionalDifference: GDPPCConstantCAGRRegionalDifference;
  exportValueGrowthCAGR: number;
  exportValueGrowthNonOilCAGR: number;
  gdpChangeCAGR: number;
  largestContributingExportProduct: Product[];
  exportValueGrowthClassification: ExportValueGrowthClassification;
  eciChange: number;
  latestGDPPerCapitaPPP: IntForYear | null;
  growthProjectionRelativeToIncome: Comparison | null;
  growthProjectionClassification: GrowthProjectionClassification | null;
  growthProjectionPercentileClassification: GrowthProjectionPercentileClassification | null;
  policyRecommendation: PolicyRecommendation | null;
  isInCountryPages: boolean;
  structuralTransformationStep: StructuralTransformationStep | null;
  structuralTransformationDirection: StructuralTransformationDirection | null;
  marketShareMainSector: Product | null;
  marketShareMainSectorDirection: MarketShareMainSectorDirection | null;
  marketShareMainSectorPositiveGrowth: boolean | null;
  currentAccount: number | null;
  diversificationGrade: DiversificationGrade | null;
  newProductExportValuePerCapita: number | null;
  newProductExportValue: number | null;
  newProductsComments: NewProductsComments | null;
  newProductsIncomeGrowthComments: NewProductsIncomeGrowthComments | null;
  newProductsComplexityStatusGrowthPrediction: NewProductsComplexityStatusGrowthPrediction | null;
  comparisonCountries: Location[] | null;
  newProducts: Product[];
  incomeClassification: IncomeClassification | null;
  eciNatResourcesGDPControlled: number | null;
  gdpChangeConstantCAGR: number | null;
  gdpPerCapitaChangeConstantCAGR: number | null;
  gdpGrowthConstant: number | null;
}

export type IntrospectionTypes = IntrospectionResultData['__schema']['types'];
