import gql from 'graphql-tag';
import { rgba } from 'polished';
import React from 'react';
import {
  Query,
  QueryResult,
} from 'react-apollo';
import styled from 'styled-components';
import ApolloProvider from '../../contextProviders/ApolloProvider';
import {
  generateStringLocationId,
} from '../../graphQL/dataFetchers/Utils';
import {
  Location,
} from '../../graphQL/graphQLTypes';
import { useLink } from '../../routing';
import { countryProfilesCountryParamName, RouteID } from '../../routing/routes';
import { borderRadius, titleOrange } from './Utils';

export const Root = styled.div`
  display: flex;
  justify-content: center;
`;

export const Button = styled.a`
  border: 1px solid ${titleOrange};
  padding: 1rem 1rem 1rem 0.5rem;
  width: 100%;
  margin-bottom: 1rem;
  text-transform: uppercase;
  position: relative;
  border-radius: ${borderRadius}px;
  text-align: center;

  &:hover {
    background-color: ${rgba(titleOrange, 0.2)};
  }
`;

export const SvgArrow = styled.span`
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0.3rem;
  display: flex;
  align-items: center;

  svg {
    height: 0.7rem;
    fill: ${titleOrange};
  }
`;

const query = gql`
  query excploreIsInCp($locationId: ID!) {
    location(id: $locationId) {
      id
      isInCountryPages
    }
  }
`;

interface SuccessResponse {
  location: {
    id: Location['id'],
    isInCountryPages: Location['isInCountryPages'],
  } | null;
}

interface Variables {
  locationId: string;
}

type Result = QueryResult<SuccessResponse, Variables>;

interface Props {
  country: number | undefined;
}

const ExploreCountryFurtherButton = (props: Props) => {
  const { country } = props;

  const locationId = country === undefined ? '' : generateStringLocationId(country);

  const variables: Variables = {locationId};

  const {href} = useLink({
    id: RouteID.CountryProfiles,
    pathParams: {
      [countryProfilesCountryParamName]: `${country}`,
    },
  });

  const renderProp = (result: Result) => {
    const {loading, error, data} = result;

    if (loading === true) {
      return null;
    } else if (error !== undefined) {
      console.error(error);
      return null;
    } else if (data !== undefined) {
      if (data.location && data.location.isInCountryPages === true) {
        return (
          <Button
          href={href}
          target='_blank'
          >
            {__lexiconText('exploreFurtherButton.countryProfile')}
            <SvgArrow
              dangerouslySetInnerHTML={{
                __html: require('../../countryProfiles/topProductsTable/diagonal-right-arrow.svg')}}
            />
          </Button>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  return (
    <Root>
      <ApolloProvider>
        <Query
          query={query}
          children={renderProp}
          variables={variables}
        />
      </ApolloProvider>
    </Root>
  );
};

export default ExploreCountryFurtherButton;
