import Downshift from 'downshift';
import React from 'react';
import {
  DispatchProp,
} from 'react-redux';
import {
  Action,
} from 'redux';
import styled from 'styled-components';
import {
  getCharacterWidthMap,
} from '../../collectFontMetrics';
import {
  ProductClass,
} from '../../graphQL/graphQLTypes';
import memoize from '../../memoize';
import {
  failIfValidOrNonExhaustive,
  ILoadable,
  LoadableStatus,
  ProductMetadatumLevel,
} from '../../Utils';
import {
  IMetadatum,
} from '../../workerStore/fetchedData/productMetadata';
import {
  isProductIdService,
} from '../getIsService';
import {
  getTotalTextHeight,
} from '../newPerformTextLayout';
import {
  convertMetadataToList as unmemoizedConvertMetadataToList,
  getHierarchicalItems as unmemoizedGetHierarchicalItems,
} from './convertProductMetadata';
import {
  getDownshiftRenderProp,
} from './downshiftRenderProp';
import {
  addInitialStateToDropdownItems,
  dropdownPlaceholderHeight,
  dropdownRowBottomPadding,
  dropdownRowTopPadding,
  expandSelectedItemAndParents,
  fourDigitBarLeftMargin,
  fourDigitBarWidth,
  getDisplayedItems,
  IHierarchicalItem,
  IItem,
  IItemWithHeight,
  IParentInfo,
  oneDigitBarLeftMargin,
  oneDigitBarWidth,
  regularTextLineHeight,
  sixDigitBarLeftMargin,
  sixDigitBarWidth,
  twoDigitBarLeftMargin,
  twoDigitBarWidth,
} from './Utils';

// We're making explicit the default `lineHeight` from CSS reset stylesheet:
const Root = styled.div`
  width: 100%;
  height: ${dropdownPlaceholderHeight}px;
  line-height: ${regularTextLineHeight};

  --one-digit-bar-width: ${oneDigitBarWidth}px;
  --one-digit-bar-left-margin: ${oneDigitBarLeftMargin}px;
  --two-digit-bar-width: ${twoDigitBarWidth}px;
  --two-digit-bar-left-margin: ${twoDigitBarLeftMargin}px;
  --four-digit-bar-width: ${fourDigitBarWidth}px;
  --four-digit-bar-left-margin: ${fourDigitBarLeftMargin}px;
  --six-digit-bar-width: ${sixDigitBarWidth}px;
  --six-digit-bar-left-margin: ${sixDigitBarLeftMargin}px;
`;

const characterWidthMapPromise = (async () => await getCharacterWidthMap())();

const defaultHeight = 16.099999999999998; // default height to use if layout is unsuccesful

const assignHeightToItems = (
    flatItems: Array<IItem<ProductMetadatumLevel>>,
    fullWidth: number,
    characterWidthMap: Map<string, number>,
    productClass: ProductClass,
  ) => {

  const flatItemsWithHeight: Array<IItemWithHeight<ProductMetadatumLevel>> = flatItems.map(
    ({primaryLabel, secondaryLabel, level, value, ...rest}) => {
      let leftMargin: number, barWidth: number;
      if (level === ProductMetadatumLevel.section) {
        leftMargin = oneDigitBarLeftMargin;
        barWidth = oneDigitBarWidth;
      } else if (level === ProductMetadatumLevel.twoDigit) {
        leftMargin = twoDigitBarLeftMargin;
        barWidth = twoDigitBarWidth;
      } else if (level === ProductMetadatumLevel.fourDigit) {
        leftMargin = fourDigitBarLeftMargin;
        barWidth = fourDigitBarWidth;
      } else if (level === ProductMetadatumLevel.sixDigit) {
        leftMargin = sixDigitBarLeftMargin;
        barWidth = sixDigitBarWidth;
      } else {
        failIfValidOrNonExhaustive(level, 'Invalid product level');
        leftMargin = 0;
        barWidth = 0;
      }

      const containerWidth = fullWidth - leftMargin - barWidth;

      // Product codes are not shown for services:
      const displayedText = isProductIdService(value, productClass) ?
                              primaryLabel :
                              primaryLabel + ' (' + secondaryLabel + ')';

      const layoutResult = getTotalTextHeight({
        text: displayedText,
        containerWidthInPixels: containerWidth,
        lineHeightFactor: regularTextLineHeight,
        characterWidthMap,
        fontSize: 14,
      });
      const height = layoutResult.success
        ? layoutResult.height + dropdownRowTopPadding + dropdownRowBottomPadding
        : defaultHeight + dropdownRowTopPadding + dropdownRowBottomPadding;
      return {
        ...rest,
        value,
        primaryLabel,
        secondaryLabel,
        height,
        level,
      };
    },
  );
  return flatItemsWithHeight;
};

const convertFlatItemsListToMap = (flatItems: Array<IItemWithHeight<ProductMetadatumLevel>>) => {
  const flatItemsAsMap: Map<number, IItemWithHeight<ProductMetadatumLevel>> = new Map(
    flatItems.map(item => ([item.value, item] as [number, IItemWithHeight<ProductMetadatumLevel>])),
  );
  return flatItemsAsMap;
};

function deriveSelectedItem<Level>(
    externalValue: number | undefined,
    items: Array<IItem<Level>>,
  ): IItem<Level> | null {
  let result: IItem<Level> | null;
  if (externalValue === undefined) {
    result = null;
  } else {
    const found = items.find(item => item.value === externalValue);
    if (found === undefined) {
      result = null;
    } else {
      result = found;
    }
  }
  return result;
}

function getInitialHighlightedIndex<Level>(
    selectedItem: IItem<Level> | null,
    hierarchicalItems: Array<IHierarchicalItem<Level>>,
    parentInfo: IParentInfo[],
  ): number {

  let result: number;
  if (selectedItem === null) {
    result = 0;
  } else {
    const itemsWithState = addInitialStateToDropdownItems(hierarchicalItems);
    const expandedItems = expandSelectedItemAndParents(itemsWithState, selectedItem.value, parentInfo);
    const displayedItems = getDisplayedItems(expandedItems);
    const selectedValue = selectedItem.value;
    const foundIndex = displayedItems.findIndex(({value}) => value === selectedValue);
    if (foundIndex > -1) {
      result = foundIndex;
    } else {
      result = 0;
    }
  }
  return result;
}

// This is a workdaround that always return an empty string so that when the
// user clicks into the placeholder (with an option already selected), the user
// sees the "prompt text" (e.g. "select a country") instead of the string value
// of the selected product:
const itemToString = () => '';

export interface IStateProps {
  flatItems: Array<IItem<ProductMetadatumLevel>>;
  parentInfo: IParentInfo[];
  metadataStatus: ILoadable<Map<number, IMetadatum>>;
  productClass: ProductClass;
}

export interface IOwnProps {
  clearable: boolean;
  value: number | undefined;
  onChange: (id: number | undefined) => void;
  promptText: string;
  fetchDataAction: Action;
  itemRenderer: (item: IItem<ProductMetadatumLevel>) => React.ReactNode;
}

type IProps = IOwnProps & IStateProps & DispatchProp;

interface IState {
  highlightedIndex: number;
  inputValue: string;
  isOpen: boolean;
  characterWidthMap: undefined | Map<string, number>;
  width: number | undefined;
}

export default class Dropdown extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      highlightedIndex: 0,
      inputValue: '',
      isOpen: false,
      characterWidthMap: undefined,
      width: undefined,
    };

    this.fetchCharaterWidthMap();
  }

  private rememberRootEl = (el: HTMLElement | null) => this.el = el;
  private el: HTMLElement | null = null;

  async fetchCharaterWidthMap() {
    const characterWidthMap = await characterWidthMapPromise;
    this.setState((prevState: IState) => ({...prevState, characterWidthMap}));
  }

  componentDidMount() {
    const {fetchDataAction, dispatch} = this.props;
    const {el} = this;
    if (el !== null) {
      const {width} = el.getBoundingClientRect();
      this.setState((prevState: IState) => ({...prevState, width}));
    }
    dispatch(fetchDataAction);
  }

  private onChange = (item: IItem<ProductMetadatumLevel> | null) => {
    const {onChange} = this.props;
    if (item === null) {
      onChange(undefined);
    } else {
      onChange(item.value);
    }
  }

  private getHierarchicalItems = memoize(unmemoizedGetHierarchicalItems);
  private convertMetadataToList = memoize(unmemoizedConvertMetadataToList);

  private openMenu = () => this.setState((prevState: IState) => {
    const {metadataStatus} = this.props;
    if (metadataStatus.status === LoadableStatus.Present) {
      const {flatItems, value, parentInfo, productClass} = this.props;
      const metadata = metadataStatus.data;
      const metadataList = this.convertMetadataToList(metadata);
      const selectedItem = deriveSelectedItem(value, flatItems);
      const hierarchicalItems = this.getHierarchicalItems(metadataList, productClass, metadata);
      const newHighlightedIndex = getInitialHighlightedIndex(
        selectedItem, hierarchicalItems, parentInfo,
      );
      return {
        ...prevState,
        isOpen: true,
        highlightedIndex: newHighlightedIndex,
      };
    } else {
      return null;
    }
  })

  private closeMenu = () => this.setState(
    (prevState: IState) => ({...prevState, isOpen: false}),
  )

  private toggleMenu = () => this.setState((prevState: IState) => {
    const {metadataStatus} = this.props;
    if (metadataStatus.status === LoadableStatus.Present) {
      const {isOpen: prevIsOpen} = prevState;
      const newIsOpen = !prevIsOpen;
      let newState: IState;
      if (newIsOpen === true) {
        const {
          flatItems, value, parentInfo, productClass,
        } = this.props;

        const metadata = metadataStatus.data;
        const metadataList = this.convertMetadataToList(metadata);
        const hierarchicalItems = this.getHierarchicalItems(metadataList, productClass, metadata);

        const selectedItem = deriveSelectedItem(value, flatItems);
        const newHighlightedIndex = getInitialHighlightedIndex(
          selectedItem, hierarchicalItems, parentInfo,
        );
        newState = {
          ...prevState,
          isOpen: newIsOpen,
          highlightedIndex: newHighlightedIndex,
        };
      } else {
        newState = {
          ...prevState,
          isOpen: newIsOpen,
        };
      }
      return newState;
    } else {
      return null;
    }
  })

  private onInputValueChange = (inputValue: string) => this.setState(
    (prevState: IState) => ({...prevState, inputValue}),
  )
  private incrementHighlightedIndex = () => this.setState(
    (prevState: IState): IState => ({...prevState, highlightedIndex: prevState.highlightedIndex + 1}),
  )

  private decrementHighlightedIndex = () => this.setState(
    (prevState: IState): IState => ({...prevState, highlightedIndex: prevState.highlightedIndex - 1}),
  )

  private setHighlightedIndex = (highlightedIndex: number) => this.setState(
    (prevState: IState): IState => ({...prevState, highlightedIndex}),
  )

  private assignHeightToItems = memoize(assignHeightToItems);

  private convertFlatItemsListToMap = memoize(convertFlatItemsListToMap);

  render() {
    const {
      value, flatItems, promptText, clearable, metadataStatus,
      itemRenderer, parentInfo, productClass,
    } = this.props;
    const {
      inputValue, highlightedIndex, isOpen,
      characterWidthMap, width: fullWidth,
    } = this.state;

    let dropdown: React.ReactNode;
    if (characterWidthMap !== undefined &&
        flatItems.length > 0 &&
        metadataStatus.status === LoadableStatus.Present &&
        fullWidth !== undefined) {
      const metadata = metadataStatus.data;
      const metadataList = this.convertMetadataToList(metadata);
      const hierarchicalItems = this.getHierarchicalItems(metadataList, productClass, metadata);

      const derivedSelectedItem = deriveSelectedItem(value, flatItems);

      const flatItemsWithHeight = this.assignHeightToItems(flatItems, fullWidth, characterWidthMap, productClass);

      const renderProp = getDownshiftRenderProp({
        flatItems: flatItemsWithHeight,
        flatItemsAsMap: this.convertFlatItemsListToMap(flatItemsWithHeight),
        hierarchicalItems,
        itemRenderer, promptText,
        clearable,
        inputValue,
        incrementHighlightedIndex: this.incrementHighlightedIndex,
        decrementHighlightedIndex: this.decrementHighlightedIndex,
        highlightedIndex,
        setHighlightedIndex: this.setHighlightedIndex,
        openMenu: this.openMenu,
        closeMenu: this.closeMenu,
        toggleMenu: this.toggleMenu,
        parentInfo,
        characterWidthMap,
        dropdownFullWidth: fullWidth,
        productClass,
      });

      dropdown = (
        <Downshift
          isOpen={isOpen}
          onInputValueChange={this.onInputValueChange}
          inputValue={inputValue}
          highlightedIndex={highlightedIndex}
          selectedItem={derivedSelectedItem}
          itemToString={itemToString}
          children={renderProp}
          onChange={this.onChange}
          onOuterClick={this.closeMenu}
          onSelect={this.closeMenu}
        />
      );
    } else {
      dropdown = null;
    }

    // Note: need the `Root` to measure the dropdown's width:
    return (
      <Root ref={this.rememberRootEl}>
        {dropdown}
      </Root>
    );

  }
}
