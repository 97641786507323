import {
  DataSource as Source,
  IDropdownOption,
} from '../../Utils';
import {
  GraphSubject as Subject,
  IDetailOverlayRow as IRow,
} from '../../viz/Utils';

// 3 ways that the stack graph can show "no data": 1) there's actually no data to
// show 2) user hasn't selected a country/product 3) user hasn't selected a
// country/partner.
export enum ErrorCode {
  NoData,
  PickCountryOrProduct,
  PickCountryOrPartner,
}

// How ribbons are ordered:
export enum Ordering {
  Community = 'Community',
  Total = 'Total',
}

// Whether each year's data should be an absolute value or percentage relative
// to the total for that year:
export enum Layout {
  Value = 'Value',
  Share = 'Share',
}

// Indicates whether or not the monetary values should be adjusted for inflation and population:
export enum InflationAdjustment {
  Adjusted = 'InflationAdjusted',
  NotAdjusted = 'InflationNotAdjusted',
}

export enum PopulationAdjustment {
  Adjusted = 'PopulationAdjusted',
  NotAdjusted = 'PopulationNotAdjusted',
}

// Data associated with each year within a ribbon:
export interface IYearDataPoint {
  year: number;
  // The value shown on the y-axis when user hovers over a particular year
  // within each ribbon:
  displayedValue: string;

  // Coordinates:
  x: number;
  lowerY: number;
  upperY: number;

  // The ID of the parent ribbon that this data point belongs to:
  ribbonId: number;

  tooltipInfo: IRow[];
  detailOverlayInfo: IRow[];
}

export type TextInRibbon = {
  showText: false,
} | {
  showText: true,
  centerX: number;
  centerY: number;
  width: number;
  height: number;
  angle: number;
  fontSize: number;
  // `textSplitIntoLines` are broken into separate lines for use in SVG which
  // does not support text wrapping. `textUnsplit` is used in DOM:
  textSplitIntoLines: string[]
  textUnsplit: string;
};

export interface IRibbon {
  dataPoints: IYearDataPoint[];
  id: number;
  // Top-level category:
  section: number;

  color: string;
  shortLabel: string;
  longLabel: string;

  // the `d` attribute of the ribbon's `path` element:
  path: string;

  // Layout info for ribbon label:
  textInRibbon: TextInRibbon;
}

export interface ITooltipMapDatum {
  id: number;
  shortLabel: string;
  longLabel: string;
  color: string;
}

export interface IMergeOutput {
  ribbons: IRibbon[];
  dropdownOptions: IDropdownOption[];
  tooltipMap: Record<string, ITooltipMapDatum>;
  yearsWithData: number[];
  subject: Subject;
  yAxisMin: number;
  yAxisMax: number;
  xAxisMin: number;
  xAxisMax: number;
  svgWidth: number;
  svgHeight: number;
}

export const getDataPointTooltipKey =
  ({ribbonId, year}: IYearDataPoint) => `tooltip-${ribbonId}-${year}`;

export type IDiscriminant = {
  source: Source.CPY,
  subject: Subject.Product,
} | {
  source: Source.CPY,
  subject: Subject.Country,
} | {
  source: Source.CCY,
  subject: Subject.Country,
} | {
  source: Source.CCPY,
  subject: Subject.Product,
} | {
  source: Source.CCPY,
  subject: Subject.Country,
};
