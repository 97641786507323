
import React, {
  lazy,
  Suspense,
  useEffect,
} from 'react';
import { ProductClass, TradeDirection, TradeFlow } from '../graphQL/graphQLTypes';
import { useQueryParams } from '../routing';
import {TutorialModalProps} from '../sharedComponents/newGraphShare';
import useTreeNavigation from '../tree/useNavigation';
import {
  scrollToTop,
  Target,
} from '../Utils';
import Controls , {
  CountryOrProduct,
  ITargetDropdownState,
  ITargetState,
  ITradeDirectionState,
  TargetDropdownType,
} from '../viz/controls';
import RouteLoading from '../viz/GraphRouteLoading';
import {
  URLAction,
} from '../viz/queryStoreUtils';
import { parseQueryParamsIntoQueryStore, useVizTypeChanger } from '../viz/routingUtils';
import { useNavigation } from '../viz/routingUtils';
import { clampYear, VizType } from '../viz/Utils';
import { SetActivePanelProps } from '../viz/Utils';
import {
  ActionType, InnerAction, ResetCountryAndTarget, SelectCountry, SelectProduct,
  SelectProductClass, SelectTradeDirection, SelectTradeFlow, SelectYear,
} from './queryStoreReducer';
import urlTransformers from './URLTransformers';

const Container = lazy(() => import(/* webpackChunkName: "geo" */ './container/index'));

type Props = SetActivePanelProps & TutorialModalProps;

const currentVizType = VizType.Geo;
const GeoMap = (props: Props) => {
  const {activePanel, setActivePanel, isTutorialModalOpen, setIsTutorialModalOpen} = props;
  useEffect(() => {
    scrollToTop();
  }, []);
  const queryParams = useQueryParams();
  const queryStore = parseQueryParamsIntoQueryStore(urlTransformers, queryParams);
  const {
    country, product, productClass, tradeDirection, tradeFlow,
  } = queryStore;
  const clampedYear = clampYear(productClass, queryStore.year);

  const vizType = VizType.Geo;

  const {push} = useNavigation<InnerAction>();

  const {changePartner} = useTreeNavigation();

  const changeCountry = (inputCountry: number | undefined) => {
    const action: URLAction<SelectCountry> = {
      vizType,
      innerAction: {type: ActionType.SELECT_COUNTRY, payload: {country: inputCountry}},
    };
    push(action);
  };

  const changeTradeDirection = (inputTradeDirection: TradeDirection) => {
    const action: URLAction<SelectTradeDirection> = {
      vizType,
      innerAction: {
        type: ActionType.SELECT_TRADE_DIRECTION,
        payload: {tradeDirection: inputTradeDirection},
      },
    };
    push(action);
  };
  const changeYear = (year: number) => {
    const action: URLAction<SelectYear> = {
      vizType,
      innerAction: {
        type: ActionType.SELECT_YEAR, payload: {year},
      },
    };
    push(action);
  };

  const changeProductClass = (inputProductClass: ProductClass) => {
    const action: URLAction<SelectProductClass> = {
      vizType,
      innerAction: {
        type: ActionType.SELECT_PRODUCT_CLASS,
        payload: {productClass: inputProductClass},
      },
    };
    push(action);
  };

  const changeTradeFlow = (inputTradeFlow: TradeFlow) => {
    const action: URLAction<SelectTradeFlow> = {
      vizType,
      innerAction: {
        type: ActionType.SELECT_TRADE_FLOW,
        payload: {tradeFlow: inputTradeFlow},
      },
    };
    push(action);
  };

  const changeProduct = (inputProduct: number | undefined) => {
    const action: URLAction<SelectProduct> = {
      vizType,
      innerAction: {
        type: ActionType.SELECT_PRODUCT,
        payload: {product: inputProduct},
      },
    };
    push(action);
  };

  const resetCountryAndTarget = () => {
    const action: URLAction<ResetCountryAndTarget> = {
      vizType,
      innerAction: {
        type: ActionType.RESET_COUNTRY_AND_TARGET,
        payload: {country: undefined, target: Target.Product},
      },
    };
    push(action);
  };

  const tradeDirectionProps: ITradeDirectionState = {
    isVisible: true,
    value: tradeDirection,
    onTradeDirectionChange: changeTradeDirection,
  };

  const targetProps: ITargetState = {
    isVisible: false,
  };

  const targetDropdownState: ITargetDropdownState = {
    isVisible: true,
    type: TargetDropdownType.Product,
    partner: queryStore.partner,
    onPartnerChange: changePartner,
    product, productClass,
    onProductChange: changeProduct,
  };

  const guidanceText = __lexiconText('guidanceText');

  const initialCountryOrProduct = queryStore.country === undefined && queryStore.product !== undefined
    ? CountryOrProduct.product : CountryOrProduct.country;

  const changeVizType = useVizTypeChanger();

  return (
    <>
      <Controls
        vizType={currentVizType}
        country={country} tradeDirectionProps={tradeDirectionProps}
        targetProps={targetProps} targetDropdownState={targetDropdownState}
        guidanceText={guidanceText}
        onCountryChange={changeCountry}
        onVizTypeChange={changeVizType}
        globalProductClass={productClass}
        globalProduct={queryStore.product}
        globalOnProductChange={changeProduct}
        initialCountryOrProduct={initialCountryOrProduct}
        resetCountryAndTarget={resetCountryAndTarget}
        activePanel={activePanel}
        setActivePanel={setActivePanel}
        key='controls'
      />
      <Suspense fallback={RouteLoading}>
        <Container
          country={country} product={product} year={clampedYear} productClass={productClass}
          tradeDirection={tradeDirection} tradeFlow={tradeFlow}
          onYearChange={changeYear}
          onProductClassChange={changeProductClass}
          onTradeFlowChange={changeTradeFlow}
          isTutorialModalOpen={isTutorialModalOpen}
          setIsTutorialModalOpen={setIsTutorialModalOpen}
        />
      </Suspense>
    </>
  );
};

export default GeoMap;
