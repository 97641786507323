import {
  DocumentNode,
} from 'graphql';
import React, {
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import {
  failIfValidOrNonExhaustive,
  xIconHTMLEntity,
} from '../../Utils';
import useClickOutsideToCloseDialogBehavior from '../newDataNotes/useClickOutsideToCloseDialogBehavior';
import Exports from './Exports';
import {
  GraphTypeConfig,
} from './Exports';
import ModeChoice from './ModeChoice';
import Share from './Share';
import {
  Background as BackgroundBase,
  CloseButton,
  ModeChoicesContainer,
  OuterContentContainer as OuterContentContainerBase,
} from './sharedStyles';
import {
  Mode,
} from './types';

const zIndices = {
  background: 10,
  content: 20,
};

//#region Styling
const Background = styled(BackgroundBase)`
  z-index: ${zIndices.background};
`;
const OuterContentContainer = styled(OuterContentContainerBase)`
  z-index: ${zIndices.content};
`;
const horizontalPadding = 15; // in `vw`
const verticalPadding = 20; // in `vh`

const InnerContentContainer = styled.div`
  display: grid;
  grid-template-rows: 40fr 150fr;

  width: calc(30vw + ${horizontalPadding}vw);
  height: calc(30vh + ${verticalPadding}vh);
  padding: 1rem 5% 0;
`;

const modeChoices: Array<{assignedMode: Mode; label: string}> = [
  {
    assignedMode: Mode.Share,
    label: __lexiconText('mode.share'),
  },
  {
    assignedMode: Mode.Export,
    label: __lexiconText('mode.export'),
  },
];

interface IProps<FetchedData, GraphData, Variables, OtherInputs> {
  initialMode: Mode;
  closeOverlay: () => void;
  variables: Variables;
  query: DocumentNode;
  otherInputs: OtherInputs;
  fetchedDataToGraphData: (fetchedData: FetchedData, variables: Variables) => GraphData;
  svgConfig: GraphTypeConfig<GraphData>;
  pdfConfig: GraphTypeConfig<GraphData>;
  pngConfig: GraphTypeConfig<GraphData>;
  graphTitle: string;
  vizId?: string;
}

function ExportsPanel<FetchedData, GraphData, Variables, OtherInputs>(
    props: IProps<FetchedData, GraphData, Variables, OtherInputs>,
  ) {
  const {
    initialMode, variables, query, otherInputs, fetchedDataToGraphData,
    svgConfig, pngConfig, pdfConfig, graphTitle, closeOverlay, vizId,
  } = props;
  const [mode, setMode] = useState<Mode>(initialMode);

  let content: React.ReactElement<any> | null;
  if (mode === Mode.Export) {
    content = (
      <Exports<FetchedData, GraphData, Variables, OtherInputs>
        variables={variables} query={query} otherInputs={otherInputs}
        fetchedDataToGraphData={fetchedDataToGraphData}
        svgConfig={svgConfig} pdfConfig={pdfConfig} pngConfig={pngConfig}
        graphTitle={graphTitle} vizId={vizId}
      />
    );
  } else if (mode === Mode.Share) {
    content = (<Share/>);
  } else {
    failIfValidOrNonExhaustive(mode, 'Invalid tab type ' + mode);
    // The following lines will never be executed:
    content = null;
  }

  const contentRootElRef = useRef<HTMLDivElement | null>(null);
  useClickOutsideToCloseDialogBehavior({
    containerElRef: contentRootElRef,
    closeDialog: closeOverlay,
  });

  const modes = modeChoices.map(({assignedMode, label}) => (
    <ModeChoice
      assignedMode={assignedMode} activeMode={mode}
      label={label} setMode={setMode}
    />
  ));

  return (
    <>
      <Background/>
      <OuterContentContainer ref={contentRootElRef}>
        <InnerContentContainer>
          <ModeChoicesContainer>{modes}</ModeChoicesContainer>
          {content}
          <CloseButton
            onClick={closeOverlay}
            dangerouslySetInnerHTML={{__html: xIconHTMLEntity}}
          />
        </InnerContentContainer>
      </OuterContentContainer>
    </>
  );
}

export default ExportsPanel;
