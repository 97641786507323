import { scaleLinear } from 'd3-scale';
import max from 'lodash-es/max';
import min from 'lodash-es/min';
import minBy from 'lodash-es/minBy';
import sortBy from 'lodash-es/sortBy';
import { generateStringProductId } from '../../graphQL/dataFetchers/Utils';
import { ProductClass } from '../../graphQL/graphQLTypes';
import {
  getCardinalSplinePathGenerator,
} from '../../NonConstantUtils';
import { getValueForKeyOrThrow } from '../../presentationManager/Utils';
import {
  groupByMap,
} from '../../Utils';
import {
  earliestHSYear,
  latestYear,
  newHSColorMap,
} from '../../Utils';
import {
  Line,
  Point,
} from './Chart';
import {
  ChartFetchedDatum, MouseCoords,
} from './index';

// Padding around the curves so that the the points are not clipped
// by the boundary of the SVG:
export const chartAreaPadding = {
  // in px
  top: 10,
  bottom: 10,
  left: 10,
  right: 10,
};

const generatePathFromPoints = getCardinalSplinePathGenerator();

interface FilteredDatum {
  marketShare: number;
  year: number;
  id: string;
  name: string;
}

interface SectorTooltipInfo {
  color: string;
  name: string;
  marketShare: number;
  id: string;
}

export type HoverYear = {
  isPresent: true
  year: number
  x: number
  y: number
  tooltipInfo: SectorTooltipInfo[],
} | {
  isPresent: false,
};

interface Input {
  width: number;
  height: number;
  data: ChartFetchedDatum[];
  selectedSectors: string[];
  mouseCoords: MouseCoords | undefined;
}
export const transform = ({width, height, data: unfilteredData, selectedSectors, mouseCoords}: Input) => {

  const otherCategoryID = generateStringProductId({productClass: ProductClass.HS, id: 9});
  const data = unfilteredData.filter(datum => datum.product.topLevelParent.id !== otherCategoryID);

  const selectedSectorsAsSet = new Set(selectedSectors);
  const filteredData: FilteredDatum[] = [];
  const getFilteredDataLookupKey = (input: {id: string, year: number}) => `${input.id}-${input.year}`;
  const filteredDataLookupTable = new Map<string, FilteredDatum>();
  for (const datum of data) {
    const {
      globalMarketShare, year,
      product: {
        id, shortName,
        topLevelParent: {id: category},
      },
    } = datum;
    if (globalMarketShare !== null && selectedSectorsAsSet.has(id) === true &&
          category !== otherCategoryID) {
      const filteredDatum: FilteredDatum = {
        marketShare: globalMarketShare,
        year,
        id,
        name: shortName,
      };
      filteredData.push(filteredDatum);
      filteredDataLookupTable.set(getFilteredDataLookupKey(filteredDatum), filteredDatum);
    }
  }
  const placeholderStartYear = earliestHSYear;
  const placeholderEndYear = latestYear;
  const placeholderMaxMarketShare = 0.01;

  const allYears = filteredData.map(({year}) => year);
  const minYear = min(allYears) || placeholderStartYear;
  const maxYear = max(allYears) || placeholderEndYear;

  const allMarketShares = filteredData.map(({marketShare}) => marketShare);
  const minMarketShare = 0;
  const maxMarketShare = max(allMarketShares) || placeholderMaxMarketShare;

  const xScale = scaleLinear<number, number>()
                  .domain([minYear, maxYear])
                  .range([chartAreaPadding.left, width - chartAreaPadding.right]);
  const yScale = scaleLinear<number, number>()
                  .domain([minMarketShare, maxMarketShare])
                  .nice()
                  .range([height - chartAreaPadding.bottom, chartAreaPadding.top]);
  const points = filteredData.map(datum => {
    const {
      marketShare, year, id, name,
    } = datum;
    const x = xScale(year);
    const y = yScale(marketShare);
    const color = newHSColorMap.get(id);
    if (color === undefined) {
      throw new Error('Cannot find color for sector ' + id);
    }
    const out: Point = {
      id, x, y, year, marketShare, name, color,
    };
    return out;
  });

  const sortedPoints = sortBy(sortBy(points, point => point.id), point => point.year);

  const groupedBySector = groupByMap(points, point => point.id);
  const sortedAndGroupedBySector = new Map(sortBy([...groupedBySector], ([sector ]) => sector));
  const lines: Line[] = [];
  for (const [sector, pointsInSector] of sortedAndGroupedBySector) {
    const sortedByYear = sortBy(pointsInSector, point => point.year);
    const path = generatePathFromPoints(sortedByYear);
    if (path === null) {
      throw new Error('generated path cannot be null');
    }
    const color = newHSColorMap.get(sector);
    if (color === undefined) {
      throw new Error('Cannot find color for sector ' + sector);
    }

    const line: Line = { id: sector, path, color };
    lines.push(line);
  }

  let hoverYear: HoverYear;
  if (mouseCoords === undefined) {
    hoverYear = {isPresent: false};
  } else {
    const xCoordsForPresentYears = allYears.map(year => ({
      year,
      x: xScale(year),
      distanceFromMouseX: Math.abs(xScale(year) - mouseCoords.x),
    }));
    const closestPoint = minBy(xCoordsForPresentYears, ({distanceFromMouseX}) => distanceFromMouseX);
    if (closestPoint === undefined) {
      hoverYear = {isPresent: false};
    } else {
      const closestYear = closestPoint.year;
      const unsortedRooltipInfo: SectorTooltipInfo[] = [];
      selectedSectors.forEach(id => {
        const map = filteredDataLookupTable;
        const key = getFilteredDataLookupKey({id, year: closestYear});
        const retrieved = map.get(key);
        if (retrieved !== undefined) {
          const {marketShare, name} = retrieved;
          const out: SectorTooltipInfo = {
            color: getValueForKeyOrThrow(newHSColorMap, id),
            name,
            marketShare,
            id,
          };
          unsortedRooltipInfo.push(out);
        }
      });
      const tooltipInfo = sortBy(unsortedRooltipInfo, elem => elem.marketShare).reverse();
      hoverYear = {
        isPresent: true,
        year: closestYear,
        x: closestPoint.x,
        y: mouseCoords.y,
        tooltipInfo,
      };
    }
  }

  // Note: lines are already sorted.
  return {
    lines,
    points: sortedPoints,
    hoverYear,
    maxYear, minYear,
    maxMarketShare, minMarketShare,
  };
};
