import { format } from 'd3-format';
import React from 'react';
import styled from 'styled-components';
import {
  ChartContainer,
} from './Grid';
import { HoverYear } from './Utils';

//#region styling
const colorMarkerSize = 10; // in px
const Root = styled(ChartContainer)`
  pointer-events: none;
`;
const Tooltip = styled.div`
  position: absolute;
  left: 0;
  padding: 1rem;
  display: grid;
  grid-template-columns: ${colorMarkerSize}px 1fr auto;
  grid-gap: 0.75rem;
  background-color: rgba(255, 255, 255, 0.85);
  box-shadow: 0 0 2px 1px hsl(0, 0%, 90%);
`;
const Title = styled.h4`
  margin: 0 0 0.25rem;
  padding-bottom: 0.25rem;
  border-bottom: 1px solid #b9bfc5;
  color: #3a4148;
  grid-column: 1 / 4;
`;

const SectorColor = styled.div`
  width: ${colorMarkerSize}px;
  height: ${colorMarkerSize}px;
  grid-column: 1;
  border-radius: 50%;
`;
const SectorTitle = styled.div`
  grid-column: 2;
`;
const MarketShare = styled.div`
  grid-column: 3;
`;
//#endregion

interface Props {
  hoverYear: HoverYear;
  zIndex: number;
  chartHeight: number;
}
const TooltipContainer = (props: Props) => {
  const {hoverYear, zIndex, chartHeight} = props;
  let tooltip: React.ReactElement<any> | null;
  if (hoverYear.isPresent === true) {
    const formatPercentage = format('.2~%');
    const tooltipRows = hoverYear.tooltipInfo.map(elem => {
      return (
        <React.Fragment
          key={`sector-${elem.id}`}
        >
          <SectorColor style={{backgroundColor: elem.color}}/>
          <SectorTitle>{elem.name}</SectorTitle>
          <MarketShare>{formatPercentage(elem.marketShare)}</MarketShare>
        </React.Fragment>);
    });
    const tooltipSeparationFromVerticalLine = '0.75rem';
    const xTranslation = `translateX(calc(${hoverYear.x}px + ${tooltipSeparationFromVerticalLine}))`;
    const yCoord = hoverYear.y;

    // If the mouse position is above the vertical midpoint of the chart, show
    // the tooltip below the cursor. Otherwise, show it above.
    let tooltipStyle: React.CSSProperties;
    if (yCoord < chartHeight / 2) {
      tooltipStyle = {
        transform: `${xTranslation} translateY(${yCoord}px)`,
        top: 0,
        bottom: undefined,
      };
    } else {
      tooltipStyle = {
        transform: `${xTranslation} translateY(${ - chartHeight + yCoord}px)`,
        top: undefined,
        bottom: 0,
      };
    }

    tooltip = (
      <Tooltip style={tooltipStyle}>
        <Title>Year: {hoverYear.year}</Title>
        {tooltipRows}
      </Tooltip>
    );
  } else {
    tooltip = null;
  }

  const style: React.CSSProperties = {
    zIndex,
  };

  return (
    <Root style={style}>
      {tooltip}
    </Root>
  );
};

export default TooltipContainer;
