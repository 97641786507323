import {
  ProductClass,
  TradeDirection,
  TradeFlow,
} from '../graphQL/graphQLTypes';
import {
  HS_to_SITC,
  SITC_to_HS,
} from '../sharedData/productCodeMapping';
import {
  Target,
} from '../Utils';
import {
  ALL_VIZ_TYPE_FORCED_NAVIGATION,
  ForcedNavigationAction,
  getProductIDForNewProductClass,
  QueryStore,
  QueryStoreReducer,
} from '../viz/queryStoreUtils';

export enum ActionType {
  SELECT_YEAR,
  SELECT_COUNTRY,
  SELECT_TRADE_DIRECTION,
  SELECT_PRODUCT_CLASS,
  SELECT_TRADE_FLOW,
  SELECT_PRODUCT,
  SELECT_TARGET,
  SELECT_PARTNER,
  RESET_COUNTRY_AND_TARGET,
}

export interface SelectYear {
  type: ActionType.SELECT_YEAR;
  payload: {year: number};
}
export interface SelectCountry {
  type: ActionType.SELECT_COUNTRY;
  payload: {country: number | undefined};
}
export interface SelectTradeDirection {
  type: ActionType.SELECT_TRADE_DIRECTION;
  payload: {tradeDirection: TradeDirection};
}
export interface SelectProductClass {
  type: ActionType.SELECT_PRODUCT_CLASS;
  payload: {productClass: ProductClass};
}

export interface SelectTradeFlow {
  type: ActionType.SELECT_TRADE_FLOW;
  payload: {tradeFlow: TradeFlow};
}

export interface SelectProduct {
  type: ActionType.SELECT_PRODUCT;
  payload: {product: number | undefined};
}

export interface SelectTarget {
  type: ActionType.SELECT_TARGET;
  payload: {target: Target};
}
export interface ResetCountryAndTarget {
  type: ActionType.RESET_COUNTRY_AND_TARGET;
  payload: {country: undefined, target: Target.Product};
}
export interface SelectPartner {
  type: ActionType.SELECT_PARTNER;
  payload: {partner: number | undefined};
}

export type InnerAction =
  SelectYear |
  SelectCountry |
  SelectTradeDirection |
  SelectProductClass |
  SelectTradeFlow |
  SelectProduct |
  SelectTarget |
  SelectPartner |
  ResetCountryAndTarget |
  ForcedNavigationAction;

const reducer: QueryStoreReducer<InnerAction> = (state: QueryStore, action: InnerAction) => {
  let newState: QueryStore;
  switch (action.type) {
    case ActionType.SELECT_YEAR:
      const {payload: {year}} = action;
      newState = {
        ...state,
        year,
      };
      break;
    case ActionType.SELECT_COUNTRY:
      const {payload: {country}} = action;
      newState = {
        ...state,
        country,
      };
      break;
    case ActionType.SELECT_TRADE_DIRECTION:
      const {payload: {tradeDirection}} = action;
      newState = {
        ...state,
        tradeDirection,
      };
      break;
    case ActionType.SELECT_PRODUCT_CLASS: {
      const {
        payload: {productClass: newProductClass},
      } = action;
      const {product, productClass} = state;

      // Switch to the equivalent product in the other classification if the
      // classification has changed:
      const newProductState = getProductIDForNewProductClass({
        SITC_to_HSMap: SITC_to_HS,
        HS_to_SITCMap: HS_to_SITC,
        currentProduct: product,
        newProductClass,
        currentProductClass: productClass,
      });
      newState = {
        ...state,
        ...newProductState,
      };
      break;
    }
    case ActionType.SELECT_TRADE_FLOW:
      const {payload: {tradeFlow}} = action;
      newState = {
        ...state,
        tradeFlow,
      };
      break;
    case ActionType.SELECT_PRODUCT: {
      const {payload: {product}} = action;
      newState = {
        ...state,
        product,
        partner: undefined,
      };
      break;
    }
    case ActionType.SELECT_PARTNER:
      const {payload: {partner}} = action;
      newState = {
        ...state,
        partner,
        product: undefined,
      };
      break;
    case ActionType.SELECT_TARGET:
      const {payload: {target}} = action;
      // When switching to a tab e.g. "partner", also clear out the value set by
      // the other tab:
      if (target === Target.Partner) {
        newState = {
          ...state,
          target,
          product: undefined,
        };
      } else {
        newState = {
          ...state,
          target,
          partner: undefined,
        };
      }
      break;
    case ActionType.RESET_COUNTRY_AND_TARGET:
      newState = {
        ...state,
        target: action.payload.target,
        country: undefined,
        partner: undefined,
      };
      break;
    case ALL_VIZ_TYPE_FORCED_NAVIGATION: {
      const {payload: {params}} = action;
      newState = {
        ...state,
        ...params,
      };
      break;
    }
    default:
      newState = state;
  }
  return newState;
};

export default reducer;
