import React from 'react';
import styled from 'styled-components';
import { primaryGray, secondaryGray } from '../../cssVariables';
import {
  VizType,
} from '../Utils';
import {
  IProps as ISelectorProps,
} from './TypeSelector';
import {
  borderRadius,
  selectedTextColor,
  textColor,
} from './Utils';

/* stylelint-disable no-descending-specificity */
const Container = styled.li`
  display: flex;
  align-items: center;
  border: 1px solid ${primaryGray};
  border-radius: ${borderRadius}px;
  margin: 3px;
  padding: 2px 4px;
  color: ${textColor};
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  flex-basis: 0;
  font-size: 0.75rem;

  svg {
    width: 36px;
    margin: 0.25rem 0;
  }

  &:hover {
    background-color: ${secondaryGray};
    color: ${selectedTextColor};

    svg {
      fill: ${selectedTextColor};
    }
  }
`;

const SvgContainerBase = styled.div`
  svg {
    fill: ${textColor};
  }
`;
const SvgContainerActive = styled.div`
  svg {
    fill: ${selectedTextColor};
  }
`;
const Text = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
`;
/* stylelint-enable */

interface IProps {
  title: string;
  value: VizType;
  selected: VizType;
  imgSrc: string;
  onChange: ISelectorProps['onChange'];
}

const isActive = ({value, selected}: IProps) => value === selected;

export default (props: IProps) => {
  const {
    imgSrc, title, value, onChange,
  } = props;

  const onClick = () => {
    if (!isActive(props)) {
      onChange(value);
    }
  };

  const active = isActive(props);
  const style: React.CSSProperties = {
    backgroundColor: active ? primaryGray : '',
    borderColor: active ? primaryGray : '',
    cursor: active ? '' : 'pointer',
    color: active ? selectedTextColor : undefined,
  };

  const SvgContainer = active ? SvgContainerActive : SvgContainerBase;
  return (
    <Container style={style}
      onClick={onClick}>
      <Text>
        {title}
      </Text>
      <SvgContainer dangerouslySetInnerHTML={{__html: imgSrc}}/>

    </Container>
  );
};
