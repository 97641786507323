import debounce from 'lodash/debounce';
import React, {MutableRefObject, useEffect, useState} from 'react';
import styled from 'styled-components';
import {latestYear} from '../../Utils';
import { getCountryName } from '../../Utils';
import {chartHeight, colors, LocationDatum, overtimeMinYear as minYear} from '../Utils';
import Axis from './Axis';
import Datapoint, {strokeColor} from './Datapoint';

const Root = styled.div`
  width: 100%;
  padding-top: ${chartHeight}px;
  position: relative;
`;

const Svg = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
`;

const CountriesGroup = styled.g`
  /* stylelint-disable no-descending-specificity */
  &:hover {
    g:not(.hovered):not(.spotlighted) {
      g {
        opacity: 0.3;

        rect {
          stroke-width: 0;
        }
      }
    }

    .svg-grid-line,
    .highlighted:not(.spotlighted),
    .chart-overlay-text {
      display: none;
    }
  }

  &.spotlight-on:not(:hover) {
    g:not(.highlighted) {
      g {
        opacity: 0.3;

        rect {
          stroke-width: 0;
        }
      }
    }

    .svg-grid-line,
    .chart-overlay-text {
      display: none;
    }
  }
  /* stylelint-enable no-descending-specificity */
`;

const linearColorScale = (minVal: number, maxVal: number) => {
  const totalRange = Math.abs(minVal) + Math.abs(maxVal);
  const rangeSize = totalRange / colors.length;
  const groups = colors.map( (color, i) => {
    const ceiling = minVal + (rangeSize * (i + 1));
    return {ceiling, color};
  });
  const getColorForRange = (value: number) => {
    const group = groups.find(({ceiling}, i) => {
      if (i === 0) {
        return (value >= minVal && value <= ceiling);
      } else {
        return (value > groups[i - 1].ceiling && value <= ceiling);
      }
    });
    if (group) {
      return group.color;
    } else {
      if (value >= maxVal) { // buffer check to solve rounding bug
        return colors[colors.length - 1];
      } else {
        return colors[0];
      }
    }
  };
  return getColorForRange;
};

export const axisMargin = {
  top: 30,
  bottom: 30,
  left: 120,
  right: 90,
};

interface Props {
  filteredCountries: LocationDatum[];
  highlighedLocations: LocationDatum[];
}

const Chart = React.forwardRef((props: Props, ref: MutableRefObject<HTMLDivElement | null>) => {
  const { filteredCountries, highlighedLocations } = props;

  const rootElRef = ref;

  const [dimensions, setDimensions] = useState<{width: number, height: number}>({width: 0, height: 0});
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    if (rootElRef && rootElRef.current) {
      const node = rootElRef.current;
      setDimensions({
        width: node.clientWidth,
        height: node.clientHeight,
      });
    }
  }, [rootElRef, windowWidth]);

  useEffect(() => {
    const updateWindowWidth = debounce(() => {
      setWindowWidth(window.innerWidth);
    }, 500);
    window.addEventListener('resize', updateWindowWidth);
    return () => {
      window.removeEventListener('resize', updateWindowWidth);
    };
  }, []);

  const minECI = -2;
  const maxECI = -2;

  const getColor = linearColorScale(minECI, maxECI);

  const totalYearsInclusive = latestYear - minYear + 1;

  const yMultiplier =
    (dimensions.height - axisMargin.top - axisMargin.bottom) / filteredCountries.length;
  const xMultiplier =
    (dimensions.width - axisMargin.left - axisMargin.right) / totalYearsInclusive;

  let content: React.ReactElement<any> |null;
  if (dimensions.width && dimensions.height) {
    const countries = filteredCountries.map(
      ({id, shortName, nameAbbr, thePrefix, eciRankYearRange, eciYearRange}, i) => {
        const {nameInChart} = getCountryName({shortName, nameAbbr, thePrefix});
        return (
          <Datapoint
            id={id}
            name={nameInChart}
            eciRankYearRange={eciRankYearRange}
            eciYearRange={eciYearRange}
            xMultiplier={xMultiplier}
            yMultiplier={yMultiplier}
            getColor={getColor}
            minECI={minECI}
            minYear={minYear}
            topBuffer={axisMargin.top}
            leftBuffer={axisMargin.left}
            highlighted={false}
            spotlighted={false}
            spotlightOn={highlighedLocations.length === 1}
            key={id + shortName + i}
          />
        );
      });
    const highlightedCountries = highlighedLocations.map(
      ({id, shortName, nameAbbr, thePrefix, eciRankYearRange, eciYearRange}, i) => {
        const {nameInChart} = getCountryName({shortName, nameAbbr, thePrefix});
        const spotlighted = highlighedLocations.length === 1;
        return (
          <Datapoint
            id={id}
            name={nameInChart}
            eciRankYearRange={eciRankYearRange}
            eciYearRange={eciYearRange}
            xMultiplier={xMultiplier}
            yMultiplier={yMultiplier}
            getColor={getColor}
            minECI={minECI}
            minYear={minYear}
            topBuffer={axisMargin.top}
            leftBuffer={axisMargin.left}
            highlighted={true}
            spotlighted={spotlighted}
            spotlightOn={highlighedLocations.length === 1}
            key={id + shortName + i}
          />
        );
      });
    content = (
      <>
        <CountriesGroup className={highlighedLocations.length === 1 ? 'spotlight-on' : undefined}>
          {countries}
          {highlightedCountries}
          <Axis
            numberOfCountries={filteredCountries.length}
            numberOfYears={totalYearsInclusive}
            width={dimensions.width}
            height={dimensions.height}
            margins={axisMargin}
            countrySpacing={yMultiplier}
            yearSpacing={xMultiplier}
            minYear={minYear}
            highlighedLocations={highlighedLocations}
            spotlightOn={highlighedLocations.length === 1}
          />
        </CountriesGroup>
      </>
    );
  } else {
    content = null;
  }

  return (
    <Root ref={rootElRef} key={windowWidth}>
      <Svg
        style={{width: dimensions.width, height: dimensions.height, backgroundColor: '#fff'}}
        viewBox={`0 0 ${dimensions.width} ${dimensions.height}`}
      >
        <defs>
          <marker id='arrowhead' markerWidth='4' markerHeight='4'
                  orient='auto' refY='2'>
            <path d='M0,0 L4,2 0,4' fill={strokeColor} />
          </marker>
        </defs>
        {content}
      </Svg>
    </Root>
  );
});

export default Chart;
