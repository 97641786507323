import React from 'react';
import styled from 'styled-components';
import App from './App';
import ApolloProvider from './contextProviders/ApolloProvider';
import HomePageLanguageProvider from './contextProviders/HomePageLanguageProvider';
import ReduxProvider from './contextProviders/ReduxProvider';
import { GlobalStyle } from './globalStyles';
import SurveyWidget from './sharedComponents/analytics/SurveyWidget';

const Snap = styled.div`
  border: 1px red dashed;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    margin: 0;
  }

  img {
    width: 100px;
    height: 100px;
  }

  .snap-message {
    padding: 12px;
  }
`;

interface IState {
  hasError: boolean;
}
export default class extends React.Component<{}, IState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      hasError: false,
    };
  }
  componentDidCatch(error: any, errorInfo: any) {
    this.setState({hasError: true });
    const RAVEN_STATUS = window.RAVEN_STATUS;
    if (RAVEN_STATUS.isEnabled === true) {
      RAVEN_STATUS.Raven.captureException(error, { extra: errorInfo });
    }
  }

  private showGlossaryForm() {
    const RAVEN_STATUS = window.RAVEN_STATUS;
    if (RAVEN_STATUS.isEnabled === true) {
      const Raven = RAVEN_STATUS.Raven;
      if (Raven.lastEventId()) {
        Raven.showReportDialog();
      }
    }
  }
  render() {
    const {hasError} = this.state;

    let content: React.ReactElement<any>;
    if (hasError === true) {
      let glossaryButton: JSX.Element | null;
      const RAVEN_STATUS = window.RAVEN_STATUS;
      if (RAVEN_STATUS.isEnabled === true) {
        glossaryButton = (
          <p>Our team has been notified, but click
              <button  onClick={this.showGlossaryForm}>
            here
            </button> to fill out a report.
          </p>
        );
      } else {
        glossaryButton = null;
      }
      content = (
        <Snap>
          <img src={require('./img/svgs-loaded-with-file-loader/sentry-aw-snap.svg')} />
          <div className='snap-message'>
            <p>We're sorry - something's gone wrong.</p>
            {glossaryButton}
          </div>
        </Snap>

      );

    } else {
      content = ( <App/>);
    }

    return (
      <ApolloProvider>
        <ReduxProvider>
          <HomePageLanguageProvider>
            <GlobalStyle/>
            {content}
            <SurveyWidget />
          </HomePageLanguageProvider>
        </ReduxProvider>
      </ApolloProvider>
    );
  }
}
