import React from 'react';
import {
  connect,
  MapStateToProps,
} from 'react-redux';
import {
  ProductClass,
} from '../../graphQL/graphQLTypes';
import memoize from '../../memoize';
import {
  IRootState,
} from '../../rootState';
import {
  getDataSelector,
} from '../../sharedData/newProductMetadata';
import {
  failIfValidOrNonExhaustive,
  LoadableStatus,
  ProductMetadatumLevel as Level,
} from '../../Utils';
import {
  convertMetadataToList as unmemoizedConvertMetadataToList,
  convertToItems as unmemoizedConvertToItems,
} from './convertProductMetadata';
import Dropdown, {
  IOwnProps,
  IStateProps,
} from './index';
import {
  IItem,
  IParentInfo,
} from './Utils';
const styles = require('./productDropdown.css');
import {
  isProductIdService,
} from '../getIsService';

// Allow CSS custom properties
declare module 'csstype' {
  interface Properties {
    '--bar-color'?: string;
  }
}

export const getProductRenderer =
  (productClass: ProductClass) =>
    ({level, color, secondaryLabel, primaryLabel, value}: IItem<Level>) => {

  let classNameByLevel: string;
  if (level === Level.section) {
    classNameByLevel = styles.oneDigit;
  } else if (level === Level.twoDigit) {
    classNameByLevel = styles.twoDigit;
  } else if (level === Level.fourDigit) {
    classNameByLevel = styles.fourDigit;
  } else if (level === Level.sixDigit) {
    classNameByLevel = styles.sixDigit;
  } else {
    failIfValidOrNonExhaustive(level, 'Invalid product level');
    classNameByLevel = '';
  }
  const style = {
    '--bar-color': color,
  };

  // Omit product codes for services:
  let mainContent: React.ReactNode;
  if (isProductIdService(value, productClass)) {
    mainContent = (
      <span >{primaryLabel}</span>
    );
  } else {
    mainContent = (
      <>
        <span >{primaryLabel}</span>
        {' '}
        <span className={styles.secondaryLabel}>({secondaryLabel})</span>
      </>
    );
  }

  return (
    <div className={`${classNameByLevel} ${styles.shared}`} style={style}>
      <div className={styles.bar}/>
      <div className={styles.text}>{mainContent}</div>
    </div>
  );
};

function getMapStateToProps(
    productClass: ProductClass,
  ): () => MapStateToProps<IStateProps, IOwnProps, IRootState> {
  const convertMetadataToList = memoize(unmemoizedConvertMetadataToList);
  const convertToItems = memoize(unmemoizedConvertToItems);
  return () => {
    const getMetadata = getDataSelector();
    return (rootState: IRootState) => {
      const metadataStatus = getMetadata(rootState, {productClass});
      let flatItems: Array<IItem<Level>>;
      let parentInfo: IParentInfo[];
      if (metadataStatus.status === LoadableStatus.Present) {
        const metadataMap = metadataStatus.data;
        const metadataList = convertMetadataToList(metadataStatus.data);
        flatItems = convertToItems(metadataList, productClass, metadataMap);
        parentInfo = metadataList;
      } else {
        flatItems = [];
        parentInfo = [];
      }
      return {
        flatItems,
        parentInfo,
        metadataStatus,
        productClass,
      };
    };
  };
}

export const HSProductDropdown = connect(getMapStateToProps(ProductClass.HS))(Dropdown);
export const SITCProductDropdown = connect(getMapStateToProps(ProductClass.SITC))(Dropdown);
