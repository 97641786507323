import {
  DecileClassification,
  DiversificationGrade,
  ProductClass,
  ProductLevel,
} from '../graphQLTypes';

export const generateStringLocationId = (id: number) => `location-${id}`;
export const extractFromStringLocationId = (stringLocationId: string): number => {
  const matched = stringLocationId.match(/location-(\d+)/);
  if (matched === null) {
    throw new Error('Invalid string location id ' + stringLocationId);
  }

  return parseInt(matched[1], 10);
};

interface ProductIDGeneratorInput {
  productClass: ProductClass;
  id: number;
}

export const generateStringProductId =
  ({productClass, id}: ProductIDGeneratorInput) => `product-${productClass}-${id}`;
export const extractFromStringProductId = (stringProductId: string): ProductIDGeneratorInput => {
  const matched = stringProductId.match(/product-(HS|SITC)-(\d+)/);
  if (matched === null) {
    throw new Error('Invalid string product id ' + stringProductId);
  }

  return {
    id: parseInt(matched[2], 10),
    productClass: matched[1] as ProductClass,
  };
};

// This correspondence between numeric product ID and product level is based on
// how the IDs are assigned in the datbase (ask Brendan).
export const getLevelForProductID = (id: string): ProductLevel => {
  const {id: numericId} = extractFromStringProductId(id);
  let productLevel: ProductLevel;
  if (numericId >= 5000) {
    productLevel = ProductLevel.sixDigit;
  } else if (numericId >= 650 && numericId <= 4500) {
    productLevel = ProductLevel.fourDigit;
  } else if (numericId >= 100 && numericId <= 405) {
    productLevel = ProductLevel.twoDigit;
  } else {
    productLevel = ProductLevel.section;
  }
  return productLevel;
};

export const getProductClassFromId = (id: string): ProductClass | null => {
  const classAsString = id.split('-')[1];
  if (classAsString === 'HS') {
    return ProductClass.HS;
  } else if (classAsString === 'SITC') {
    return ProductClass.SITC;
  } else {
    return null;
  }
};

interface Deciles {
  percentile90: number;
  percentile80: number;
  percentile70: number;
  percentile60: number;
  percentile50: number;
  percentile40: number;
  percentile30: number;
  percentile20: number;
  percentile10: number;

}
export const decilesToDecileClassification = (value: number, deciles: Deciles): DecileClassification => {
  const {
    percentile90, percentile80,
    percentile70, percentile60,
    percentile50, percentile40,
    percentile30, percentile20, percentile10,
  } = deciles;
  if (value > percentile90) {
    return DecileClassification.Top;
  } else if (value > percentile80) {
    return DecileClassification.Ninth;
  } else if (value > percentile70) {
    return DecileClassification.Eighth;
  } else if (value > percentile60) {
    return DecileClassification.Seventh;
  } else if (value > percentile50) {
    return DecileClassification.Sixth;
  } else if (value > percentile40) {
    return DecileClassification.Fifth;
  } else if (value > percentile30) {
    return DecileClassification.Fourth;
  } else if (value > percentile20) {
    return DecileClassification.Third;
  } else if (value > percentile10) {
    return DecileClassification.Second;
  } else {
    return DecileClassification.Last;
  }
};

export const gradeToString = (grade: DiversificationGrade): string => {
  if (grade === DiversificationGrade.APlus) {
    return 'A+';
  } else if (grade === DiversificationGrade.DMinus) {
    return 'D-';
  } else {
    return grade as string;
  }
};
