import {
  format,
} from 'd3-format';
import React from 'react';
import {
  Option,
} from 'react-select';
const styles = require('./countryOption.css');

// pad number to with zeros: 12 becomes 012:
const formatFlagIndex = format('03');

interface ICountryProps {
  id: number;
  label: string;
}

class CountryOption extends React.PureComponent<ICountryProps> {
  render() {
    const {id, label} = this.props;
    const imageSource = require(
      '../../img/svgs-loaded-with-file-loader/country_flags/Flag-' + formatFlagIndex(id) + '.svg',
    );

    return (
      <div className={styles.root}>
        <div className={styles.flag}>
          <img src={imageSource}/>
        </div>
        <div className={styles.name}>{label}</div>
      </div>
    );
  }
}

export const optionRenderer = ({value, label}: Option) => (
 <CountryOption id={value as number} label={label as string}/>
);
