import { useEffect } from 'react';
import { pageview, set } from 'react-ga';
import { useLocation } from './routing';
const {
  engagementGAEventName,
 } = require('../buildConstants');

const useGoogleAnalytics = () => {
  const retrievedLocation = useLocation();
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      const trackPage = (page: string) => {
        set({page});
        pageview(page);
      };
      const {pathname, search} = retrievedLocation;
      trackPage(pathname + search);
    }
  }, [retrievedLocation]);
};

export const googleAnalyticsEvent = (action: string, label: string) => {
  if (process.env.NODE_ENV === 'production') {
    (window as any).ga('send', {
      hitType: 'event',
      eventCategory: engagementGAEventName,
      eventLabel: label,
      eventAction: action,
    });
  }
};

export default useGoogleAnalytics;
