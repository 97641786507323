import React from 'react';
import {
  connect,
  DispatchProp,
  MapStateToProps,
} from 'react-redux';
import styled from 'styled-components';
import {
  demiFontWeight,
} from '../cssVariables';
import ECIEvolution, {
  OwnProps as ECIEvolutionOwnProps,
} from '../eciEvolution';
import FeasibilityExports from '../feasibility/ExportsSharingPanel';
import GeoExports from '../geoMap/ExportsSharingPanel';
import GraphExplanation from '../graphExplanation';
import ProductSpaceExports from '../network/ExportsSharingPanel';
import RingsExports from '../rings/ExportsSharingPanel';
import {
  IRootState,
} from '../rootState';
import DataNotes from '../sharedComponents/dataNotes';
import StackExports from '../stack/ExportsSharingPanel';
import {
  failIfValidOrNonExhaustive,
  xIconHTMLEntity,
} from '../Utils';
import {
  VizType,
} from '../viz/Utils';
import {
  hideOverlay,
} from './actions';
import {
  getOverlayState,
} from './reducers';
import {
  ECI_OVERLAY,
  GRAPH_EXPLANATION_OVERLAY,
  IOverlayState,
  SHARING_EXPORTS_PANEL,
  SHOW_DATA_NOTES,
} from './Utils';

type StateProps = {
  // Need to have this extra level of nesting because the type definition for `connect()`
  // can't handle union types:
  stateProps: IOverlayState,
};

interface OwnProps {
  backgroundZIndex: number;
  contentZIndex: number;
}

type IProps = OwnProps & StateProps & DispatchProp;

const Background = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: grey;
  opacity: 0.5;
`;
const Content = styled.div`
  position: fixed;
  top: 50vh;
  left: 50vw;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transform: translateX(-50%) translateY(-50%);
  border-radius: 1rem;
`;

const CloseButton = styled.div`
  position: absolute;
  top: 1vh;
  right: 1vw;
  cursor: pointer;
  color: #aaa;
  font-size: 1.6rem;
  font-weight: ${demiFontWeight};
  padding: 12px;
`;

interface IState {
  viewportWidth: number | undefined;
  viewportHeight: number | undefined;
}

class Overlay extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      viewportWidth: undefined,
      viewportHeight: undefined,
    };
  }
  private closeOverlay = () => {
    this.props.dispatch(hideOverlay());
  }

  private eciOverlayEl: HTMLElement | null = null;
  private rememberECIContainerEl = (el: HTMLElement | null) => {
    this.eciOverlayEl = el;
    if (el === null) {
      this.setState(state => ({
        ...state,
        viewportWidth: undefined,
        viewportHeight: undefined,
      }));
      window.removeEventListener('click', this.onECIClick);
    } else {
      const documentElement = document.documentElement;
      if (documentElement === null) {
        throw new Error('document.documentElement is null');
      }
      const viewportHeight = documentElement.clientHeight;
      const viewportWidth = documentElement.clientWidth;
      this.setState(state => ({
        ...state,
        viewportWidth, viewportHeight,
      }));
      window.addEventListener('click', this.onECIClick);
    }
  }
  private onECIClick = (event: MouseEvent) => {
    if (this.eciOverlayEl !== null && !this.eciOverlayEl.contains(event.target as Node)) {
      const {stateProps} = this.props;
      if (stateProps.isOverlayShown === true && stateProps.shouldCloseWhenClickedOutside === true) {
        this.closeOverlay();
      }
    }
  }

  render() {
    const {stateProps} = this.props;
    const {viewportHeight, viewportWidth} = this.state;
    if (stateProps.isOverlayShown === true) {
      const {overlayInfo} = stateProps;
      let content: React.ReactNode;
      switch (overlayInfo.type) {
        case ECI_OVERLAY:
          if (viewportWidth === undefined || viewportHeight === undefined) {
            const ownProps: ECIEvolutionOwnProps = {
              isInOverlay: true,
              hasSize: false,
            };
            content = (
              <ECIEvolution ownProps={ownProps}/>
            );
          } else {
            const ownProps: ECIEvolutionOwnProps = {
              hasSize: true,
              isInOverlay: true,
              viewportWidth, viewportHeight,
            };
            content = (
              <ECIEvolution ownProps={ownProps}/>
            );

          }
          break;
        case GRAPH_EXPLANATION_OVERLAY: {
          const {info: vizType} = overlayInfo;
          content = (
            <GraphExplanation vizType={vizType}/>
          );
          break;
        }

        case SHARING_EXPORTS_PANEL: {
          const {initialMode, vizType} = overlayInfo;
          switch (vizType) {
            case VizType.Rings:
              content = (
                <RingsExports initialMode={initialMode}/>
              );
              break;

            case VizType.Stack:
              content = (
                <StackExports initialMode={initialMode}/>
              );
              break;

            case VizType.Network:
              content = (
                <ProductSpaceExports initialMode={initialMode}/>
              );
              break;

            case VizType.Feasibility:
              content = (
                <FeasibilityExports initialMode={initialMode}/>
              );
              break;

            case VizType.Tree:
              content = (
                <div/>
              );
              break;

            case VizType.MarketShare:
              content = (
                <div/>
              );
              break;

            case VizType.Geo:
              content = (
                <GeoExports initialMode={initialMode}/>
              );
              break;
            default:
              failIfValidOrNonExhaustive(vizType, 'Invalid viz type');
              content = (<div/>);
              break;
          }
          break;
        }
        case SHOW_DATA_NOTES:
          const {dataIssues} = overlayInfo;
          content = (
            <DataNotes dataIssues={dataIssues}/>
          );
          break;
        default:
          failIfValidOrNonExhaustive(overlayInfo, 'Invalid overlay type');
          throw new Error('Invalid type');
      }

      return (
        <>
          <Background style={{zIndex: this.props.backgroundZIndex}}/>
          <Content ref={this.rememberECIContainerEl} style={{zIndex: this.props.contentZIndex}}>
            {content}
            <CloseButton
              onClick={this.closeOverlay}
              dangerouslySetInnerHTML={{__html: xIconHTMLEntity}}
            />
          </Content>
        </>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps: MapStateToProps<StateProps, {}, IRootState>  =
  (state: IRootState) => ({stateProps: getOverlayState(state)});

export default connect(mapStateToProps)(Overlay);
