import gql from 'graphql-tag';
import {darken} from 'polished';
import React from 'react';
import {
  Query,
  QueryResult,
} from 'react-apollo';
import { CSVLink } from 'react-csv';
import styled from 'styled-components';
import Loading from '../../countryRankings/Loading';
import {LocationDatum, minYear} from '../../countryRankings/Utils';
import { AllProductsInput, LocationLevel, Product, ProductClass, ProductLevel } from '../../graphQL/graphQLTypes';
import { latestYear } from '../../Utils';

const Title = styled.h2`
  font-weight: 400;
`;

const DownloadButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 2rem;
  margin-bottom: 1rem;
`;

const DownloadButton = styled(CSVLink)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  border: none;
  background-color: #eaf0f4;
  padding: 1rem 0.5rem;
  border-radius: 8px;

  &:hover {
    background-color: ${darken(0.1, '#eaf0f4')};
  }

  svg {
    width: 80px;
    margin-bottom: 0.5rem;
  }
`;

const query = gql`
  query RankingsData(
    $queryVariables: AllLocationsInput!,
    $minYear: Int!,
    $maxYear: Int!,
    $productClass: ProductClass!,
    $productsInput: AllProductsInput!,
  ) {
    locations: allLocations(input: $queryVariables) {
      id
      shortName
      code
      eciYearRange(minYear: $minYear, maxYear: $maxYear, productClass: $productClass) {
        quantity
        year
      }
      eciRankYearRange(minYear: $minYear, maxYear: $maxYear, productClass: $productClass) {
        quantity
        year
      }
      coiYearRange(minYear: $minYear, maxYear: $maxYear, productClass: $productClass) {
        quantity
        year
      }
      coiRankYearRange(minYear: $minYear, maxYear: $maxYear, productClass: $productClass) {
        quantity
        year
      }
      isInComplexityRankings
    }
    products: allProducts(input: $productsInput) {
      id
      longName
      code
      pciYearRange(minYear: $minYear, maxYear: $maxYear) {
        quantity
        year
      }
    }
  }
`;

interface SuccessResponse {
  locations: LocationDatum[];
  products: Array<{
    id: Product['id'];
    longName: Product['longName'];
    code: Product['code'];
    pciYearRange: Product['pciYearRange'];
  }>;
}

interface Variables {
  queryVariables: {
    level: LocationLevel;
  };
  productClass: ProductClass;
  minYear: number;
  maxYear: number;
  productsInput: AllProductsInput;
}

type Result = QueryResult<SuccessResponse, Variables>;

interface ProductsCSVRow {
  ['HS4 Code']: string;
  Product: string;
  [key: string]: number | string;
}
interface CountryCSVRow {
  Country: string;
  [key: string]: number | string;
}

const AllDownloads = () => {

  const renderProp = (result: Result) => {
    const {loading, error, data} = result;
    if (loading) {
      return <Loading />;
    } else if (error !== undefined) {
      console.error(error);
      return <p>Error: {error.message}</p>;
    } else if (data !== undefined) {
      const {products, locations} = data;
      const flattenedProductData: ProductsCSVRow[] = [];
      products.forEach(({longName, code, pciYearRange}) => {
        const yearFields: {[key: string]: number | string} = {};
        pciYearRange.forEach(({year, quantity}) => {
          const pci = quantity !== null ? quantity : '';
          yearFields[`PCI ${year}`] = pci;
        });
        flattenedProductData.push({
          ['HS4 Code']: code,
          Product: longName,
          ...yearFields,
        });
      });
      const flattenedCountryData: CountryCSVRow[] = [];
      locations.forEach(
        ({shortName, eciYearRange, eciRankYearRange, coiYearRange, coiRankYearRange, isInComplexityRankings}) => {
        if (isInComplexityRankings) {
          const eciValueYearFields: {[key: string]: number | string} = {};
          eciYearRange.forEach(({year, quantity}) => {
            const eci = quantity !== null ? quantity : '';
            eciValueYearFields[`ECI ${year}`] = eci;
          });
          const eciRankYearFields: {[key: string]: number | string} = {};
          eciRankYearRange.forEach(({year, quantity}) => {
            const eciRank = quantity !== null ? quantity : '';
            eciRankYearFields[`ECI Rank ${year}`] = eciRank;
          });
          const coiValueYearFields: {[key: string]: number | string} = {};
          coiYearRange.forEach(({year, quantity}) => {
            const coi = quantity !== null ? quantity : '';
            coiValueYearFields[`COI ${year}`] = coi;
          });
          const coiRankYearFields: {[key: string]: number | string} = {};
          coiRankYearRange.forEach(({year, quantity}) => {
            const coiRank = quantity !== null ? quantity : '';
            coiRankYearFields[`COI Rank ${year}`] = coiRank;
          });
          flattenedCountryData.push({
            Country: shortName,
            ...eciRankYearFields,
            ...eciValueYearFields,
            ...coiValueYearFields,
            ...coiRankYearFields,
          });
        }
      });
      return (
        <>
          <DownloadButton
            data={flattenedCountryData}
            filename={`Country Complexity Rankings ${minYear} - ${latestYear}.csv`}
          >
            <div
              dangerouslySetInnerHTML={{__html: require('../../countryRankings/img/dataDownload.svg')}}
            />
            Download data for
            <br />
            <strong>Country Complexity Rankings</strong>
          </DownloadButton>
          <DownloadButton
            data={flattenedProductData}
            filename={`Product Complexity Rankings ${minYear} - ${latestYear}.csv`}
          >
            <div
              dangerouslySetInnerHTML={{__html: require('../../countryRankings/img/dataDownload.svg')}}
            />
            Download data for
            <br />
            <strong>Product Complexity Rankings</strong>
          </DownloadButton>
        </>
      );
    } else {
      return null;
    }
  };
  const variables: Variables = {
    queryVariables: {level: LocationLevel.country},
    productClass: ProductClass.SITC,
    minYear, maxYear: latestYear,
    productsInput: {productClass: ProductClass.HS, level: ProductLevel.fourDigit},
  };

  return (
    <>
      <Title>Download the data</Title>
      <DownloadButtonsContainer>
        <Query
          variables={variables}
          query={query}
          children={renderProp}
        />
      </DownloadButtonsContainer>
    </>
  );
};

export default AllDownloads;
