import React from 'react';
import styled from 'styled-components';
import { demiFontWeight } from '../cssVariables';
import { useLink } from '../routing';
import { RouteID } from '../routing/routes';
import {
  h4Style,
} from '../sharedComponents/TextStyling';
import {
  CopyrightContainer,
  FooterBackgroundContainer,
  FooterContainer,
} from './GlobalGrid';

export const footerColor = '#5a708c';
const breakpointSmall = '990px';

const Background = styled(FooterBackgroundContainer)`
  background-color: #e6e6e6;
`;
const Root = styled(FooterContainer)`
  padding: 7vh 0 4vh;
  line-height: 1;
  letter-spacing: 1px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 4rem;
  text-transform: uppercase;
  justify-content: center;

  @media (min-width: ${breakpointSmall}) {
    grid-template-columns: auto auto auto auto auto;
  }
`;
const Title = styled.div`
  ${h4Style}
  grid-column: span 2;
  margin-bottom: 0;
  padding-bottom: 10px;
  font-weight: ${demiFontWeight};
  font-size: 1.1rem;
  text-align: center;

  @media (min-width: ${breakpointSmall}) {
    grid-column: 1;
    text-align: left;
  }
`;
const GrowthLab = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (min-width: ${breakpointSmall}) {
    align-items: flex-start;
    grid-column: 2;
  }
`;
const Contact = styled.ul`
  @media (min-width: ${breakpointSmall}) {
    grid-column: 3;
  }
`;
const SocialMedia = styled.ul`
  display: flex;
  justify-content: space-between;
  grid-column: span 2;
  margin: 2rem auto;
  max-width: 200px;

  @media (min-width: ${breakpointSmall}) {
    display: block;
    grid-column: 4;
    margin: 0;
  }
`;
const GrowthLabLogo = styled.div`
  grid-column: 1 / -1;

  @media (min-width: ${breakpointSmall}) {
    grid-column: 5;
  }
`;
const Li = styled.li`
  margin-bottom: 0.8rem;
  font-size: 0.875rem;
`;
const Link = styled.a`
  &:hover {
    border-bottom: 1px solid #333;
  }
`;

const SocialLink = styled(Li)`
  font-size: 0;
  line-height: 0;
  color: rgba(0, 0, 0, 0);
`;

const SocialIconContainer = styled.span`
  display: inline-block;
  width: 1.5rem;

  path {
    fill: #777;
  }

  &:hover {
    path {
      fill: #999;
    }
  }
`;
const AtlasLogoImage = styled.div`
  max-width: 280px;
  margin: auto;

  img {
    width: 100%;
    height: 100%;
  }
`;
const GrowthLabLogoImage = styled.div`
  max-width: 190px;
  margin: auto;

  img {
    width: 100%;
    height: 100%;
    transform: translate(0, -22px);
  }
`;
const Copyright = styled(CopyrightContainer)`
  padding: 0.5rem;
  text-align: center;
  background-color: #333;
  color: #fff;
  font-size: 0.875rem;

  a {
    color: #fff;
    text-decoration: none;
    border-bottom: solid 1px transparent;

    &:hover {
      border-bottom-color: #fff;
    }
  }
`;

const Footer = () => {
  const mailingListLink =
`https://hksexeced.tfaforms.net/f/subscribe?s=a1n6g000000nJnxAAE`;

  const linkedInLink =
`https://www.linkedin.com/company/center-for-international-development-harvard-university/`;

  const treeMapLinkProps = useLink({id: RouteID.TreeMap});
  const countryProfilesLinkProps = useLink({id: RouteID.CountryProfiles});
  const dataLinkProps = useLink({id: RouteID.AboutData});
  const learnLinkProps = useLink({id: RouteID.KeyConcepts});
  const publicationsLinkProps = useLink({id: RouteID.PublicationHighlights});
  const aboutLinkProps = useLink({id: RouteID.About});
  const announcementsLinkProps = useLink({id: RouteID.Announcement});

  return (
    <>
      <Background/>
      <Root>
        <Title>
          <a href='https://growthlab.cid.harvard.edu/home' target='_blank' rel='noopener'>
            <AtlasLogoImage>
              <img src={require('../img/Atlas2_logo.png')} alt='The Growth Lab' />
            </AtlasLogoImage>
          </a>
          <p><em>{__lexiconText('footer.atlasVersion')}</em></p>
        </Title>
        <GrowthLab>
          <Li><Link {...treeMapLinkProps}>{__lexiconText('footer.explore')}</Link></Li>
          <Li><Link {...countryProfilesLinkProps}>{__lexiconText('footer.countryProfiles')}</Link></Li>
          <Li><Link {...dataLinkProps}>{__lexiconText('footer.data')}</Link></Li>
          <Li><Link {...learnLinkProps}>{__lexiconText('footer.learn')}</Link></Li>
          <Li><Link {...publicationsLinkProps}>{__lexiconText('footer.publications')}</Link></Li>
          <Li><Link {...aboutLinkProps}>{__lexiconText('footer.about')}</Link></Li>
        </GrowthLab>
        <Contact>
          <Li>
            <Link {...announcementsLinkProps} target='_blank' rel='noopener'>
              {__lexiconText('footer.announcements')}
            </Link>
          </Li>
          <Li>
            <Link href='mailto:cidatlas@hks.harvard.edu' target='_blank' rel='noopener'>
              {__lexiconText('footer.contact')}
            </Link>
          </Li>
          <Li><Link href={mailingListLink} target='_blank' rel='noopener'>
            {__lexiconText('footer.newsletter')}
          </Link></Li>
          <Li>
            <Link href='https://growthlab.cid.harvard.edu/jobs-opportunities'
              target='_blank' rel='noopener'>

              {__lexiconText('footer.positions')}
            </Link>
          </Li>
        </Contact>
        <SocialMedia>
          <SocialLink>
            <a
              href='https://www.facebook.com/HarvardCID/'
              target='_blank' rel='noopener'
              title={__lexiconText('footer.facebook')}
            >
              <SocialIconContainer
                dangerouslySetInnerHTML={{__html: require('../img/icons/social/facebook.svg')}}/>
              {__lexiconText('footer.facebook')}
            </a>
          </SocialLink>
          <SocialLink>
            <a
              href={linkedInLink}
              target='_blank' rel='noopener'
              title={__lexiconText('footer.linkedin')}
            >
              <SocialIconContainer
                dangerouslySetInnerHTML={{__html: require('../img/icons/social/linkedin.svg')}}/>
              {__lexiconText('footer.linkedin')}
            </a>
          </SocialLink>
          <SocialLink>
            <a
              href='https://twitter.com/HarvardGrwthLab'
              target='_blank' rel='noopener'
              title={__lexiconText('footer.twitter')}
            >
              <SocialIconContainer
                dangerouslySetInnerHTML={{__html: require('../img/icons/social/twitter.svg')}}/>
              {__lexiconText('footer.twitter')}
            </a>
          </SocialLink>
          <SocialLink>
            <a
              href='https://www.youtube.com/user/HarvardCID'
              target='_blank' rel='noopener'
              title={__lexiconText('footer.youtube')}
            >
              <SocialIconContainer
                dangerouslySetInnerHTML={{__html: require('../img/icons/social/youtube.svg')}}/>
              {__lexiconText('footer.youtube')}
            </a>
          </SocialLink>
          <SocialLink>
            <a
              href='https://podcasts.apple.com/us/podcast/growth-lab-podcast-series/id1486218164'
              target='_blank' rel='noopener'
              title={__lexiconText('footer.applepodcast')}
            >
              <SocialIconContainer
                dangerouslySetInnerHTML={{__html: require('../img/icons/social/applepodcast.svg')}}/>
              {__lexiconText('footer.applepodcast')}
            </a>
          </SocialLink>
        </SocialMedia>
        <GrowthLabLogo>
          <a href='https://growthlab.cid.harvard.edu/home' target='_blank' rel='noopener'>
            <GrowthLabLogoImage>
              <img src={require('../img/pub_images/default_image.png')} alt='The Growth Lab' />
            </GrowthLabLogoImage>
          </a>
        </GrowthLabLogo>
      </Root>
      <Copyright>
        Copyright © {new Date().getFullYear()} The President and Fellows of Harvard College
        {' | '}
        <a href='https://gdpr.harvard.edu/eeaprivacydisclosures' target='_blank' rel='noopener'>
          Privacy
        </a>
        {' | '}
        <a href='http://accessibility.harvard.edu/' target='_blank' rel='noopener'>
          Accessibility
        </a>
        {' | '}
        <a href='https://accessibility.huit.harvard.edu/digital-accessibility-policy' target='_blank' rel='noopener'>
          Digital Accessibility
        </a>
        {' | '}
        <a href='http://www.harvard.edu/reporting-copyright-infringements' target='_blank' rel='noopener'>
          Report Copyright
        </a>
      </Copyright>
    </>
  );
};

export default Footer;
