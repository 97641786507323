import {
  GetString,
} from 'fluent-react';
import gql from 'graphql-tag';
import debounce from 'lodash-es/debounce';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Query, QueryResult } from 'react-apollo';
import styled from 'styled-components';
import { CountryProfilesLocalizationAndBundleContext } from '../contextProviders/getFluentLocalizationContexts';
import ChartWrapper from '../countryProfiles/marketShareChart/ChartWrapper';
import LabelsWrapper from '../countryProfiles/marketShareChart/LabelsWrapper';
import {
  client as apolloClient,
} from '../getConfiguredApolloClient';
import {
  AllProductsInput,
  Product,
  ProductClass,
  ProductLevel,
} from '../graphQL/graphQLTypes';
import { TreeMapInput, TreeMapProduct } from '../graphQL/tree/graphQLTypes';
import {
  TreeMapType,
} from '../graphQL/tree/graphQLTypes';

export const chartQuery = gql`
  query MarketShareChart($treeMapInput: TreeMapInput!, $id: ID!) {
    chartData: treeMap(input: $treeMapInput) {
      ... on TreeMapProduct {
        product {
          id
          shortName
          topLevelParent {
            id
          }
        }
        year
        globalMarketShare
      }
    }
    location(id: $id) {
      id
      shortName
    }
  }
`;

const sectorLabelsQuery = gql`
  query MarketShareSectorLabels($input: AllProductsInput!) {
    sectors: allProducts(input: $input) {
      id
      shortName
    }
  }
`;

export interface ChartFetchedDatum {
  product: {
    id: TreeMapProduct['product']['id']
    shortName: TreeMapProduct['product']['shortName'],
    topLevelParent: {
      id: Product['topLevelParent']['id'],
    },
  };
  year: TreeMapProduct['year'];
  globalMarketShare: TreeMapProduct['globalMarketShare'];
}

export interface ChartSuccessResponse {
  chartData: ChartFetchedDatum[];
}

export interface SectorFetchedDatum {
  id: Product['id'];
  shortName: Product['shortName'];
}
interface SectorLabelsSuccessResponse {
  sectors: SectorFetchedDatum[];
}

export interface ChartVariables {
  treeMapInput: TreeMapInput;
  id: string;
}

interface SectorLabelsVariables {
  input: AllProductsInput;
}

export type SectorLabelsQueryResult = QueryResult<SectorLabelsSuccessResponse, SectorLabelsVariables>;
export type ChartQueryResult = QueryResult<ChartSuccessResponse, ChartVariables>;

const zIndices = {
  chartAreaMeasurement: 10,
  verticalLineContainer: 20,
  chart: 30,
  tooltip: 40,
  loadingSpinner: 50,
  errorOverlay: 60,
};

//#region Styling
const gridLines = {
  // columns:
  yAxisLabelLeft: 'countryPagesMarketShareChartYAxisLabelLeft',
  yAxisLabelRight: 'countryPagesMarketShareChartYAxisLabelRight',
  yAxisTicksLeft: 'countryPagesMarketShareChartYAxisTicksLeft',
  yAxisTicksRight: 'countryPagesMarketShareChartYAxisTicksRight',
  chartLeft: 'countryPagesMarketShareChartYAxisChartLeft',
  chartRight: 'countryPagesMarketShareChartYAxisChartRight',
  gutterLeft: 'countryPagesMarketShareChartYAxisGutterLeft',
  gutterRight: 'countryPagesMarketShareChartYAxisGutterRight',
  sectorLabelsLeft: 'countryPagesMarketShareChartYAxisSectorLabelsLeft',
  sectorLabelsRight: 'countryPagesMarketShareChartYAxisSectorLabelsRight',
  // rows:
  chartTop: 'countryPagesMarketShareChartYAxisChartTop',
  chartBottom: 'countryPagesMarketShareChartYAxisChartBottom',
  xAxisTicksTop: 'countryPagesMarketShareChartXAxisTicksTop',
  xAxisTicksBottom: 'countryPagesMarketShareChartXAxisTicksBottom',
};
// This width is chosen such that all sector labels are kept within one line:
const labelsColumnWidth = '7.75rem';

const Grid = styled.div`
  display: grid;
  height: 100%;
  grid-template-columns:
    [${gridLines.yAxisLabelLeft}] 40px
    [${gridLines.yAxisLabelRight} ${gridLines.yAxisTicksLeft}] 30px
    [${gridLines.yAxisTicksRight} ${gridLines.chartLeft}] 1fr
    [${gridLines.chartRight} ${gridLines.gutterLeft}] 1rem
    [${gridLines.gutterRight} ${gridLines.sectorLabelsLeft}] ${labelsColumnWidth}
    [${gridLines.sectorLabelsRight}];
  grid-template-rows:
    [${gridLines.chartTop}] 1fr
    [${gridLines.chartBottom} ${gridLines.xAxisTicksTop}] 30px
    [${gridLines.xAxisTicksBottom}];
`;
export const ChartAreaMeasurementEl = styled.div`
  grid-column: ${gridLines.chartLeft} / ${gridLines.chartRight};
  grid-row: ${gridLines.chartTop} / ${gridLines.chartBottom};
  position: relative;
  z-index: ${zIndices.chartAreaMeasurement};
`;
//#endregion

export interface DOMMeasurement {
  width: number;
  height: number;
}

export interface MouseCoords {
  x: number;
  y: number;
}

interface Props {
  countryId: string;
  parentSetSelectedSectors?: (value: string[] | undefined) => void;
}

const QueryWrapper = (props: Props) => {
  const {
    countryId, parentSetSelectedSectors,
  } = props;
  const [selectedSectors, setSelectedSectors] = useState<string[] | undefined>(undefined);
  const [chartSize, setChartSize] = useState<DOMMeasurement | undefined>(undefined);
  const [mouseCoords, setMouseCoords] = useState<MouseCoords | undefined>(undefined);
  const [chartKey, setChartKey] = useState<string>(`${countryId}-${window.innerWidth}-${window.innerHeight}`);

  useEffect(() => {
    const refreshOnResize = debounce(() => {
      setChartKey(`${countryId}-${window.innerWidth}-${window.innerHeight}`);
      setChartSize(undefined);
    }, 300);
    window.addEventListener('resize', refreshOnResize);
    return () => {
      window.removeEventListener('resize', refreshOnResize);
    };
  }, []);

  const {localization} = useContext(CountryProfilesLocalizationAndBundleContext);
  const getFluentString: GetString =
    (id: string, args?: object) => localization.getString(id, args);

  const selectSector = (sector: string) => {
    if (selectedSectors !== undefined) {
      const newSectors = new Set(selectedSectors);
      newSectors.add(sector);
      const sortedSelectors = [...newSectors].sort();
      setSelectedSectors([...sortedSelectors]);
      if (parentSetSelectedSectors) {
        parentSetSelectedSectors([...sortedSelectors]);
      }
    }
  };

  const unselectSector = (sector: string) => {
    if (selectedSectors !== undefined) {
      const newSectors = new Set(selectedSectors);
      newSectors.delete(sector);
      const sortedSelectors = [...newSectors].sort();
      setSelectedSectors([...sortedSelectors]);
      if (parentSetSelectedSectors) {
        parentSetSelectedSectors([...sortedSelectors]);
      }
    }
  };

  const measureChartArea = useCallback((el: HTMLDivElement | null) => {
    if (el !== null) {
      const {width, height} = el.getBoundingClientRect();
      setTimeout(() => {
        setChartSize({
          width: Math.floor(width),
          height: Math.floor(height),
        });
      }, 0);
    }
  }, [chartKey]);

  const chartVariables: ChartVariables = {
    treeMapInput: {
      type: TreeMapType.CPY_C,
      productClass: ProductClass.HS,
      year: null,
      productLevel: ProductLevel.section,
      locationLevel: null,
      location: countryId,
      product: null,
      partner: null,
    },
    id: countryId,
  };

  const sectorLabelsVariables: SectorLabelsVariables = {
    input: {
      productClass: ProductClass.HS,
      level: ProductLevel.section,
    },
  };
  const chartRenderProp = (queryResult: ChartQueryResult) => (
    <ChartWrapper
      extraInputs={{
        chartSize,
        chartZIndex: zIndices.chart,
        spinnerZIndex: zIndices.loadingSpinner,
        errorOverlayZIndex: zIndices.errorOverlay,
        selectedSectors,
        setMouseCoords,
        mouseCoords,
        tooltipZIndex: zIndices.tooltip,
        verticalLineContainerZIndex: zIndices.verticalLineContainer,
        getFluentString,
      }}
      result={queryResult}
    />
  );

  const sectorLabelsRenderProp = (queryResult: SectorLabelsQueryResult) => (
    <LabelsWrapper
      extraInputs={{
        selectedSectors,
        setSelectedSectors,
        selectSector,
        unselectSector,
        getFluentString,
      }}
      result={queryResult}
    />
  );

  return (
    <Grid>
      <ChartAreaMeasurementEl ref={measureChartArea}/>
      <Query
        key={chartKey}
        query={chartQuery}
        variables={chartVariables}
        children={chartRenderProp}
        client={apolloClient}
      />
      <Query
        query={sectorLabelsQuery}
        variables={sectorLabelsVariables}
        children={sectorLabelsRenderProp}
        client={apolloClient}
      />
    </Grid>
  );
};

export default QueryWrapper;
