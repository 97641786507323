import {
  format,
} from 'd3-format';
import {
  AnyAction,
} from 'redux';
import {
  LocationLevel,
  ProductClass,
  ProductLevel,
  TradeFlow,
} from '../graphQL/graphQLTypes';
import { RouteID } from '../routing/routes';
import {
  IChoice,
} from '../sharedComponents/radioSelector/Utils';
import {
  CountryMetadatumLevel as CountryLevel,
  earliestHSYear,
  earliestSITCYear,
  failIfValidOrNonExhaustive,
  latestYear,
  LoadableStatus,
  ProductMetadatumLevel as OldProductLevel,
} from '../Utils';

export const playButtonInterval = 2_000; // in milliseconds

export const graphHeight = 75; // in `vh`

// TODO: Move all `ProductClass` and `TradeFlow` references to `src/Utils` instead of this file:
export enum VizType {
  Tree = 'tree',
  Geo = 'geo',
  Stack = 'stack',
  MarketShare = 'marketshare',
  Network = 'productspace',
  Feasibility = 'feasiblity',
  Rings = 'rings',
}

export enum PanelControls {
  Build = 'Build',
  Settings = 'Settings',
}

export interface SetActivePanelProps {
  activePanel: PanelControls;
  setActivePanel: (value: PanelControls) => void;
}

export const vizTypeToRouteID = (vizType: VizType): RouteID => {
  if (vizType === VizType.Tree) {
    return RouteID.TreeMap;
  } else if (vizType === VizType.Geo) {
    return RouteID.GeoMap;
  } else if (vizType === VizType.Stack) {
    return RouteID.StackGraph;
  }  else if (vizType === VizType.MarketShare) {
    return RouteID.MarketShare;
  } else if (vizType === VizType.Network) {
    return RouteID.ProductSpace;
  } else if (vizType === VizType.Feasibility) {
    return RouteID.Feasibility;
  } else if (vizType === VizType.Rings) {
    return RouteID.RingsGraph;
  } else {
    throw failIfValidOrNonExhaustive(vizType, 'Invalid viz type ' + vizType);
  }
};

// Certain graphs (tree/stack) can be either about country or products:
export enum GraphSubject {
  Product = 'Product',
  Country = 'Country',
}

export enum NodeSizing {
  None = 'None',
  WorldTrade = 'WorldTrade',
  CountryTrade = 'CountryTrade',
}

// These types are used for properties shown in a graph detail overlay, like RCA, distance,
// opportunity gain:
export enum DisplayValueStatus {
  // Value is present and display that value:
  Show = 'Present',
  // Value is not applicable and display "N/A":
  ShowNotApplicable = 'NotApplicable',

  ShowNotAvailable = 'ShowNotAvailable',
  // Do not display value:
  DoNotShow = 'NotPresent',
}

export type DisplayValue = {
  status: DisplayValueStatus.Show,
  value: number | string,
} | {
  status: DisplayValueStatus.DoNotShow,
} | {
  status: DisplayValueStatus.ShowNotApplicable,
} | {
  status: DisplayValueStatus.ShowNotAvailable;
};

export interface IDetailOverlayRow {
  label: string;
  value: number | string | DisplayValue;
}
/* Start of status codes for graphs*/

export enum GraphStatusCode {
  // `Initial` here means the very first render when the graph is possibly loading yet
  // doesn't have any data to show underneath the loading spinner:
  Initial = 'Initial',

  LoadingAfterSuccess = 'GraphLoadingAfterSuccess',
  LoadingAfterFailure = 'GraphLoadingAfterFailure',
  Success = 'GraphSuccess',
  Fail = 'GraphFail',
}

// Note: `value` type for `Success` and `Loading` is the same because in `Loading` status,
// we'll retain the previous "succesful" state and add a "loading" overlay/spinner:
export type GraphStatus<Success, Failure> = {
  status: GraphStatusCode.Initial,
  loadableStatus: LoadableStatus,
} | {
  status: GraphStatusCode.Success,
  value: Success,
  loadableStatus: LoadableStatus,
} | {
  status: GraphStatusCode.LoadingAfterSuccess,
  value: Success,
  loadableStatus: LoadableStatus,
} | {
  status: GraphStatusCode.LoadingAfterFailure,
  value: Failure,
  loadableStatus: LoadableStatus,
} | {
  status: GraphStatusCode.Fail,
  value: Failure,
  loadableStatus: LoadableStatus,
};
/* End of status codes for graphs*/

// Possible "error states" for graphs:
export enum GraphFailureCode {
  NoData = 'NoData',
}

export enum UpdateType {
  Hard = 'Hard',
  Soft = 'Soft',
}

export const productClassChoices: Array<IChoice<ProductClass>> = [{
  value: ProductClass.SITC,
  label: 'SITC4 (1962-2019)',
}, {
  value: ProductClass.HS,
  label: 'HS4 (1995-2019)',
}];

export const tradeFlowChoices: Array<IChoice<TradeFlow>> = [{
  value: TradeFlow.Gross,
  label: __lexiconText('applicationWide.tradeFlowSelector.gross'),
}, {
  value: TradeFlow.Net,
  label: __lexiconText('applicationWide.tradeFlowSelector.net'),
}];

export const productDetailLevelChoices: Array<IChoice<OldProductLevel>> = [{
  value: OldProductLevel.section,
  label: __lexiconText('applicationWide.productDetailLevelSelector.low'),
}, {
  value: OldProductLevel.twoDigit,
  label: __lexiconText('applicationWide.productDetailLevelSelector.mid'),
}, {
  value: OldProductLevel.fourDigit,
  label: __lexiconText('applicationWide.productDetailLevelSelector.high'),
}];

export const expandedProductDetailLevelChoices: Array<IChoice<OldProductLevel>> = [{
  value: OldProductLevel.section,
  label: __lexiconText('applicationWide.productDetailLevelSelector.low'),
}, {
  value: OldProductLevel.twoDigit,
  label: __lexiconText('applicationWide.productDetailLevelSelector.mid'),
}, {
  value: OldProductLevel.fourDigit,
  label: __lexiconText('applicationWide.productDetailLevelSelector.high'),
}, {
  value: OldProductLevel.sixDigit,
  label: __lexiconText('applicationWide.productDetailLevelSelector.ultraHigh'),
},
];

export const newProductDetailLevelChoices: Array<IChoice<ProductLevel>> = [{
  value: ProductLevel.section,
  label: __lexiconText('applicationWide.productDetailLevelSelector.low'),
}, {
  value: ProductLevel.twoDigit,
  label: __lexiconText('applicationWide.productDetailLevelSelector.mid'),
}, {
  value: ProductLevel.fourDigit,
  label: __lexiconText('applicationWide.productDetailLevelSelector.high'),
}];

export const newExpandedProductDetailLevelChoices: Array<IChoice<ProductLevel>> = [{
  value: ProductLevel.section,
  label: __lexiconText('applicationWide.productDetailLevelSelector.low'),
}, {
  value: ProductLevel.twoDigit,
  label: __lexiconText('applicationWide.productDetailLevelSelector.mid'),
}, {
  value: ProductLevel.fourDigit,
  label: __lexiconText('applicationWide.productDetailLevelSelector.high'),
}, {
  value: ProductLevel.sixDigit,
  label: __lexiconText('applicationWide.productDetailLevelSelector.ultraHigh'),
},
];

export const countryDetailLevelChoices: Array<IChoice<CountryLevel>> = [{
  value: CountryLevel.region,
  label: __lexiconText('applicationWide.countryDetailLevelSelector.low'),
}, {
  value: CountryLevel.country,
  label: __lexiconText('applicationWide.countryDetailLevelSelector.high'),
}];
export const locationDetailLevelChoices: Array<IChoice<LocationLevel>> = [{
  value: LocationLevel.region,
  label: __lexiconText('applicationWide.countryDetailLevelSelector.low'),
}, {
  value: LocationLevel.country,
  label: __lexiconText('applicationWide.countryDetailLevelSelector.high'),
}];

export const formatDecimal = format('.3');

export const formatLongDecimalDisplayedValue = (input: DisplayValue): DisplayValue => {
  if (input.status === DisplayValueStatus.Show) {
    return {
      status: DisplayValueStatus.Show,
      value: formatDecimal(input.value as number),
    };
  } else {
    return input;
  }
};

// Widen the interval [`lower`, `upper`] by a factor of `factor`:
// `factor` must be at least 1.

export type IntervalWidener = (lower: number, upper: number) => [number, number];
export const getIntervalWidener =
  (factor: number): IntervalWidener =>
    (lower: number, upper: number): [number, number] => {

  const width = upper - lower;
  const additionalLength = width * (factor - 1);
  const newLower = lower - additionalLength / 2;
  const newUpper = upper + additionalLength / 2;
  return [newLower, newUpper];
};

// Clamp year so that it's within the max year range of a product
// classification:
export const clampYear = (productClass: ProductClass, year: number) => {
  let lowerBoundApplied: number;
  if (productClass === ProductClass.HS && year < earliestHSYear) {
    lowerBoundApplied = earliestHSYear;
  } else if (productClass === ProductClass.SITC && year < earliestSITCYear) {
    lowerBoundApplied = earliestSITCYear;
  } else {
    lowerBoundApplied = year;
  }

  let bothBoundsApplied: number;
  if (lowerBoundApplied > latestYear) {
    bothBoundsApplied = latestYear;
  } else  {
    bothBoundsApplied = lowerBoundApplied;
  }
  return bothBoundsApplied;
};

// Dispatch props for graph components (which need to dispatch to both stores):
export interface IGraphDispatchProps {
  dispatchToBothStores: (action: AnyAction) => void;
  dispatchToWorkerStore: (action: AnyAction) => void;
  dispatchToMainStore: (action: AnyAction) => void;
}

// Determine new product ID when switching between product classes:
export const getProductIDForNewProductClassGetter =
  (SITC_to_HSMap: Map<number, number>, HS_to_SITCMap: Map<number, number>) =>
    (currentProduct: number | undefined, currentClass: ProductClass, newClass: ProductClass) => {

  let newProduct: number | undefined;
  if (newClass !== currentClass) {
    if (currentProduct === undefined) {
      newProduct = undefined;
    } else {
      newProduct = (newClass === ProductClass.HS) ?
                    SITC_to_HSMap.get(currentProduct) :
                    HS_to_SITCMap.get(currentProduct);
    }
  } else {
    newProduct = currentProduct;
  }
  return {
    productClass: newClass,
    product: newProduct,
  };
};
