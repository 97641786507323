import React, { useState } from 'react';
import {slide as BurgerMenu} from 'react-burger-menu';
import styled from 'styled-components';
import { menuBaseColor, rootFontSize } from '../../cssVariables';
import { useLink } from '../../routing';
import { RouteID } from '../../routing/routes';
import NavMenu from './NavMenu';

const HamburgerMenuContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

const smallMediaQuery = `
screen and (max-width: 1050px) , screen and (max-height: 700px)
`;
//#region Styling

const MenuLabel = styled.div<{iconColor: string}>`
  color: ${(({iconColor}) => iconColor)};
  font-size: 0.625rem;
  line-height: 1.5;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  position: absolute;
  transform: translateX(50%);
  top: 10px;

  @media ${smallMediaQuery} {
    top: 8px;
  }
`;
const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
`;
//#endregion

const ImageContainer = styled.a`
  width: 150px;
  margin-bottom: 2rem;
`;

const burgerBarWidth = 1.5 * rootFontSize; // in pixels
const burgerBarsTotalHeight = 17; // in px

interface Props {
  iconColor: string;
}

const HamburgerMenu = (props: Props) => {
  const { iconColor } = props;

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [containerWidth] = useState<number | undefined>(20);

  const logoAnchorProps = useLink({id: RouteID.Home});

  let menuContent: React.ReactElement<any> | null;
  if (containerWidth === undefined) {
    menuContent = null;
  } else {
    // const searchBarHeightInRems = window.innerHeight > gridSmallMediaSize
      // ? searchBarHeightLargeInRems : searchBarHeightSmallInRems;
    // const searchBarHeightInPixels = searchBarHeightInRems * (rootFontSize * 1.5);
    // const menuTopAdjustmentVal = window.innerWidth > gridSmallMediaSize ? 2 : 3;
    const menuTopOffset = 25;
    const menuWidth = burgerBarWidth;
    const menuRightOffset = Math.round(containerWidth / 2 - burgerBarWidth / 2);
    const styles = {
      bmBurgerButton: {
        position: 'absolute',
        width: `${menuWidth}px`,
        height: `${burgerBarsTotalHeight}px`,
        right: `${menuRightOffset}px`,
        top: `${menuTopOffset}px`,
      },
      bmBurgerBars: {
        background: iconColor,
      },
      bmBurgerBarsHover: {
        background: iconColor,
      },
      bmCrossButton: {
        height: `30px`,
        width: `30px`,
        right: `5px`,
        top: `5px`,
      },
      bmCross: {
        background: '#fff',
        height: `${burgerBarsTotalHeight}px`,
      },
      bmMenuWrap: {
        position: 'fixed',
        height: '100%',
      },
      bmMenu: {
        background: menuBaseColor,
        width: '300px',
        padding: '0 2rem',
      },
      bmMorphShape: {
        fill: '#373a47',
      },
      bmItemList: {
        color: '#b8b7ad',
        padding: '0.8em',
        display: 'flex',
        flexDirection: 'column',
      },
      bmItem: {
        display: 'block',
      },
      bmOverlay: {
        background: 'rgba(0, 0, 0, 0.3)',
      },
    };
    const menuLabelStyle: React.CSSProperties = {
      right: `${Math.round(containerWidth / 2)}px`,
    };

    menuContent = (
      <>
        <MenuLabel style={menuLabelStyle} iconColor={iconColor}>Menu</MenuLabel>
        <BurgerMenu
          right={true}
          styles={styles}
          isOpen={isMenuOpen}
          onStateChange={(state) => setIsMenuOpen(state.isOpen)}
        >
          <ImageContainer {...logoAnchorProps}>
            <img src={require('./logo-white.png')}/>
          </ImageContainer>
          <NavMenu/>
        </BurgerMenu>
      </>
    );
  }
  const backgroundOverlay = isMenuOpen === false ? null : (
    <Overlay onClick={() => setIsMenuOpen(false)} />
  );
  const style: React.CSSProperties = {
    zIndex: isMenuOpen === false ? 100 : 500,
  };

  return (
    <HamburgerMenuContainer style={style}>
      {menuContent}
      {backgroundOverlay}
    </HamburgerMenuContainer>
  );
};

export default HamburgerMenu;
