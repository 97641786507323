import React, {
  useEffect,
} from 'react';
import ReactDOM from 'react-dom';
import {
  ProductClass,
  TradeFlow,
} from '../graphQL/graphQLTypes';
import { useQueryParams } from '../routing';
import RadioSelector from '../sharedComponents/radioSelector';
import treeURLTransformers from '../tree/URLTransformers';
import useTreeNavigation from '../tree/useNavigation';
import {
  scrollToTop,
  Target,
} from '../Utils';
import Controls , {
  CountryOrProduct,
  ITargetDropdownState,
  ITargetState,
  ITradeDirectionState,
} from '../viz/controls';
import {
  parseQueryParamsIntoQueryStore, useVizTypeChanger,
} from '../viz/routingUtils';
import {
  productClassChoices,
  tradeFlowChoices,
  VizType,
} from '../viz/Utils';
import { SetActivePanelProps as Props } from '../viz/Utils';
import { vizSettingsPortalId } from '../viz/VizSettings';
import useNavigation from './useNavigation';

const currentVizType = VizType.MarketShare;

const MarketShareStatic = ({activePanel, setActivePanel}: Props) => {
  useEffect(scrollToTop, []);
  const queryParams = useQueryParams();
  const queryStore = parseQueryParamsIntoQueryStore(treeURLTransformers, queryParams);

  const { changeCountry, resetCountryAndTarget } = useNavigation();
  const {
    changeProductClass,
    changeTradeFlow, changeProduct, changeTarget,
  } = useTreeNavigation();
  const tradeDirectionPropsForVizControls: ITradeDirectionState = {
    isVisible: false,
  };

  const targetProps: ITargetState = {
    isVisible: true,
    target: Target.Product,
    onTargetChange: changeTarget,
  };

  const targetDropdownState: ITargetDropdownState = {
    isVisible: false,
  };

  let guidanceText: string;
  if (queryStore.target === Target.Product) {
    guidanceText = __lexiconText('guidanceText.productMode');
  } else {
    guidanceText = __lexiconText('guidanceText.partnerMode');
  }

  //#region Trade flow selector:
  const tradeFlowSelector = (
    <RadioSelector
      tooltipText={__lexiconText('applicationWide.tradeFlowSelector.tooltipText')}
      mainLabel={__lexiconText('applicationWide.tradeFlowSelector.mainLabel')}
      choices={tradeFlowChoices}
      selected={TradeFlow.Gross}
      onClick={changeTradeFlow}
      disabled={true}
    />
  );
  //#endregion
  //#region Product class selector
  const productClassSelector = (
    <RadioSelector
      tooltipText={__lexiconText('applicationWide.productClassSelector.tooltipText')}
      mainLabel={__lexiconText('applicationWide.productClassSelector.mainLabel')}
      choices={productClassChoices}
      selected={ProductClass.HS}
      onClick={changeProductClass}
      disabled={true}
    />
  );
  //#endregion

  const vizSettingsNodeRef = document.querySelector<HTMLElement>(`#${vizSettingsPortalId}`);

  let vizSettings: React.ReactElement<any> | null;
  if (vizSettingsNodeRef) {
    vizSettings = ReactDOM.createPortal((
      <>
        {productClassSelector}
        {tradeFlowSelector}
      </>
    ), vizSettingsNodeRef);
  } else {
    vizSettings = null;
  }

  const changeVizType = useVizTypeChanger();

  //#region render
  return (
    <>
      <Controls
        vizType={currentVizType}
        country={queryStore.country} tradeDirectionProps={tradeDirectionPropsForVizControls}
        targetProps={targetProps} targetDropdownState={targetDropdownState} guidanceText={guidanceText}
        onCountryChange={changeCountry}
        onVizTypeChange={changeVizType}
        globalProductClass={ProductClass.HS}
        globalProduct={queryStore.product}
        globalOnProductChange={changeProduct}
        initialCountryOrProduct={CountryOrProduct.country}
        resetCountryAndTarget={resetCountryAndTarget}
        activePanel={activePanel}
        setActivePanel={setActivePanel}
      />
      {vizSettings}
    </>
  );
  //#endregion
};

export default MarketShareStatic;
