import random from 'lodash-es/random';
import {
  ProductClass,
  TradeDirection,
  TradeFlow,
} from '../graphQL/graphQLTypes';
import {
  latestYear, Target,
} from '../Utils';
import {
  NodeSizing, VizType,
} from './Utils';

export interface QueryStore {
  country: number | undefined;
  product: number | undefined;
  year: number;
  startYear: number | undefined;
  tradeDirection: TradeDirection;
  productClass: ProductClass;
  nodeSizing: NodeSizing;
  tradeFlow: TradeFlow;
  target: Target;
  partner: number | undefined;
}

export interface URLAction<InnerAction> {
  vizType: VizType;
  innerAction: InnerAction;
}

export type QueryStoreReducer<InnerAction> = (current: QueryStore, action: InnerAction) => QueryStore;

// `T` is the type of parsed values that this query parameter can take:
interface URLTransformer<T> {
  parse: (input: string | undefined) => T;
  defaultValue: T;
}

export interface URLTransformers {
  country: URLTransformer<QueryStore['country']>;
  product: URLTransformer<QueryStore['product']>;
  year: URLTransformer<QueryStore['year']>;
  startYear: URLTransformer<QueryStore['startYear']>;
  tradeDirection: URLTransformer<QueryStore['tradeDirection']>;
  productClass: URLTransformer<QueryStore['productClass']>;
  nodeSizing: URLTransformer<QueryStore['nodeSizing']>;
  tradeFlow: URLTransformer<QueryStore['tradeFlow']>;
  target: URLTransformer<QueryStore['target']>;
  partner: URLTransformer<QueryStore['partner']>;
}

// This is the string constant that represents a query string value that is explicitly
// set to `undefined`:
export const UNDEFINED = 'undefined';

const getStringToNumberConverter = (defaultValue: number) => (input: string | undefined) => {
  if (input === undefined) {
    return defaultValue;
  } else {
    // If value for query key does exist but not a number, also return default:
    const parsed = parseInt(input, 10);
    return Number.isNaN(parsed) ? defaultValue : parsed;
  }
};

const getStringToNumberWithUndefinedConverter =
  (defaultValue: number | undefined) => (input: string | undefined): number | undefined => {
    if (input === undefined) {
      if (defaultValue === undefined) {
        // Treat non-existence of value for a query parameter as `undefined` if
        // `undefined` is the default:
        return undefined;
      } else {
        return defaultValue;
      }

    } else if (input === UNDEFINED) {
      return undefined;
    } else {
      const parsed = parseInt(input, 10);
      return Number.isNaN(parsed) ? defaultValue : parsed;
    }
  };

export const defaultCountryPool = [
  231, // US
  81, // UK
  114, // Japan
  43, // China,
  192, // Singapore
  39, // Canada
  138, // Mexico
  49, // Colombia
  77, // France
  186, // Russia,
  104, // India,
  32, // Brazil,
  224, // Turkey
  61, // Germany,
  239, // Vietnam,
  83, // Ghana,
  14, // Australia,
  71, // Spain,
  113, // Jordan,
  188, // Saudi Arabia,
];

let defaultCountry: number;
if (process.env.NODE_ENV === 'production') {
  defaultCountry = defaultCountryPool[random(defaultCountryPool.length - 1)];
} else {
  defaultCountry = 114;
}

export const defaultCountryTransformer: URLTransformer<number | undefined> = {
  parse: getStringToNumberWithUndefinedConverter(defaultCountry),
  defaultValue: defaultCountry,
};

export const defaultNumberWithUndefinedTransformer: URLTransformer<number | undefined> = {
  parse: getStringToNumberWithUndefinedConverter(undefined),
  defaultValue: undefined,
};

export const defaultYearTransformer: URLTransformer<number> = {
  parse: getStringToNumberConverter(latestYear),
  defaultValue: latestYear,
};

const defaultProductClass = ProductClass.HS;
export const defaultProductClassTransformer: URLTransformer<ProductClass> = {
  parse: (input: string | undefined) => {
    if (input === undefined) {
      return defaultProductClass;
    } else if (input === ProductClass.HS || input === ProductClass.SITC) {
      return input;
    } else {
      return defaultProductClass;
    }
  },
  defaultValue: ProductClass.HS,
};

export const defaultTradeDirection = TradeDirection.export;
export const defaultTradeDirectionTransformer: URLTransformer<TradeDirection> = {
  parse: (input: string | undefined) => {
    if (input === undefined) {
      return defaultTradeDirection;
    } else if (input === TradeDirection.export || input === TradeDirection.import) {
      return input;
    } else {
      return defaultTradeDirection;
    }
  },
  defaultValue: defaultTradeDirection,
};

export const defaultNodeSizing = NodeSizing.WorldTrade;
export const defaultNodeSizingTransformer: URLTransformer<NodeSizing> = {
  parse: (input: string | undefined) => {
    if (input === undefined) {
      return defaultNodeSizing;
    } else if (input === NodeSizing.None || input === NodeSizing.WorldTrade ||
                input === NodeSizing.CountryTrade) {
      return input;
    } else {
      return defaultNodeSizing;
    }
  },
  defaultValue: defaultNodeSizing,
};

export const defaultTradeFlow = TradeFlow.Gross;
export const defaultTradeFlowTransformer: URLTransformer<TradeFlow> = {
  parse: (input: string | undefined) => {
    if (input === undefined) {
      return defaultTradeFlow;
    } else if (input === TradeFlow.Gross || input === TradeFlow.Net) {
      return input;
    } else {
      return defaultTradeFlow;
    }
  },
  defaultValue: defaultTradeFlow,
};

export const defaultTarget = Target.Product;
export const defaultTargetTransformer: URLTransformer<Target> = {
  parse: (input: string | undefined) => {
    if (input === undefined) {
      return defaultTarget;
    } else if (input === Target.Product || input === Target.Partner) {
      return input;
    } else {
      return defaultTarget;
    }
  },
  defaultValue: defaultTarget,
};

// This action type is not registered with the reducer of any viz type. As such, any reducer
// will return the state that's parsed from the search string without any modification. Useful for
// switching between viz types.
export const ALL_VIZ_TYPE_IDENTITY = 'ALL_VIZ_TYPE_IDENTITY';

// Forced navigation from the search bar:
export const ALL_VIZ_TYPE_FORCED_NAVIGATION = 'ALL_VIZ_TYPE_FORCED_NAVIGATION';

export interface ForcedNavigationAction {
  type: typeof ALL_VIZ_TYPE_FORCED_NAVIGATION;
  payload: {
    params: Partial<QueryStore>,
  };
}

// Determine new product ID when switching between product classes:
export const getProductIDForNewProductClass = (input: {
    SITC_to_HSMap: Map<number, number>,
    HS_to_SITCMap: Map<number, number>,
    currentProduct: number | undefined,
    currentProductClass: ProductClass,
    newProductClass: ProductClass,
  }) => {

  const {SITC_to_HSMap, HS_to_SITCMap, currentProduct, currentProductClass, newProductClass} = input;

  let newProduct: number | undefined;
  if (newProductClass !== currentProductClass) {
    if (currentProduct === undefined) {
      newProduct = undefined;
    } else {
      newProduct = (newProductClass === ProductClass.HS) ?
                    SITC_to_HSMap.get(currentProduct) :
                    HS_to_SITCMap.get(currentProduct);
    }
  } else {
    newProduct = currentProduct;
  }
  return {
    productClass: newProductClass,
    product: newProduct,
  };
};
