import React from 'react';
import styled from 'styled-components';
import {
  BannerContainer,
} from '../sharedComponents/GlobalGrid';
import {
  xIconHTMLEntity,
} from '../Utils';

const textColor = 'white';
const backgroundColor = 'rgb(84, 163, 198)';
export const bannerHeight = '3.5rem';

const Root = styled(BannerContainer)`
  font-size: 1.1rem;
  line-height: 1.36;
  padding: 1rem 0;
  color: ${textColor};
  font-weight: 400;
  background-color: ${backgroundColor};
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const iconSize = '1.5rem';
const InfoIconOuterContainer = styled.div`
  width: ${iconSize};
  height: ${iconSize};
  margin-right: 0.5rem;
`;

const InfoIconInnerContainer = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 100%;

  svg {
    position: absolute;
    top: 0;
    left: 0;
  }

  polygon {
    fill: ${textColor};
  }

  path {
    stroke: ${textColor};
    fill: ${textColor};
  }
`;

// Need to give regular text `border-bottom` so that it appears level with the
// link:
const Text = styled.div`
  text-align: center;
  border-bottom: 1px solid ${backgroundColor};
  margin-right: 0.2rem;
`;

const CallToAction = styled.a`
  border-bottom: 1px solid ${textColor};
`;

const CloseButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(71, 156, 194);
  width: ${bannerHeight};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

interface Props {
  hide: () => void;
  disabled?: boolean;
}

const Banner = ({hide, disabled}: Props) => {

  if (disabled) {
    return null;
  }

  return (
    <Root>
      <InfoIconOuterContainer>
        <InfoIconInnerContainer dangerouslySetInnerHTML={{__html: require('./banner_info_icon.svg')}}/>
      </InfoIconOuterContainer>
      <Text>{__lexicon('banner.text')}</Text>
      <CallToAction onClick={hide} href={'https://metroverse.cid.harvard.edu/'} target='_blank'>
        {__lexicon('banner.callToAction')}
      </CallToAction>
      <CloseButton onClick={hide}>
        <div dangerouslySetInnerHTML={{__html: xIconHTMLEntity}}/>
      </CloseButton>
    </Root>
  );
};

export default Banner;
