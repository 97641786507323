import { always } from '../Utils';
import {
  defaultCountryTransformer,
  defaultNodeSizing,
  defaultNumberWithUndefinedTransformer,
  defaultProductClassTransformer,
  defaultTarget,
  defaultTradeDirectionTransformer,
  defaultTradeFlowTransformer,
  defaultYearTransformer,
  URLTransformers,
} from '../viz/queryStoreUtils';

const transformers: URLTransformers = {
  year: defaultYearTransformer,
  country: defaultCountryTransformer,
  tradeDirection: defaultTradeDirectionTransformer,
  productClass: defaultProductClassTransformer,
  tradeFlow: defaultTradeFlowTransformer,
  product: defaultNumberWithUndefinedTransformer,

  // Below are URL params that we don't care about:
  nodeSizing: {
    parse: always(defaultNodeSizing),
    defaultValue: defaultNodeSizing,
  },
  target: {
    parse: always(defaultTarget),
    defaultValue: defaultTarget,
  },
  partner: defaultNumberWithUndefinedTransformer,
  startYear: defaultNumberWithUndefinedTransformer,
};

export default transformers;
