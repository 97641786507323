import feasibilityQueryStoreReducer from '../feasibility/queryStoreReducer';
import feasibilityURLTransformers from '../feasibility/URLTransformers';
import geoMapQueryStoreReducer from '../geoMap/queryStoreReducer';
import geoMapURLTransformers from '../geoMap/URLTransformers';
import marketShareQueryStoreReducer from '../marketShare/queryStoreReducer';
import marketShareURLTransformers from '../marketShare/URLTransformers';
import productSpaceQueryStoreReducer from '../network/queryStoreReducer';
import productSpaceURLTransformers from '../network/URLTransformers';
import ringsQueryStoreReducer from '../rings/queryStoreReducer';
import ringsURLTransformers from '../rings/URLTransformers';
import { usePushStateByDescriptor, useQueryParams} from '../routing';
import stackQueryStoreReducer from '../stack/queryStoreReducer';
import stackURLTransformers from '../stack/URLTransformers';
import treeQueryStoreReducer from '../tree/queryStoreReducer';
import treeURLTransformers from '../tree/URLTransformers';
import {
  ALL_VIZ_TYPE_FORCED_NAVIGATION,
  ALL_VIZ_TYPE_IDENTITY,
  ForcedNavigationAction,
  QueryStore,
  UNDEFINED,
  URLAction,
  URLTransformers,
} from './queryStoreUtils';
import {
  VizType, vizTypeToRouteID,
} from './Utils';

const getURLTransformersForViztype = (vizType: VizType) => {
  if (vizType === VizType.Tree) {
    return treeURLTransformers;
  } else if (vizType === VizType.Network) {
    return productSpaceURLTransformers;
  } else if (vizType === VizType.Geo) {
    return geoMapURLTransformers;
  } else if (vizType === VizType.Stack) {
    return stackURLTransformers;
  } else if (vizType === VizType.Feasibility) {
    return feasibilityURLTransformers;
  } else if (vizType === VizType.Rings) {
    return ringsURLTransformers;
  }  else if (vizType === VizType.MarketShare) {
    return marketShareURLTransformers;
  } else {
    throw new Error('Not implemented yet');
  }
};

const getQueryStoreReducer = (vizType: VizType) => {
  if (vizType === VizType.Tree) {
    return treeQueryStoreReducer;
  } else if (vizType === VizType.Network) {
    return productSpaceQueryStoreReducer;
  } else if (vizType === VizType.Geo) {
    return geoMapQueryStoreReducer;
  } else if (vizType === VizType.Stack) {
    return stackQueryStoreReducer;
  } else if (vizType === VizType.Feasibility) {
    return feasibilityQueryStoreReducer;
  } else if (vizType === VizType.Rings) {
    return ringsQueryStoreReducer;
  } else if (vizType === VizType.MarketShare) {
    return marketShareQueryStoreReducer;
  } else {
    throw new Error('Not implemented yet');
  }
};

export const getActionDispatchResult = <InnerAction>(
    queryParams: Record<string, string | undefined>,
    action: URLAction<InnerAction>,
  ) => {
  const {vizType, innerAction} = action;
  const urlTransformers = getURLTransformersForViztype(vizType);
  const queryStoreReducer = getQueryStoreReducer(vizType);
  const prevQueryStore = parseQueryParamsIntoQueryStore(urlTransformers, queryParams);
  const newQueryStore = queryStoreReducer(prevQueryStore, innerAction as any);
  return newQueryStore;
};

export const useNavigation = <InnerAction>() => {
  const historyPushState = usePushStateByDescriptor();
  const queryParams = useQueryParams();
  const push = (action: URLAction<InnerAction>) => {
    const {vizType} = action;
    const routeID = vizTypeToRouteID(vizType);
    historyPushState({
      id: routeID,
      queryParams: convertQueryStoreIntoQueryParams(
        vizType,
        getActionDispatchResult(queryParams, action),
      ),
    });
  };

  return {push};
};

export const useForcedNavigation = () => {
  const {push} = useNavigation();
  return (queryParams: Partial<QueryStore>, vizType: VizType) => {
    const action: URLAction<ForcedNavigationAction> = {
      vizType,
      innerAction: {
        type: ALL_VIZ_TYPE_FORCED_NAVIGATION,
        payload: {params: queryParams},
      },
    };
    push(action);
  };
};

export const useVizTypeChanger = () => {
  const {push} = useNavigation();
  return (vizType: VizType) => {
    const action: URLAction<{}> = {
      vizType,
      innerAction: {
        type: ALL_VIZ_TYPE_IDENTITY,
      },
    };
    push(action);
  };
};

export const parseQueryParamsIntoQueryStore =
  (transformers: URLTransformers, queryParams: Record<string, string | undefined>): QueryStore => {

  return {
    country: transformers.country.parse(queryParams.country),
    product: transformers.product.parse(queryParams.product),
    year: transformers.year.parse(queryParams.year),
    startYear: transformers.startYear.parse(queryParams.startYear),
    tradeDirection: transformers.tradeDirection.parse(queryParams.tradeDirection),
    productClass: transformers.productClass.parse(queryParams.productClass),
    nodeSizing: transformers.nodeSizing.parse(queryParams.nodeSizing),
    tradeFlow: transformers.tradeFlow.parse(queryParams.tradeFlow),
    target: transformers.target.parse(queryParams.target),
    partner: transformers.partner.parse(queryParams.partner),
  };
};

export const convertQueryStoreIntoQueryParams =
  (vizType: VizType, queryStore: QueryStore): Record<string, string | undefined> => {

  const urlTransformers = getURLTransformersForViztype(vizType);
  // These are the params that always need to be present in the query string for
  // the social-media-shared URL to be accurate (i.e. take whoever that clicks on
  // the shared links to the same graph that the sharer intended):
  const importantParams = new Set<keyof QueryStore>([
    'country', 'product', 'year', 'startYear', 'partner', 'target', 'productClass',
  ]);

  const result: Record<string, string> = {};
  for (const [key, {defaultValue}] of Object.entries(urlTransformers)) {
    const retrievedValue = (queryStore as any)[key] as string | undefined;
    if (retrievedValue !== defaultValue || importantParams.has(key as any) === true) {
      result[key] = (retrievedValue === undefined) ? UNDEFINED : retrievedValue;
    }
  }

  return result;
};
