import {
  combineEpics,
} from 'redux-observable';
import {
  fetchDataEpic as fetchGrowthProjectionsData,
} from './growthProjections/actions';
import {
  fetchDataEpic as fetchCYData,
} from './sharedData/countryYear';
import {
  fetchDataEpic as fetchNewCountryMetadataEpic,
} from './sharedData/newCountryMetadata';
import {
  fetchDataEpic as fetchNewProductSpaceLayout,
} from './sharedData/newLayoutData';
import {
  fetchDataEpic as fetchNewProductMetadataEpic,
} from './sharedData/newProductMetadata';
import {
  fetchDataEpic as fetchNewWorldGeoJSON,
} from './sharedData/newWorldGeoJSON';
import {
  fetchDataEpic as fetchPYData,
} from './sharedData/productYear';
import {
  fetchDataEpic as fetchWorldGeoJSON,
} from './sharedData/worldGeoJSON';
import {
  fetchDataEpic as fetchWorldTradeData,
} from './sharedData/worldTradeData';

export const rootEpic = combineEpics(
  fetchWorldGeoJSON,
  fetchGrowthProjectionsData,
  fetchWorldTradeData,
  fetchNewProductMetadataEpic,
  fetchNewCountryMetadataEpic,
  fetchNewWorldGeoJSON,
  fetchNewProductSpaceLayout,
  fetchPYData,
  fetchCYData,
);
