import {
  DecileClassification,
  IntrospectionTypes,
  Location,
  LocationLevel,
  Product,
  ProductClass,
  ProductLevel,
} from '../graphQLTypes';

export enum TreeMapType {
  CPY_C = 'CPY_C',
  CPY_P = 'CPY_P',
  CCPY_CP = 'CCPY_CP',
  CCPY_CC = 'CCPY_CC',
  CCY_C = 'CCY_C',
}

export interface TreeMapInput {
  type: TreeMapType;
  productClass: ProductClass | null;
  year: number | null;
  productLevel: ProductLevel | null;
  locationLevel: LocationLevel | null;
  location: string | null;
  product: string | null;
  partner: string | null;
}

export const treeMapProductTypeName = 'TreeMapProduct';
export const treeMapLocationTypeName = 'TreeMapLocation';

export const introspectionTypes: IntrospectionTypes = [
  {
    kind: 'Union',
    name: 'TreeMapDatum',
    possibleTypes: [
      {name: treeMapLocationTypeName},
      {name: treeMapProductTypeName},
    ],
  },
];

export interface TreeMapProduct {
  product: Product;
  exportValue: number | null;
  importValue: number | null;
  rca: number | null;
  distance: number | null;
  opportunityGain: number | null;
  normalizedDistance: number | null;
  normalizedOpportunityGain: number | null;
  normalizedPCI: number | null;
  normalizedPCIDecileClassification: DecileClassification | null;
  normalizedOpportunityGainDecileClassification: DecileClassification | null;
  normalizedDistanceDecileClassification: DecileClassification | null;
  globalMarketShare: number | null;
  year: number;
}

export interface TreeMapLocation {
  location: Location;
  exportValue: number | null;
  importValue: number | null;
}
